import BaseService from '../../BaseService';

class BrandService extends BaseService {
    getAll = (filters) => this.post('/warehouse/product/brands', filters || { });

    getOne = (id) => this.get(`/warehouse/product/brand/${id}`);

    deleteOne = (id) => this.delete(`/warehouse/product/brand/${id}`);

    addBrand = (data) => this.post('/warehouse/product/brand', data);

    editBrand = (id, data) => this.put(`/warehouse/product/brand/${id}`, data);
}

export default new BrandService();