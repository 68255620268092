import { createActions } from 'reduxsauce';

const { Types: types, Creators: actions } = createActions({
    // add size
    requestSubmitSize: ['id', 'data'],
    submitSizeSucceeded: ['data'],
    submitSizeFailed: ['errors'],

    // clear state
    clearSizeCreateState: null,
});

export default {
    // add size
    [types.REQUEST_SUBMIT_SIZE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_SIZE_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            sizeDetails: data,
            success: true,
        },
    }),
    [types.SUBMIT_SIZE_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            success: false,
            errors,
        },
    }),

    // clear state
    [types.CLEAR_SIZE_CREATE_STATE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            sizeDetails: null,
            success: false,
            errors: null,
        },
    }),
};

export { types, actions };