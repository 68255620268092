import { combineReducers } from 'redux';

import warehouse, {
    types as warehouseTypes,
    actions as warehouseActions,
} from './warehouse/reducer';

import warehouseType, {
    types as typeTypes,
    actions as typeActions,
} from './type/reducer';

const actions = {
    ...warehouseActions,
    ...typeActions,
};

const types = {
    ...warehouseTypes,
    ...typeTypes,
};

export { types, actions };

const ordersReducer = combineReducers({
    warehouse,
    warehouseType,
});

export default ordersReducer;
