import {
    takeEvery,
    call,
    all,
    put,
    fork,
} from 'redux-saga/effects';

import { types, actions } from './reducer';
import PartnerService from '@iso/services/SettingsService/PartnerService';
import { extractError } from '@iso/lib/helpers/api';

function* submitPartner({ id, data }) {
    try {
        let response;
        if(id) {
            response = yield call(PartnerService.editPartner, id, data);
        } else {
            response = yield call(PartnerService.addPartner, data);
        }
        yield put(actions.submitPartnerSucceeded(response.data));
    } catch (error) {
        yield put(actions.submitPartnerFailed(extractError(error)));
    }
}

function* getPartnerType({}) {
    try {
        const response = yield call(PartnerService.getPartnerType);
        yield put(actions.getPartnerTypeSucceeded(response.data));
    } catch (error) {
        yield put(actions.getPartnerTypeFailed(extractError(error)));
    }
}

function* watchSubmitPartner() {
    yield takeEvery(
        types.REQUEST_SUBMIT_PARTNER,
        submitPartner,
    );
}

function* watchGetPartnerType() {
    yield takeEvery(
        types.REQUEST_GET_PARTNER_TYPE,
        getPartnerType,
    );
}

function* createSaga() {
    yield all([
        fork(watchSubmitPartner),
        fork(watchGetPartnerType),
    ]);
}

export default createSaga;