import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { types, actions } from './reducer';
import { extractError } from '@iso/lib//helpers/api';
import SizeService from '@iso/services/SettingsService/ProductService/SizeService';

function* viewSize({ id }) {
    try {
        const response = yield call(SizeService.getOne, id);
        yield put(actions.viewSizeSucceeded(response.data));
    } catch (error) {
        yield put(actions.viewSizeFailed(extractError(error)));
    }
}

function* watchViewSize() {
    yield takeEvery(
        types.REQUEST_VIEW_SIZE,
        viewSize,
    );
}

function* viewSaga() {
    yield all([
        fork(watchViewSize),
    ]);
}

export default viewSaga;
