import { all } from 'redux-saga/effects';
import typeSaga from './type/saga';
import statusTypeSaga from './statusType/saga';

function* ordersSaga() {
    yield all([
        typeSaga(),
        statusTypeSaga(),
    ]);
}

export default ordersSaga;