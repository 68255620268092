import { all, fork, takeEvery } from 'redux-saga/effects';
import { types } from './reducer';
import pagination from '@iso/lib/helpers/pagination';
import PartnerService from '@iso/services/SettingsService/PartnerService';

function* watchFetchPartners() {
    yield takeEvery(
        types.REQUEST_FETCH_PARTNERS,
        pagination('partners').saga(PartnerService),
    );
}

function* paginationSaga() {
    yield all([
        fork(watchFetchPartners),
    ]);
}

export default paginationSaga;