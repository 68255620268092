const options = [
    {
        key: "view",
        label: "sidebar.dashboard",
        leftIcon: "ion-home",
    },
    {
        key: "products",
        label: "sidebar.products",
        leftIcon: "ion-clipboard",
    },
    {
        key: "orders",
        label: "sidebar.orders",
        leftIcon: "ion-clipboard",
        children: [
            {
                key: "order/in",
                label: "sidebar.order/in",
            },
            {
                key: "order/out",
                label: "sidebar.order/out",
            }
        ]
    },
    {
        key: "promotions",
        label: "sidebar.promotions",
        leftIcon: "ion-clipboard",
        children: [
            {
                key: "promotions/marketing",
                label: "sidebar.promotions.marketing",
            },
            {
                key: "promotions/promotion",
                label: "sidebar.promotions.promotion",
            }
        ]
    },
    {
        key: "reporting",
        label: "sidebar.reporting",
        leftIcon: "ion-clipboard",
        children: [
            {
                key: "advanced/reporting",
                label: "sidebar.advanced/reporting",
            },
            {
                key: "promotion/reporting",
                label: "sidebar.promotion/reporting",
            },
            {
                key: "stock/reporting",
                label: "sidebar.stock/reporting",
            }
        ]
    },
    {
        key: "accounts",
        label: "sidebar.accounts",
        leftIcon: "ion-clipboard",
        children: [
            {
                key: "accounts/users",
                label: "sidebar.accounts.users",
            },
            {
                key: "accounts/roles",
                label: "sidebar.accounts.roles",
            },
            {
                key: "accounts/groups",
                label: "sidebar.accounts.groups",
            }
        ]
    },
    {
        key: "settings",
        label: "sidebar.settings",
        leftIcon: "ion-clipboard",
        children: [
            {
                key: "settings/product",
                label: "sidebar.settings.product",
            },
            {
                key: "settings/composed-product",
                label: "sidebar.settings.composed-product",
            },
            {
                key: "settings/orders",
                label: "sidebar.settings.orders",
            },
            {
                key: "settings/warehouses",
                label: "sidebar.settings.warehouses",
            },
            {
                key: "settings/alerts",
                label: "sidebar.settings.alerts",
            },
            {
                key: "settings/general",
                label: "sidebar.settings.general",
            },
            {
                key: "settings/offers",
                label: "sidebar.settings.offers",
            },
            {
                key: "settings/partner",
                label: "sidebar.settings.partner",
            }
        ]
    }
]
export default options;
