import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import desktopActions from "@iso/redux/desktop/actions";

const DesktopEvents = () => {

  const { setSelectProduct } = desktopActions;
  const dispatch = useDispatch();
  const ReceiveMesaageHandler = (e) => {
    if (e.detail.Com === "product" && e.detail.ComTy === "set_prodCode") {
      dispatch(setSelectProduct(e.detail));
    }
  };

  useEffect(() => {
    document.addEventListener("messageReceiveEvent", ReceiveMesaageHandler);
    return () => {
      document.removeEventListener(
        "messageReceiveEvent",
        ReceiveMesaageHandler
      );
    };
  }, [ReceiveMesaageHandler]);

  return <div></div>;
};
export default DesktopEvents;
