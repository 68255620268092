import { combineReducers } from 'redux';

import products, {
    types as productsTypes,
    actions as productsActions,
} from './products/reducer';

import compositeProducts, {
    types as compositeProductsTypes,
    actions as compositeProductsActions,
} from './compositeProducts/reducer';

import orders, {
    types as ordersTypes,
    actions as ordersActions,
} from './orders/reducer';

import warehouses, {
    types as warehousesTypes,
    actions as warehousesActions,
} from './warehouses/reducer';

import alert, {
    types as alertTypes,
    actions as alertActions,
} from './alert/reducer';

import general, {
    types as generalTypes,
    actions as generalActions,
} from './general/reducer';

import partner, {
    types as partnerTypes,
    actions as partnerActions,
} from './partner/reducer';

import offer, {
    types as offerTypes,
    actions as offerActions,
} from './offer/reducer';

const actions = {
    ...productsActions,
    ...compositeProductsActions,
    ...ordersActions,
    ...warehousesActions,
    ...alertActions,
    ...generalActions,
    ...partnerActions,
    ...offerActions,
};

const types = {
    ...productsTypes,
    ...compositeProductsTypes,
    ...ordersTypes,
    ...warehousesTypes,
    ...alertTypes,
    ...generalTypes,
    ...partnerTypes,
    ...offerTypes,
};

export { types, actions };

const settingsReducer = combineReducers({
    products,
    compositeProducts,
    orders,
    warehouses,
    alert,
    general,
    partner,
    offer,
});

export default settingsReducer;