import pagination from '@iso/lib/helpers/pagination';

const {
    actions: baseActions,
    types,
    reducer,
} = pagination('productGenders');

const actions = {
    requestFetchProductGenders: baseActions.request,
    fetchProductGendersSucceeded: baseActions.succeeded,
    fetchProductGendersFailed: baseActions.failed,
};

export { types, actions };

export default reducer;