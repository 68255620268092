const model = {
    submitting: false,
    errors: null,
    compositeProduct: null,
    success: false,
    mainUnits: [],
    componentUnits: [],
    componentProducts: [],
};

export default model;
