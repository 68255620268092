import {
    takeEvery,
    call,
    all,
    put,
    fork,
} from 'redux-saga/effects';

import { types, actions } from './reducer';
import CurrencyService from '@iso/services/SettingsService/GeneralService/CurrencyService';
import { extractError } from '@iso/lib/helpers/api';

function* submitCurrency({ id, data }) {
    try {
        let response;
        if(id) {
            response = yield call(CurrencyService.editCurrency, id, data);
        } else {
            response = yield call(CurrencyService.addCurrency, data);
        }
        yield put(actions.submitCurrencySucceeded(response.data));
    } catch (error) {
        yield put(actions.submitCurrencyFailed(extractError(error)));
    }
}

function* watchSubmitCurrency() {
    yield takeEvery(
        types.REQUEST_SUBMIT_CURRENCY,
        submitCurrency,
    );
}

function* createSaga() {
    yield all([
        fork(watchSubmitCurrency),
    ]);
}

export default createSaga;