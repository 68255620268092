import BaseService from '../../BaseService';

class TypeService extends BaseService {
    getAll = (filters) => this.post('/warehouse/types', filters || { });

    getOne = (id) => this.get(`/warehouse/type/${id}`);

    deleteOne = (id) => this.delete(`/warehouse/type/${id}`);

    addWarehouseType = (data) => this.post('/warehouse/type', data);

    editWarehouseType = (id, data) => this.put(`/warehouse/type/${id}`, data);
}

export default new TypeService();