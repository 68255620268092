import { createActions } from 'reduxsauce';

const { Types: types, Creators: actions } = createActions({

    // get promotion list by start/stop event
    requestFetchStartStopPromotion: ['data'],
    fetchStartStopPromotionSucceeded: [
        'message',
        'status',
    ],
    fetchStartStopPromotionFailed: ['error'],
});

export default {
    // get promotion list by start/stop event
    [types.REQUEST_FETCH_START_STOP_PROMOTION]: (state) => ({
        ...state,
        create: {
            ...state.pagination,
            startStopLoading: true,
            startStopError: null,
        }
    }),
    [types.FETCH_START_STOP_PROMOTION_SUCCEEDED]: (state, { message, status }) => ({
        ...state,
        create: {
            ...state.pagination,
            startStopLoading: false,
            startStopError: null,
            message,
            status,
        }
    }),
    [types.FETCH_START_STOP_PROMOTION_FAILED]: (state, { error }) => ({
        ...state,
        create: {
            ...state.pagination,
            startStopLoading: false,
            startStopError: error,
        }
    }),
};

export { types, actions };
