import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { types, actions } from './reducer';
import { extractError } from '@iso/lib/helpers/api';
import TopbarAlertService from '@iso/services/TopbarService/TopbarAlertService';

function* checkAlerts() {
    try {
        const response = yield call(TopbarAlertService.checkAlerts);
        yield put(actions.checkAlertsSuccedded(response.data.alert));
    } catch (error) {
        yield put(actions.checkAlertsFailed(extractError(error)));
    }
}

function* watchCheckAlerts() {
    yield takeEvery(
        types.REQUEST_CHECK_ALERTS,
        checkAlerts,
    );
}

function* topbarAlertSaga() {
    yield all([
        fork(watchCheckAlerts),
    ]);
}

export default topbarAlertSaga;
