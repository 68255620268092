import { createActions } from 'reduxsauce';
import { removeSelectedItem } from '@iso/lib//helpers/CRUD';

export const { Types: types, Creators: actions } = createActions({
    requestDeleteAlert: ['id'],
    deleteAlertSucceeded: ['id'],
    deleteAlertFailed: ['error'],
});

export default {
    [types.REQUEST_DELETE_ALERT]: (state, { id }) => ({
        ...state,
        delete: {
            ...state.delete,
            id,
            loading: true,
            error: null,
        },
    }),
    [types.DELETE_ALERT_SUCCEEDED]: (state, { id }) => ({
        ...state,
        data: removeSelectedItem(state.data, id),
        delete: {
            ...state.delete,
            id: null,
            loading: false,
        },
    }),
    [types.DELETE_ALERT_FAILED]: (state, { error }) => ({
        ...state,
        delete: {
            ...state.delete,
            id: null,
            loading: false,
            error,
        },
    }),
};
