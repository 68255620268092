import { all } from 'redux-saga/effects';
import marketingSaga from './marketing/saga';
import promotionSaga from './promotion/saga';

function* promotionsSaga() {
    yield all([
        marketingSaga(),
        promotionSaga(),
    ]);
}

export default promotionsSaga;