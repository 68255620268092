import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { extractError } from '@iso/lib//helpers/api';
import { types, actions } from './reducer';
import PaymentMethodService from '@iso/services/SettingsService/GeneralService/PaymentMethodService';

function* deletePaymentMethod({ id }) {
    try {
        yield call(PaymentMethodService.deleteOne, id);
        yield put(actions.deletePaymentMethodSucceeded(id));
    } catch (error) {
        yield put(actions.deletePaymentMethodFailed(extractError(error)));
    }
}

function* watchDeletePaymentMethod() {
    yield takeEvery(
        types.REQUEST_DELETE_PAYMENT_METHOD,
        deletePaymentMethod,
    );
}

function* deleteSaga() {
    yield all([
        fork(watchDeletePaymentMethod),
    ]);
}

export default deleteSaga;
