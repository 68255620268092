import BaseService from '../BaseService';

class PartnerService extends BaseService {
    getAll = (filters) => this.post('/warehouse/partners', filters || { });

    getOne = (id) => this.get(`/warehouse/partner/${id}`);

    deleteOne = (id) => this.delete(`/warehouse/partner/${id}`);

    addPartner = (data) => this.post('/warehouse/partner', data);

    editPartner = (id, data) => this.put(`/warehouse/partner/${id}`, data);

    getPartnerType = () => this.get('/warehouse/partners/type');
};

export default new PartnerService();