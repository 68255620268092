import { createActions } from 'reduxsauce';

export const { Types: types, Creators: actions } = createActions({
    // view role
    requestViewRole: ['id'],
    viewRoleSucceeded: ['data'],
    viewRoleFailed: ['error'],

    // clear state
    clearRoleViewState: null,
});

export default {
    // view role
    [types.REQUEST_VIEW_ROLE]: (state) => ({
        ...state,
        view: {
            ...state.view,
            loading: true,
            error: null,
        },
    }),
    [types.VIEW_ROLE_SUCCEEDED]: (state, { data }) => ({
        ...state,
        view: {
            ...state.view,
            roleDetails: data,
            loading: false,
        },
    }),
    [types.VIEW_ROLE_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            loading: false,
            error,
        },
    }),

    // clear state
    [types.CLEAR_ROLE_VIEW_STATE]: (state) => ({
        ...state,
        view: {
            ...state.view,
            roleDetails: null,
        },
    }),
}
