import { createActions } from 'reduxsauce';

export const { Types: types, Creators: actions } = createActions({
    // view gender
    requestViewGender: ['id'],
    viewGenderSucceeded: ['data'],
    viewGenderFailed: ['error'],

    // clear state
    clearGenderViewState: null,
});

export default {
    // view gender
    [types.REQUEST_VIEW_GENDER]: (state) => ({
        ...state,
        view: {
            ...state.view,
            loading: true,
            error: null,
        },
    }),
    [types.VIEW_GENDER_SUCCEEDED]: (state, { data }) => ({
        ...state,
        view: {
            ...state.view,
            genderDetails: data,
            loading: false,
        },
    }),
    [types.VIEW_GENDER_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            loading: false,
            error,
        },
    }),

    // clear state
    [types.CLEAR_GENDER_VIEW_STATE]: (state) => ({
        ...state,
        view: {
            ...state.view,
            genderDetails: null,
        },
    }),
}
