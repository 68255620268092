import { createActions } from 'reduxsauce';

export const { Types: types, Creators: actions } = createActions({
    requestDeleteCompositeProduct: ['id'],
    deleteCompositeProductSucceeded: ['id'],
    deleteCompositeProductFailed: ['error'],
});

const removeSelectedItem = (items, deletedItemId, identityKey = 'composed_id') =>
    items.filter((item) => item[identityKey] !== deletedItemId);

export default {
    [types.REQUEST_DELETE_COMPOSITE_PRODUCT]: (state, { id }) => ({
        ...state,
        delete: {
            ...state.delete,
            id,
            loading: true,
            error: null,
        },
    }),
    [types.DELETE_COMPOSITE_PRODUCT_SUCCEEDED]: (state, { id }) => ({
        ...state,
        data: removeSelectedItem(state.data, id),
        delete: {
            ...state.delete,
            id: null,
            loading: false,
        },
    }),
    [types.DELETE_COMPOSITE_PRODUCT_FAILED]: (state, { error }) => ({
        ...state,
        delete: {
            ...state.delete,
            id: null,
            loading: false,
            error,
        },
    }),
};
