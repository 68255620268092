import { createActions, createReducer } from 'reduxsauce';
import initialState from './model';

const { Types: types, Creators: actions } = createActions({

    // check for alerts
    requestCheckAlerts: [null],
    checkAlertsSuccedded: ['hasAlert'],
    checkAlertsFailed: ['error'],
});

export default createReducer(initialState, {

    // check for alerts
    [types.REQUEST_CHECK_ALERTS]: (state) => ({
        ...state,
        loading: true,
        error: null,
    }),
    [types.CHECK_ALERTS_SUCCEDDED]: (state, { hasAlert }) => ({
        ...state,
        alerts: hasAlert,
        loading: false,
    }),
    [types.CHECK_ALERTS_FAILED]: (state, { error }) => ({
        ...state,
        loading: false,
        error,
    })
});

export { types, actions };
