import { createActions } from 'reduxsauce';

const { Types: types, Creators: actions } = createActions({
    // add warehouse
    requestSubmitWarehouse: ['id', 'data'],
    submitWarehouseSucceeded: ['data'],
    submitWarehouseFailed: ['errors'],

    // clear state
    clearWarehouseCreateState: null,
});

export default {
    // add warehouse
    [types.REQUEST_SUBMIT_WAREHOUSE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_WAREHOUSE_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            warehouseDetails: data,
            success: true,
        },
    }),
    [types.SUBMIT_WAREHOUSE_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            success: false,
            errors,
        },
    }),

    // clear state
    [types.CLEAR_WAREHOUSE_CREATE_STATE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            warehouseDetails: null,
            success: false,
            errors: null,
        },
    }),
};

export { types, actions };