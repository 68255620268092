import { all, fork, takeEvery } from 'redux-saga/effects';
import { types } from './reducer';
import pagination from '@iso/lib/helpers/pagination';
import StockService from '@iso/services/ReportingService/StockService';

function* watchFetchStock() {
    yield takeEvery(
        types.REQUEST_FETCH_STOCK,
        pagination('stock').saga(StockService),
    );
}

function* stockReportingSaga() {
    yield all([
        fork(watchFetchStock),
    ]);
}

export default stockReportingSaga;