import {
    takeEvery,
    call,
    all,
    put,
    fork,
} from 'redux-saga/effects';

import { types, actions } from './reducer';
import PaymentMethodService from '@iso/services/SettingsService/GeneralService/PaymentMethodService';
import { extractError } from '@iso/lib/helpers/api';

function* submitPaymentMethod({ id, data }) {
    try {
        let response;
        if(id) {
            response = yield call(PaymentMethodService.editPaymentMethod, id, data);
        } else {
            response = yield call(PaymentMethodService.addPaymentMethod, data);
        }
        yield put(actions.submitPaymentMethodSucceeded(response.data));
    } catch (error) {
        yield put(actions.submitPaymentMethodFailed(extractError(error)));
    }
}

function* watchSubmitPaymentMethod() {
    yield takeEvery(
        types.REQUEST_SUBMIT_PAYMENT_METHOD,
        submitPaymentMethod,
    );
}

function* createSaga() {
    yield all([
        fork(watchSubmitPaymentMethod),
    ]);
}

export default createSaga;