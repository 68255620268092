const model = {
    submitting: false,
    errors: null,
    orderOut: [],
    success: false,
    productPriceDetails: [],

    allProducts: [],

    // in case of using barcode
    units: [],
    barcodeProducts: [],
};

export default model;
