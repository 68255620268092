import {
    takeEvery,
    call,
    all,
    put,
    fork,
} from 'redux-saga/effects';

import { types, actions } from './reducer';
import GroupService from '@iso/services/AccountService/GroupService';
import { extractError } from '@iso/lib/helpers/api';

function* submitGroup({ id, data }) {
    try {
        let response;
        if(id) {
            response = yield call(GroupService.editGroup, id, data);
        } else {
            response = yield call(GroupService.addGroup, data);
        }
        yield put(actions.submitGroupSucceeded(response.data));
    } catch (error) {
        yield put(actions.submitGroupFailed(extractError(error)));
    }
}

function* watchSubmitGroup() {
    yield takeEvery(
        types.REQUEST_SUBMIT_GROUP,
        submitGroup,
    );
}

function* createSaga() {
    yield all([
        fork(watchSubmitGroup),
    ]);
}

export default createSaga;