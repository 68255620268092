import { createActions } from 'reduxsauce';

const { Types: types, Creators: actions } = createActions({
    // add season
    requestSubmitSeason: ['id', 'data'],
    submitSeasonSucceeded: ['data'],
    submitSeasonFailed: ['errors'],

    // clear state
    clearSeasonCreateState: null,
});

export default {
    // add season
    [types.REQUEST_SUBMIT_SEASON]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_SEASON_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            seasonDetails: data,
            success: true,
        },
    }),
    [types.SUBMIT_SEASON_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            success: false,
            errors,
        },
    }),

    // clear state
    [types.CLEAR_SEASON_CREATE_STATE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            seasonDetails: null,
            success: false,
            errors: null,
        },
    }),
};

export { types, actions };