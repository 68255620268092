import { createActions } from 'reduxsauce';

export const { Types: types, Creators: actions } = createActions({
    // view category
    requestViewCategory: ['id'],
    viewCategorySucceeded: ['data'],
    viewCategoryFailed: ['error'],

    // clear state
    clearCategoryViewState: null,
});

export default {
    // view category
    [types.REQUEST_VIEW_CATEGORY]: (state) => ({
        ...state,
        view: {
            ...state.view,
            loading: true,
            error: null,
        },
    }),
    [types.VIEW_CATEGORY_SUCCEEDED]: (state, { data }) => ({
        ...state,
        view: {
            ...state.view,
            categoryDetails: data,
            loading: false,
        },
    }),
    [types.VIEW_CATEGORY_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            loading: false,
            error,
        },
    }),

    // clear state
    [types.CLEAR_CATEGORY_VIEW_STATE]: (state) => ({
        ...state,
        view: {
            ...state.view,
            categoryDetails: null,
        },
    }),
}
