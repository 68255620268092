import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { types, actions } from './reducer';
import { extractError } from '@iso/lib//helpers/api';
import GenderService from '@iso/services/SettingsService/ProductService/GenderService';

function* viewGender({ id }) {
    try {
        const response = yield call(GenderService.getOne, id);
        yield put(actions.viewGenderSucceeded(response.data));
    } catch (error) {
        yield put(actions.viewGenderFailed(extractError(error)));
    }
}

function* watchViewGender() {
    yield takeEvery(
        types.REQUEST_VIEW_GENDER,
        viewGender,
    );
}

function* viewSaga() {
    yield all([
        fork(watchViewGender),
    ]);
}

export default viewSaga;
