import {
    fork,
    all,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { types, actions } from './reducer';
import { extractError } from '@iso/lib/helpers/api';
import ProfileService from '@iso/services/TopbarService/ProfileService';

function* submitProfile({ data }) {
    try {
        const response = yield call(ProfileService.updateProfile, data);
        yield put(actions.submitProfileSucceeded(response.data));
    } catch (error) {
        yield put(actions.submitProfileFailed(extractError(error)));
    }
}

function* changePassword({ data }) {
    try {
        const response = yield call(ProfileService.changePassword, data);
        yield put(actions.changePasswordSucceeded(response.message));
    } catch (error) {
        yield put(actions.changePasswordFailed(extractError(error)));
    }
}
 
function* watchSubmitProfile() {
    yield takeEvery(
        types.REQUEST_SUBMIT_PROFILE,
        submitProfile,
    );
}

function* watchChangePassword() {
    yield takeEvery(
        types.REQUEST_CHANGE_PASSWORD,
        changePassword,
    );
}

function* profileSaga() {
    yield all([
        fork(watchSubmitProfile),
        fork(watchChangePassword),
    ]);
}

export default profileSaga;