import BaseService from '../../BaseService';

class BaseUnitService extends BaseService {
    getAll = (filters) => this.post('/warehouse/product/baseUnits', filters || { });

    getOne = (id) => this.get(`/warehouse/product/baseUnit/${id}`);

    deleteOne = (id) => this.delete(`/warehouse/product/baseUnit/${id}`);

    addBaseUnit = (data) => this.post('/warehouse/product/baseUnit', data);

    editBaseUnit = (id, data) => this.put(`/warehouse/product/baseUnit/${id}`, data);
}

export default new BaseUnitService();