import pagination from '@iso/lib/helpers/pagination';

const {
    actions: baseActions,
    types,
    reducer,
} = pagination('productBaseUnits');

const actions = {
    requestFetchProductBaseUnits: baseActions.request,
    fetchProductBaseUnitsSucceeded: baseActions.succeeded,
    fetchProductBaseUnitsFailed: baseActions.failed,
};

export { types, actions };

export default reducer;