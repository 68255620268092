import pagination from '@iso/lib/helpers/pagination';

const {
    actions: baseActions,
    types,
    reducer,
} = pagination('groups');

const actions = {
    requestFetchGroups: baseActions.request,
    fetchGroupsSucceeded: baseActions.succeeded,
    fetchGroupsFailed: baseActions.failed,
};

export { types, actions };

export default reducer;