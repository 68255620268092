import { combineReducers } from 'redux';

import sales, {
    types as salesTypes,
    actions as salesActions,
} from './sales/reducer';

import promotion, {
    types as promotionTypes,
    actions as promotionActions,
} from './promotion/reducer';

import stock, {
    types as stockTypes,
    actions as stockActions,
} from './stock/reducer';

const actions = {
    ...salesActions,
    ...promotionActions,
    ...stockActions,
};

const types = {
    ...salesTypes,
    ...promotionTypes,
    ...stockTypes,
};

export { types, actions };

const reportingReducer = combineReducers({
    sales,
    promotion,
    stock,
});

export default reportingReducer;