import { all, fork, takeEvery } from 'redux-saga/effects';
import { types } from './reducer';
import pagination from '@iso/lib/helpers/pagination';
import StatusTypeService from '@iso/services/SettingsService/OrderService/StatusTypeService';

function* watchFetchOrderStatusTypes() {
    yield takeEvery(
        types.REQUEST_FETCH_ORDER_STATUS_TYPES,
        pagination('orderStatusTypes').saga(StatusTypeService),
    );
}

function* paginationSaga() {
    yield all([
        fork(watchFetchOrderStatusTypes),
    ]);
}

export default paginationSaga;