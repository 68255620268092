import { combineReducers } from 'redux';

import users, {
    types as usersTypes,
    actions as usersActions,
} from './users/reducer';

import roles, {
    types as rolesTypes,
    actions as rolesActions,
} from './roles/reducer';

import groups, {
    types as groupsTypes,
    actions as groupsActions,
} from './groups/reducer';

const actions = {
    ...usersActions,
    ...rolesActions,
    ...groupsActions,
};

const types = {
    ...usersTypes,
    ...rolesTypes,
    ...groupsTypes,
};

export { types, actions };

const accountsReducer = combineReducers({
    users,
    roles,
    groups,
});

export default accountsReducer;