import { combineReducers } from 'redux';

import brand, {
    types as brandTypes,
    actions as brandActions,
} from './brand/reducer';

import category, {
    types as categoryTypes,
    actions as categoryActions,
} from './category/reducer';

import gender, {
    types as genderTypes,
    actions as genderActions,
} from './gender/reducer';

import season, {
    types as seasonTypes,
    actions as seasonActions,
} from './season/reducer';

import size, {
    types as sizeTypes,
    actions as sizeActions,
} from './size/reducer';

import productType, {
    types as typeTypes,
    actions as typeActions,
} from './type/reducer';

import baseUnit, {
    types as baseUnitTypes,
    actions as baseUnitActions,
} from './baseUnit/reducer';

import unit, {
    types as unitTypes,
    actions as unitActions,
} from './unit/reducer';

const actions = {
    ...brandActions,
    ...categoryActions,
    ...genderActions,
    ...seasonActions,
    ...sizeActions,
    ...typeActions,
    ...baseUnitActions,
    ...unitActions,
};

const types = {
    ...brandTypes,
    ...categoryTypes,
    ...genderTypes,
    ...seasonTypes,
    ...sizeTypes,
    ...typeTypes,
    ...baseUnitTypes,
    ...unitTypes,
};

export { types, actions };

const productsReducer = combineReducers({
    brand,
    category,
    gender,
    season,
    size,
    productType,
    baseUnit,
    unit,
});

export default productsReducer;
