import pagination from '@iso/lib/helpers/pagination';

const {
    actions: baseActions,
    types,
    reducer,
} = pagination('orderIn');

const actions = {
    requestFetchOrderIn: baseActions.request,
    fetchOrderInSucceeded: baseActions.succeeded,
    fetchOrderInFailed: baseActions.failed,
};

export { types, actions };

export default reducer;