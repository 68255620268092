import { createActions } from 'reduxsauce';

export const { Types: types, Creators: actions } = createActions({
    // view composite product
    requestViewCompositeProduct: ['id'],
    viewCompositeProductSucceeded: ['data'],
    viewCompositeProductFailed: ['error'],

    // clear state
    clearCompositeProductViewState: null,
});

export default {
    // view composite product
    [types.REQUEST_VIEW_COMPOSITE_PRODUCT]: (state) => ({
        ...state,
        view: {
            ...state.view,
            loading: true,
            error: null,
        },
    }),
    [types.VIEW_COMPOSITE_PRODUCT_SUCCEEDED]: (state, { data }) => ({
        ...state,
        view: {
            ...state.view,
            compositeProduct: data,
            loading: false,
        },
    }),
    [types.VIEW_COMPOSITE_PRODUCT_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            loading: false,
            error,
        },
    }),

    // clear state
    [types.CLEAR_COMPOSITE_PRODUCT_VIEW_STATE]: (state) => ({
        ...state,
        view: {
            ...state.view,
            compositeProduct: null,
        },
    }),
}
