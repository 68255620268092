import {
    takeEvery,
    call,
    all,
    put,
    fork,
} from 'redux-saga/effects';

import { types, actions } from './reducer';
import CompositeProductService from '@iso/services/SettingsService/CompositeProductService';
import { extractError } from '@iso/lib/helpers/api';

function* submitCompositeProduct({ id, data }) {
    try {
        let response;
        if(id) {
            response = yield call(CompositeProductService.editProduct, id, data);
        } else {
            response = yield call(CompositeProductService.addProduct, data);
        }
        yield put(actions.submitCompositeProductSucceeded(response.data));
    } catch (error) {
        yield put(actions.submitCompositeProductFailed(extractError(error)));
    }
}

function* getMainProductUnits({ data }) {
    try {
        const response = yield call(CompositeProductService.getProductUnits, data);
        yield put(actions.getMainProductUnitsSucceeded(response.data));
    } catch (error) {
        yield put(actions.getMainProductUnitsFailed(extractError(error)));
    }
}

function* getComponentProductUnits({ data }) {
    try {
        const response = yield call(CompositeProductService.getProductUnits, data);
        yield put(actions.getComponentProductUnitsSucceeded(response.data));
    } catch (error) {
        yield put(actions.getComponentProductUnitsFailed(extractError(error)));
    }
}

function* getComponentProducts({ data }) {
    try {
        const response = yield call(CompositeProductService.getComponentProducts, data);
        yield put(actions.getComponentProductsSucceeded(response.data));
    } catch (error) {
        yield put(actions.getComponentProductsFailed(extractError(error)));
    }
}

function* watchSubmitCompositeProduct() {
    yield takeEvery(
        types.REQUEST_SUBMIT_COMPOSITE_PRODUCT,
        submitCompositeProduct,
    );
}

function* watchGetMainProductUnits() {
    yield takeEvery(
        types.REQUEST_GET_MAIN_PRODUCT_UNITS,
        getMainProductUnits,
    );
}

function* watchGetComponentProductUnits() {
    yield takeEvery(
        types.REQUEST_GET_COMPONENT_PRODUCT_UNITS,
        getComponentProductUnits,
    );
}

function* watchGetComponentProducts() {
    yield takeEvery(
        types.REQUEST_GET_COMPONENT_PRODUCTS,
        getComponentProducts,
    );
}

function* createSaga() {
    yield all([
        fork(watchSubmitCompositeProduct),
        fork(watchGetMainProductUnits),
        fork(watchGetComponentProductUnits),
        fork(watchGetComponentProducts),
    ]);
}

export default createSaga;