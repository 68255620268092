import BaseService from '../BaseService';

class UserService extends BaseService {
    getAll = (filters) => this.post('/warehouse/users', filters || { });

    getOne = (id) => this.get(`/warehouse/user/${id}`);

    deleteOne = (id) => this.delete(`/warehouse/user/${id}`);

    addUser = (data) => this.post('/warehouse/user', data);

    editUser = (id, data) => this.put(`/warehouse/user/${id}`, data);
}

export default new UserService();