import {
    takeEvery,
    call,
    all,
    put,
    fork,
} from 'redux-saga/effects';

import { types, actions } from './reducer';
import CompanyService from '@iso/services/SettingsService/GeneralService/CompanyService';
import { extractError } from '@iso/lib/helpers/api';

function* submitCompany({ data }) {
    try {
        const response = yield call(CompanyService.editCompany, data);
        yield put(actions.submitCompanySucceeded(response.data));
    } catch (error) {
        yield put(actions.submitCompanyFailed(extractError(error)));
    }
}

function* getCountries({ data }) {
    try {
        const response = yield call(CompanyService.getCountries, data);
        yield put(actions.getCountriesSucceeded(response.data));
    } catch (error) {
        yield put(actions.getCountriesFailed(extractError(error)));
    }
}

function* watchSubmitCompany() {
    yield takeEvery(
        types.REQUEST_SUBMIT_COMPANY,
        submitCompany,
    );
}

function* watchGetCountries() {
    yield takeEvery(
        types.REQUEST_GET_COUNTRIES,
        getCountries,
    );
}


function* createSaga() {
    yield all([
        fork(watchSubmitCompany),
        fork(watchGetCountries),
    ]);
}

export default createSaga;