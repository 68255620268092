import BaseService from '../BaseService';

class ProfileService extends BaseService {

    updateProfile = (data) => this.put('/warehouse/profile/edit', data);

    changePassword = (data) => this.put('/warehouse/profile/changepassword', data);
}

export default new ProfileService();
