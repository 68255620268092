import options from './options';
import { isServer } from './isServer';

export function getDefaultPath() {
  const getParent = route => {
    const parent = [];
    if (!route) return parent;
    options.forEach(option => {
        if (option.children) {
            option.children.forEach(child => {
                if (route.match(child.key)) {
                    parent.push(child.key);
                    parent.push(option.key);
                }
            });
        } else {
            if (route.match(option.key)) {
                parent.push(option.key);
            }
        }
    });
    return parent;
  };

  if (!isServer && window.location.pathname) {
        const route = window.location.pathname
        if (route) {
        return getParent(route);
        }
    }
  return [];
}
