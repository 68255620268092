import pagination from '@iso/lib/helpers/pagination';

const {
    actions: baseActions,
    types,
    reducer,
} = pagination('warehouses');

const actions = {
    requestFetchWarehouses: baseActions.request,
    fetchWarehousesSucceeded: baseActions.succeeded,
    fetchWarehousesFailed: baseActions.failed,
};

export { types, actions };

export default reducer;