import { all } from 'redux-saga/effects';
import orderInSaga from './orderIn/saga';
import orderOutSaga from './orderOut/saga';

function* ordersSaga() {
    yield all([
        orderInSaga(),
        orderOutSaga(),
    ]);
}

export default ordersSaga;