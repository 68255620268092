import { all } from 'redux-saga/effects';
import authenticationSaga from '@iso/redux/auth/saga';
import menuSaga from '@iso/redux/menu/saga';
import routeSaga from '@iso/redux/routes/saga';
import dashboardSaga from '@iso/redux/dashboard/saga';
import productsSaga from '@iso/redux/products/saga';
import ordersSaga from '@iso/redux/orders/saga';
import promotionsSaga from '@iso/redux/promotions/saga';
import reportingSaga from '@iso/redux/reporting/saga';
import accountsSaga from '@iso/redux/accounts/saga';
import settingsSaga from '@iso/redux/settings/saga';
import permissionSaga from '@iso/redux/permissions/saga';
import downloadSaga from '@iso/redux/download/saga';
import topbarSaga from '@iso/redux/topbar/saga';
import allowedWarehousesSaga from '@iso/redux/allowedWarehouses/saga';

export default function* rootSaga() {
  yield all([
    authenticationSaga(),
    menuSaga(),
    routeSaga(),
    dashboardSaga(),
    productsSaga(),
    ordersSaga(),
    promotionsSaga(),
    reportingSaga(),
    accountsSaga(),
    settingsSaga(),
    permissionSaga(),
    downloadSaga(),
    topbarSaga(),
    allowedWarehousesSaga(),
  ]);
}
