import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { types, actions } from './reducer';
import { extractError } from '@iso/lib//helpers/api';
import ProductService from '@iso/services/ProductService';

function* viewProduct({ id }) {
    try {
        const response = yield call(ProductService.getOne, id);
        const {
            product: productDetails,
            stock: stockDetails,
        } = response.data;
        yield put(actions.viewProductSucceeded(
            productDetails,
            stockDetails,
        ));
    } catch (error) {
        yield put(actions.viewProductFailed(extractError(error)));
    }
}

function* watchViewProduct() {
    yield takeEvery(
        types.REQUEST_VIEW_PRODUCT,
        viewProduct,
    );
}

function* viewSaga() {
    yield all([
        fork(watchViewProduct),
    ]);
}

export default viewSaga;
