const model = {
    submitting: false,
    errors: null,
    product: null,
    success: false,
    barcode: "",

    // brand categories
    brandCategories: [],
    loading: false,
};

export default model;
