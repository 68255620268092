import {
    takeEvery,
    call,
    all,
    put,
    fork,
} from 'redux-saga/effects';

import { types, actions } from './reducer';
import TypeService from '@iso/services/SettingsService/ProductService/TypeService';
import { extractError } from '@iso/lib/helpers/api';

function* submitType({ id, data }) {
    try {
        let response;
        if(id) {
            response = yield call(TypeService.editType, id, data);
        } else {
            response = yield call(TypeService.addType, data);
        }
        yield put(actions.submitTypeSucceeded(response.data));
    } catch (error) {
        yield put(actions.submitTypeFailed(extractError(error)));
    }
}

function* watchSubmitType() {
    yield takeEvery(
        types.REQUEST_SUBMIT_TYPE,
        submitType,
    );
}

function* createSaga() {
    yield all([
        fork(watchSubmitType),
    ]);
}

export default createSaga;