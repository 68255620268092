import pagination from '@iso/lib/helpers/pagination';

const {
    actions: baseActions,
    types,
    reducer,
} = pagination('currencies');

const actions = {
    requestFetchCurrencies: baseActions.request,
    fetchCurrenciesSucceeded: baseActions.succeeded,
    fetchCurrenciesFailed: baseActions.failed,
};

export { types, actions };

export default reducer;