import {
    fork,
    all,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { types, actions } from './reducer';
import { extractError } from '@iso/lib/helpers/api';
import PermissionService from '@iso/services/PermissionService';

function* getUserPermissions() {
    try {
        const response = yield call(PermissionService.getUserPermissions);
        yield put(actions.getUserPermissionsSucceeded(
            response.data.permission,
            response.data.profile,
        ));
    } catch (error) {
        yield put(actions.getUserPermissionsFailed(extractError(error)));
    }
}
 
function* watchGetUserPermissions() {
    yield takeEvery(
        types.REQUEST_GET_USER_PERMISSIONS,
        getUserPermissions,
    );
}

function* permissionsSaga() {
    yield all([
        fork(watchGetUserPermissions),
    ]);
}

export default permissionsSaga;