import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { extractError } from '@iso/lib/helpers/api';
import OrderOutService from '@iso/services/OrderService/OrderOutService';
import { types, actions } from './reducer';

function* deleteOrderOut({ id }) {
    try {
        yield call(OrderOutService.deleteOne, id);
        yield put(actions.deleteOrderOutSucceeded(id));
    } catch (error) {
        yield put(actions.deleteOrderOutFailed(extractError(error)));
    }
}

function* watchDeleteOrderOut() {
    yield takeEvery(
        types.REQUEST_DELETE_ORDER_OUT,
        deleteOrderOut,
    );
}

function* deleteSaga() {
    yield all([
        fork(watchDeleteOrderOut),
    ]);
}

export default deleteSaga;
