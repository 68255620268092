import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { types, actions } from './reducer';
import { extractError } from '@iso/lib//helpers/api';
import BaseUnitService from '@iso/services/SettingsService/ProductService/BaseUnitService';

function* viewBaseUnit({ id }) {
    try {
        const response = yield call(BaseUnitService.getOne, id);
        yield put(actions.viewBaseUnitSucceeded(response.data));
    } catch (error) {
        yield put(actions.viewBaseUnitFailed(extractError(error)));
    }
}

function* watchViewBaseUnit() {
    yield takeEvery(
        types.REQUEST_VIEW_BASE_UNIT,
        viewBaseUnit,
    );
}

function* viewSaga() {
    yield all([
        fork(watchViewBaseUnit),
    ]);
}

export default viewSaga;
