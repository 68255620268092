import { combineReducers } from 'redux';

import orderIn, {
    types as orderInTypes,
    actions as orderInActions,
} from './orderIn/reducer';

import orderOut, {
    types as orderOutTypes,
    actions as orderOutActions,
} from './orderOut/reducer';

const actions = {
    ...orderInActions,
    ...orderOutActions,
};

const types = {
    ...orderInTypes,
    ...orderOutTypes,
};

export { types, actions };

const ordersReducer = combineReducers({
    orderIn,
    orderOut,
});

export default ordersReducer;