import { all, fork, takeEvery } from 'redux-saga/effects';
import { types } from './reducer';
import pagination from '@iso/lib/helpers/pagination';
import RoleService from '@iso/services/AccountService/RoleService';

function* watchFetchRoles() {
    yield takeEvery(
        types.REQUEST_FETCH_ROLES,
        pagination('roles').saga(RoleService),
    );
}

function* paginationSaga() {
    yield all([
        fork(watchFetchRoles),
    ]);
}

export default paginationSaga;