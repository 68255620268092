import { all, fork, takeEvery } from 'redux-saga/effects';
import { types } from './reducer';
import pagination from '@iso/lib/helpers/pagination';
import OrderInService from '@iso/services/OrderService/OrderInService';

function* watchFetchOrderIn() {
    yield takeEvery(
        types.REQUEST_FETCH_ORDER_IN,
        pagination('orderIn').saga(OrderInService),
    );
}

function* paginationSaga() {
    yield all([
        fork(watchFetchOrderIn),
    ]);
}

export default paginationSaga;