import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { types, actions } from './reducer';
import { extractError } from '@iso/lib//helpers/api';
import PartnerService from '@iso/services/SettingsService/PartnerService';

function* viewPartner({ id }) {
    try {
        const response = yield call(PartnerService.getOne, id);
        yield put(actions.viewPartnerSucceeded(response.data));
    } catch (error) {
        yield put(actions.viewPartnerFailed(extractError(error)));
    }
}

function* watchViewPartner() {
    yield takeEvery(
        types.REQUEST_VIEW_PARTNER,
        viewPartner,
    );
}

function* viewSaga() {
    yield all([
        fork(watchViewPartner),
    ]);
}

export default viewSaga;
