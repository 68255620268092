import { createActions } from 'reduxsauce';

const { Types: types, Creators: actions } = createActions({
    // add warehouse type
    requestSubmitWarehouseType: ['id', 'data'],
    submitWarehouseTypeSucceeded: ['data'],
    submitWarehouseTypeFailed: ['errors'],

    // clear state
    clearWarehouseTypeCreateState: null,
});

export default {
    // add warehouse type
    [types.REQUEST_SUBMIT_WAREHOUSE_TYPE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_WAREHOUSE_TYPE_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            typeDetails: data,
            success: true,
        },
    }),
    [types.SUBMIT_WAREHOUSE_TYPE_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            success: false,
            errors,
        },
    }),

    // clear state
    [types.CLEAR_WAREHOUSE_TYPE_CREATE_STATE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            typeDetails: null,
            success: false,
            errors: null,
        },
    }),
};

export { types, actions };