import { all, fork, takeEvery } from 'redux-saga/effects';
import { types } from './reducer';
import pagination from '@iso/lib/helpers/pagination';
import BaseUnitService from '@iso/services/SettingsService/ProductService/BaseUnitService';

function* watchFetchProductBaseUnits() {
    yield takeEvery(
        types.REQUEST_FETCH_PRODUCT_BASE_UNITS,
        pagination('productBaseUnits').saga(BaseUnitService),
    );
}

function* paginationSaga() {
    yield all([
        fork(watchFetchProductBaseUnits),
    ]);
}

export default paginationSaga;