import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { types, actions } from './reducer';
import { extractError } from '@iso/lib//helpers/api';
import DownloadService from '@iso/services/DownloadService';

function* getUrlToDownloadStock({ data }) {
    try {
        const response = yield call(DownloadService.getUrlToDownloadStock, data);
        yield put(actions.getUrlToDownloadStockSucceeded(response.data));
    } catch (error) {
        yield put(actions.getUrlToDownloadStockFailed(extractError(error)));
    }
}

function* getUrlToDownloadSales({ data }) {
    try {
        const response = yield call(DownloadService.getUrlToDownloadSales, data);
        yield put(actions.getUrlToDownloadSalesSucceeded(response.data));
    } catch (error) {
        yield put(actions.getUrlToDownloadSalesFailed(extractError(error)));
    }
}

function* downloadExcelFile({ data }) {
    try {
        yield call(DownloadService.downloadExcelFile, data);
        yield put(actions.downloadExcelFileSucceeded(true));
    } catch (error) {
        yield put(actions.downloadExcelFileFailed(extractError(error)));
    }
}

function* watchGetUrlToDownloadStock() {
    yield takeEvery(
        types.REQUEST_GET_URL_TO_DOWNLOAD_STOCK,
        getUrlToDownloadStock,
    );
}

function* watchGetUrlToDownloadSales() {
    yield takeEvery(
        types.REQUEST_GET_URL_TO_DOWNLOAD_SALES,
        getUrlToDownloadSales,
    );
}

function* watchDownloadExcelFile() {
    yield takeEvery(
        types.DOWNLOAD_EXCEL_FILE,
        downloadExcelFile,
    );
}

function* downloadSaga() {
    yield all([
        fork(watchGetUrlToDownloadStock),
        fork(watchGetUrlToDownloadSales),
        fork(watchDownloadExcelFile),
    ]);
}

export default downloadSaga;
