import { createActions, createReducer } from 'reduxsauce';
import initialState from './model';

const { Types: types, Creators: actions } = createActions({
 
    // get routes
    requestGetRoutes: [null],
    getRoutesSucceeded: ['data'],
    getRoutesFailed: ['error'],
 

});

export default createReducer(initialState, {
    [types.REQUEST_GET_ROUTES]: (state) => ({
        ...state,
        loading: true,
        error: null,
    }),
    [types.GET_ROUTES_SUCCEEDED]: (state, { data }) => ({
        ...state,
       arrayRoutes: data,
        loading: false,
    }),
    [types.GET_ROUTES_FAILED]: (state, { error }) => ({
        ...state,
        loading: false,
        error,
    }),
});

export { types, actions };