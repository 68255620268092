import { createActions, createReducer } from 'reduxsauce';
import initialState from './model';

const { Types: types, Creators: actions } = createActions({
    // update profile
    requestSubmitProfile: ['data'],
    submitProfileSucceeded: ['data'],
    submitProfileFailed: ['errors'],

    // change password
    requestChangePassword: ['data'],
    changePasswordSucceeded: ['message'],
    changePasswordFailed: ['errors'],

    // clear state
    clearProfileState: null,
});

export default createReducer(initialState, {
    // update profile
    [types.REQUEST_SUBMIT_PROFILE]: (state) => ({
        ...state,
        profileSubmitting: true,
        success: false,
        errors: null,
    }),
    [types.SUBMIT_PROFILE_SUCCEEDED]: (state, { data }) => ({
        ...state,
        profileSubmitting: false,
        profile: data,
        success: true,
    }),
    [types.SUBMIT_PROFILE_FAILED]: (state, { errors }) => ({
        ...state,
        profileSubmitting: false,
        success: false,
        errors,
    }),

    // change password
    [types.REQUEST_CHANGE_PASSWORD]: (state) => ({
        ...state,
        passwordSubmitting: true,
        errors: null,
    }),
    [types.CHANGE_PASSWORD_SUCCEEDED]: (state, { message }) => ({
        ...state,
        passwordSuccess: message,
        passwordSubmitting: false,
    }),
    [types.CHANGE_PASSWORD_FAILED]: (state, { errors }) => ({
        ...state,
        passwordSubmitting: false,
        errors,
    }),

    // clear state
    [types.CLEAR_PROFILE_STATE]: (state) => ({
        ...state,
        profileSubmitting: false,
        passwordSubmitting: false,
        errors: null,
        profile: null,
        passwordSuccess: false,
        success: false,
    }),
});

export { types, actions };