import BaseService from '../../BaseService';

class TypeService extends BaseService {
    getAll = () => this.get('/warehouse/order/statusTypes');

    getOne = (id) => this.get(`/warehouse/order/statusType/${id}`);

    deleteOne = (id) => this.delete(`/warehouse/order/statusType/${id}`);

    addOrderStatusType = (data) => this.post('/warehouse/order/statusType', data);

    editOrderStatusType = (id, data) => this.put(`/warehouse/order/statusType/${id}`, data);
}

export default new TypeService();