import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { extractError } from '@iso/lib//helpers/api';
import { types, actions } from './reducer';
import UnitService from '@iso/services/SettingsService/ProductService/UnitService';

function* deleteUnit({ id }) {
    try {
        yield call(UnitService.deleteOne, id);
        yield put(actions.deleteUnitSucceeded(id));
    } catch (error) {
        yield put(actions.deleteUnitFailed(extractError(error)));
    }
}

function* watchDeleteUnit() {
    yield takeEvery(
        types.REQUEST_DELETE_UNIT,
        deleteUnit,
    );
}

function* deleteSaga() {
    yield all([
        fork(watchDeleteUnit),
    ]);
}

export default deleteSaga;
