import BaseService from '../BaseService';

class RoleService extends BaseService {
    getAll = () => this.get('/warehouse/role/list');

    getOne = (id) => this.get(`/warehouse/role/${id}`);

    deleteOne = (id) => this.delete(`/warehouse/role/${id}`);

    addRole = (data) => this.post('/warehouse/role', data);

    editRole = (id, data) => this.put(`/warehouse/role/${id}`, data);

    getPermissions = () => this.get('/warehouse/permission/list');
}

export default new RoleService();