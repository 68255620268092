import { firstToUpper } from './string';

export const createEntityActionTypes = (entity, action) => ({
    request: `request${firstToUpper(action)}${entity}`,
    succeeded: `${action + entity}Succeeded`,
    failed: `${action + entity}Failed`,
});

export const createEntityCrudActionTypes = (entity) => {
    const formattedEntityName = firstToUpper(entity);
    return ['fetch', 'create', 'update', 'delete'].reduce((total, method) => ({
        ...total,
        [method]: createEntityActionTypes(formattedEntityName, method),
    }), {});
};
