import {
    takeEvery,
    call,
    all,
    put,
    fork,
} from 'redux-saga/effects';

import { types, actions } from './reducer';
import CategoryService from '@iso/services/SettingsService/ProductService/CategoryService';
import { extractError } from '@iso/lib/helpers/api';

function* submitCategory({ id, data }) {
    try {
        let response;
        if(id) {
            response = yield call(CategoryService.editCategory, id, data);
        } else {
            response = yield call(CategoryService.addCategory, data);
        }
        yield put(actions.submitCategorySucceeded(response.data));
    } catch (error) {
        yield put(actions.submitCategoryFailed(extractError(error)));
    }
}

function* watchSubmitCategory() {
    yield takeEvery(
        types.REQUEST_SUBMIT_CATEGORY,
        submitCategory,
    );
}

function* createSaga() {
    yield all([
        fork(watchSubmitCategory),
    ]);
}

export default createSaga;