import {
    takeEvery,
    call,
    all,
    put,
    fork,
} from 'redux-saga/effects';

import { types, actions } from './reducer';
import StatusTypeService from '@iso/services/SettingsService/OrderService/StatusTypeService';
import { extractError } from '@iso/lib/helpers/api';

function* submitOrderStatusType({ id, data }) {
    try {
        let response;
        if(id) {
            response = yield call(StatusTypeService.editOrderStatusType, id, data);
        } else {
            response = yield call(StatusTypeService.addOrderStatusType, data);
        }
        yield put(actions.submitOrderStatusTypeSucceeded(response.data));
    } catch (error) {
        yield put(actions.submitOrderStatusTypeFailed(extractError(error)));
    }
}

function* watchSubmitOrderStatusType() {
    yield takeEvery(
        types.REQUEST_SUBMIT_ORDER_STATUS_TYPE,
        submitOrderStatusType,
    );
}

function* createSaga() {
    yield all([
        fork(watchSubmitOrderStatusType),
    ]);
}

export default createSaga;