import { combineReducers } from 'redux';

import currency, {
    types as currencyTypes,
    actions as currencyActions,
} from './currency/reducer';

import paymentMethod, {
    types as paymentMethodTypes,
    actions as paymentMethodActions,
} from './paymentMethod/reducer';

import company, {
    types as companyTypes,
    actions as companyActions,
} from './company/reducer';

const actions = {
    ...currencyActions,
    ...paymentMethodActions,
    ...companyActions,
};

const types = {
    ...currencyTypes,
    ...paymentMethodTypes,
    ...companyTypes,
};

export { types, actions };

const generalReducer = combineReducers({
    currency,
    paymentMethod,
    company,
});

export default generalReducer;
