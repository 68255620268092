import BaseService from '../BaseService';

class PromotionService extends BaseService {
    getAll = (filters) => this.post('/warehouse/promotion/promotionsDurationList', filters || { });

    startStopPromotion = (data) => this.put('/warehouse/promotion/promotionDuration', data);

    deleteOne = (id, data) => this.delete(`/warehouse/promotion/delete/${id}`, data);
}

export default new PromotionService();