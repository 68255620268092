import {
    takeEvery,
    call,
    all,
    put,
    fork,
} from 'redux-saga/effects';

import { types, actions } from './reducer';
import GenderService from '@iso/services/SettingsService/ProductService/GenderService';
import { extractError } from '@iso/lib/helpers/api';

function* submitGender({ id, data }) {
    try {
        let response;
        if(id) {
            response = yield call(GenderService.editGender, id, data);
        } else {
            response = yield call(GenderService.addGender, data);
        }
        yield put(actions.submitGenderSucceeded(response.data));
    } catch (error) {
        yield put(actions.submitGenderFailed(extractError(error)));
    }
}

function* watchSubmitGender() {
    yield takeEvery(
        types.REQUEST_SUBMIT_GENDER,
        submitGender,
    );
}

function* createSaga() {
    yield all([
        fork(watchSubmitGender),
    ]);
}

export default createSaga;