import { all, fork, takeEvery } from 'redux-saga/effects';
import { types } from './reducer';
import pagination from '@iso/lib/helpers/pagination';
import PaymentMethodService from '@iso/services/SettingsService/GeneralService/PaymentMethodService';

function* watchFetchPaymentMethods() {
    yield takeEvery(
        types.REQUEST_FETCH_PAYMENT_METHODS,
        pagination('paymentMethods').saga(PaymentMethodService),
    );
}

function* paginationSaga() {
    yield all([
        fork(watchFetchPaymentMethods),
    ]);
}

export default paginationSaga;