import { createGlobalStyle } from 'styled-components';
import 'antd/dist/antd.css';

const GlobalStyles = createGlobalStyle`
body {
  -webkit-overflow-scrolling: touch;
}

html h1,
html h2,
html h3,
html h4,
html h5,
html h6,
html a,
html p,
html li,
input,
textarea,
span,
div,
html,
body,
html a {
    margin-bottom: 0;
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

html ul {
    -webkit-padding-start: 0px;
    list-style: none;
    margin-bottom: 0;
}

/*-----------------------------------------------*/
// style for slider tooltip
/*-----------------------------------------------*/

.ant-slider-tooltip {
    padding-bottom: 0px !important;

    .ant-tooltip-inner {
        background-color: transparent;
        color: #9aa7e3;
        letter-spacing: -0.12px;
        box-shadow: none;
        padding: 8px 8px 0px 8px;
    }
    .ant-tooltip-arrow {
        display: none;
    }
}
/*-----------------------------------------------*/
// style for datepicker panel
/*-----------------------------------------------*/

.ant-picker-range .ant-picker-active-bar {
    background: #485696 !important;
}

.ant-picker-dropdown {
    .ant-picker-body {
        padding: 10px 15px 15px 16px !important;
        > table {
            width: auto;
            
            > tbody {
                > tr {
                    .ant-picker-cell-inner {
                        width: 31px;
                        height: 31px;
                        min-width: 31px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                    }

                    .ant-picker-cell {
                        padding: 4px 10px;
                        width: 31px;
                        height: 31px;
                        &::before {
                            height: 31px;
                        }
                    }
                }
            }
        }
    }

    .ant-picker-date-panel {
        width: 100%;
    }

    .ant-picker-header {
        align-items: center;
        border-color: #DDE4EB;
        padding: 28px;
        height: 70px;

        button {
            color: #A6B0BB;
            transition: color 0.3s;

            &:hover {
                color: #485696;
            }
        }

        .ant-picker-header-view {
            font-size: 14px;
            color: #4D677E;
            font-weight: normal;
            line-height: 21px;

            button {
                color: #4D677E;

                &:hover {
                    color: #485696;
                }
            }
        }
    }

    .ant-picker-panel {
        border-color: #E7EBEF;
    }

    .ant-picker-content th {
        font-size: 14px;
        color: #BBC6D1;
        font-weight: normal;
    }

    .ant-picker-content td {
        font-size: 14px;
        color: #4D677E;
        font-weight: normal;

        &.ant-picker-cell {
            color: #BBC6D1;
        }

        &.ant-picker-cell-in-view {
            color: #4D677E;
        }

        &.ant-picker-cell-in-view.ant-picker-cell-today {
            color: #fff;
        }

        &.ant-picker-cell-today {
            .ant-picker-cell-inner {
                &::before {
                    border-radius: 50%;
                }
            }
        }

        &.ant-picker-cell-selected {
            .ant-picker-cell-inner {
                border-radius: 50%;
            }
        }

        &.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
            border-color: #485696;
            background: #485696;
            z-index: -1;
        }

        &.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, &.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, &.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
            background: #485696;
        }
    }
}

.ant-select-dropdown.language-select {
    padding: 10px;
    top: 62px !important;
    border-radius: 5px;

    .ant-select-item {
        border-radius: 5px;
    }

    .ant-select-item-option-content {

        img {
            width: 28px;
            height: 28px;
        }
    
        .language-name {
            font-size: 14px;
            color: #646D82;
        }
    }
}

/*-----------------------------------------------*/
// form style
/*-----------------------------------------------*/

.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
}

.ant-form-item-explain-error {
    font-size: 11.5px;
    text-align: end;
}

.ant-form-item-has-error .ant-picker, .ant-input-affix-wrapper {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
}

/*-----------------------------------------------*/
// slider style
/*-----------------------------------------------*/

.ant-slider {
    margin: 33px 6px 0px 6px;

    .ant-slider-rail {
        background-color: #D8D8D8;
        height: 2px;
    }

    .ant-slider-track {
        background-color: #9aa7e3 !important;
        height: 2px;
    }

    .ant-slider-dot {
        display: none;
    }

    .ant-slider-handle {
        border: 3px solid #9aa7e3 !important;
        background-color: #9aa7e3;
        width: 10px;
        height: 10px;
        margin-top: -4px;

        &:focus {
            box-shadow: none;
        }
    }
}

.ant-slider {
    margin: 2px 6px 0px 6px !important;
}

.ant-slider-min-max-total {
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    > div {
        width: fit-content;
        height: 25px;
        border-radius: 4px;
        background-color: #FFFFFF;
        display: flex;
        align-items: center;
        font-size: 13px;
        color: #9aa7e3;
        font-weight: normal;
        padding: 0 8px;
        line-height: normal;
    }

    .seperator {
        margin: 15px 5px;
        padding: 0;

        &::after {
            content: "";
            display: block;
            width: 10px;
            height: 2px;
            background: #9aa7e3;
        }
    }
}

/*-----------------------------------------------*/
// input
/*-----------------------------------------------*/

.ant-input {
    padding: 4px 10px;
    width: 100%;
    height: 45px;
    font-size: 14px;
    line-height: 1.5;
    background-color: #fff;
    background-image: none;
    color: #495274;
    border: 0px;

    &:focus {
        box-shadow: 0 2px 3px rgba(0,0,0,.08);
    }
}

// input-search
.ant-input-group-wrapper.ant-input-search {
    height: 45px;
    font-size: 14px;
    font-weight: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background-color: #fff;
    overflow: hidden;
    
    .ant-input-group .ant-input {
        border: 0px;
        font-size: 14px;
        color: #495274;
        font-weight: normal;

        &:focus {
            box-shadow: none;
        }
    }

    .ant-input-group .ant-input:hover {
        z-index: 0;
    }

    .ant-input-group-addon {
        background: #fff;
        > button {
            border: none;
            box-shadow: none;

            &::after {
                content: none;
            }
        }
    }
}

// textarea
textarea.ant-input {
    line-height: 2.4 !important;
    height: 45px !important;
}

/*-----------------------------------------------*/
// select
/*-----------------------------------------------*/

.ant-select:not(.ant-pagination-options-size-changer) {
    width: 100%;
    height: 45px;
    font-size: 14px;
    font-weight: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background-color: #fff;
    overflow: hidden;

    .ant-select-selector {
        width: 100%;
        height: 100% !important;
        border: 0 !important;
        cursor: pointer;

        .ant-select-selection-item {
            color: #495274;
            line-height: 45px !important;
        }
    }

    .ant-select-selection-placeholder {
        display: flex;
        align-items: center;
        height: 100%;
        line-height: 1.5;
        font-size: 14px;
        font-weight: normal;
        padding-right: 73px;
    }

    .ant-select-arrow {
        right: 14px !important;
        color: #BBC6D1 !important;
    }

    &.ant-select-multiple {
        height: auto;
        .ant-select-selection-item {
            line-height: 20px !important;
            border: none;
            background: none;
            cursor: pointer;
        }

        &.ant-select-disabled {
            .ant-select-selection-item {
                margin-top: 8px;
            }
        }
    }

    .ant-select-selection-search {
        input {
            height: 40px !important;
        }
    }

    .ant-select-clear {
        width: 14px;
        height: 14px;
    }
}

.ant-select-item-option-content {
    color: #758ca1;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #fff !important;
    font-weight: 100 !important;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    cursor: pointer !important;
}

// pagination select
.ant-select.ant-pagination-options-size-changer {
    font-size: 14px;
    font-weight: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background-color: #fff;
  
    .ant-select-selector {
        height: 35px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none !important;
        color: #758ca1;
    }

    &.ant-select-focused {
        .ant-select-selector {
            box-shadow: none !important;
        }
    }
}

/*-----------------------------------------------*/
// datepicker
/*-----------------------------------------------*/

.ant-picker {
    height: 45px;
    width: 100%;
    cursor: pointer;
   
    .ant-picker-input > input {
        color: #495274;
        cursor: pointer;
    }
}

/*-----------------------------------------------*/
// popover
/*-----------------------------------------------*/

.ant-popover {

    .ant-popover-inner {
        border-radius: 5px;
    }

    .ant-btn:hover {
        color: rgba(0, 0, 0, 0.85);
    }

    .ant-btn-sm {
        height: 30px;
        padding: 0px 14px;
        border-radius: 5px;

        &:hover {
            color: color: rgba(0, 0, 0, 0.85);
            border-color: #d9d9d9;
        }
    }

    .ant-btn-primary.ant-btn-sm {
        background: #7584c7;
        border-color: #7584c7;

        &:hover {
            color: #ffff;
        }
    }
}

&.download-popover {
    .ant-popover-inner-content {
        color: #758CA1;
    }
}

/*-----------------------------------------------*/
// sidebar menu popover
/*-----------------------------------------------*/
    .ant-tooltip-inner {
        background: #485696 !important;

        svg {
            display: none;
        }
    }

    .ant-tooltip-arrow-content {
        background: #485696 !important;
    }

    .ant-menu-dark.ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
        background: #485696 !important;
        padding: 5px 10px;

        .ant-menu-item {
            border-radius: 5px;
            &:hover {
                background-color: rgba(0, 0, 0, 0.2); 
            }
    
            span > a {
                color: #fff;
            }
        }

        .ant-menu-item-selected {
            background-color: #f7b538 !important;
        }
    }

/*-----------------------------------------------*/
// table
/*-----------------------------------------------*/
    .ant-table {
        cursor: pointer;
        border-radius: 5px !important;
        box-shadow:  0 2px 2px rgba(0,0,0,.08);

        .ant-table-thead > tr > th {
            background: #9aa7e3;
            color: #ffff;
            font-weight: bold;
            padding: 24px 30px;
        
            &.ant-table-column-has-sorters:hover {
                background: #9aa7e3 !important;
            }
        
            .ant-table-column-sorter {
                color: #ffff !important;
            }

            .ant-table-column-sorter-up.active {
                color: #f7b538 !important;
            }
        
            .ant-table-column-sorter-down.active {
                color: #f7b538 !important;
            }
        }

        .ant-table-tbody {
            color: #646d82;
            > tr > td {
                padding: 24px 30px;
                border-bottom: 1px solid #D8DDE1;
            }
        }

        .ant-table-container table > thead > tr:first-child th:first-child {
            border-top-left-radius: 5px !important;
        }
    
        .ant-table-container table > thead > tr:first-child th:last-child {
            border-top-right-radius: 5px !important;
        }
    }

/*-----------------------------------------------*/
// table pagination
/*-----------------------------------------------*/
    .ant-pagination {
        font-size: 14px;
        color: #758CA1;
        font-weight: normal;
        line-height: 1.15;
        display: flex;
        justify-content: flex-end;
        align-items: center !important;
        margin-top: 30px !important;

        > li {
            &:not(:last-child){
                margin-right: 15px !important;
            }
        }

        .ant-pagination-prev, .ant-pagination-next {
            min-width: 0;
            height: auto !important;
            line-height: 1;
            button {
                width: 36px;
                height: 36px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #DDE4EB !important;
                border: 0;
                outline: none;
                box-shadow: none;
                border-radius: 100%;

                svg {
                    path {
                        fill: #BBC6D1;
                    }
                }
            }
        }

        .ant-pagination-item {
            min-width: 0;
            line-height: 1;
            width: 36px !important;
            height: 36px !important;
            background-color: #FFFFFF !important;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0;
            outline: none;
            box-shadow: none;
            a {
                text-decoration: none;
                font-size: 14px;
                color: #758CA1 !important;
                font-weight: normal;
                padding: 0;
            }

            &.ant-pagination-item-active {
                background-color: #485696 !important;
                a {
                    color: #fff !important;
                }
            }
        }
    }
`;

export default GlobalStyles;
