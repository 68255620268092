import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { extractError } from '@iso/lib/helpers/api';
import RoleService from '@iso/services/AccountService/RoleService';
import { types, actions } from './reducer';

function* deleteRole({ id }) {
    try {
        yield call(RoleService.deleteOne, id);
        yield put(actions.deleteRoleSucceeded(id));
    } catch (error) {
        yield put(actions.deleteRoleFailed(extractError(error)));
    }
}

function* watchDeleteRole() {
    yield takeEvery(
        types.REQUEST_DELETE_ROLE,
        deleteRole,
    );
}

function* deleteSaga() {
    yield all([
        fork(watchDeleteRole),
    ]);
}

export default deleteSaga;
