import pagination from '@iso/lib/helpers/pagination';

const {
    actions: baseActions,
    types,
    reducer,
} = pagination('alerts');

const actions = {
    requestFetchAlerts: baseActions.request,
    fetchAlertsSucceeded: baseActions.succeeded,
    fetchAlertsFailed: baseActions.failed,
};

export { types, actions };

export default reducer;