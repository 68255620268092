import pagination from '@iso/lib/helpers/pagination';

const {
    actions: baseActions,
    types,
    reducer,
} = pagination('productBrands');

const actions = {
    requestFetchProductBrands: baseActions.request,
    fetchProductBrandsSucceeded: baseActions.succeeded,
    fetchProductBrandsFailed: baseActions.failed,
};

export { types, actions };

export default reducer;