import { combineReducers } from 'redux';

import orderType, {
    types as typeTypes,
    actions as typeActions,
} from './type/reducer';

import orderStatusType, {
    types as statusTypeTypes,
    actions as statusTypeActions,
} from './statusType/reducer';

const actions = {
    ...typeActions,
    ...statusTypeActions,
};

const types = {
    ...typeTypes,
    ...statusTypeTypes,
};

export { types, actions };

const ordersReducer = combineReducers({
    orderType,
    orderStatusType,
});

export default ordersReducer;
