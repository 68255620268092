import {
    takeEvery,
    call,
    all,
    put,
    fork,
} from 'redux-saga/effects';

import { types, actions } from './reducer';
import ProductService from '@iso/services/ProductService';
import { extractError } from '@iso/lib/helpers/api';

function* submitProduct({ id, data }) {
    try {
        let response;
        if(id) {
            response = yield call(ProductService.editProduct, id, data);
        } else {
            response = yield call(ProductService.addProduct, data);
        }
        yield put(actions.submitProductSucceeded(response.data));
    } catch (error) {
        yield put(actions.submitProductFailed(extractError(error)));
    }
}

function* getBrandCategories({ id }) {
    try {
        const response = yield call(ProductService.getBrandCategories, id);
        yield put(actions.getBrandCategoriesSucceeded(response.data));
    } catch (error) {
        yield put(actions.getBrandCategoriesFailed(extractError(error)));
    }
}

function* getBarcode({ data }) {
    try {
        const response = yield call(ProductService.getBarcode, data);
        yield put(actions.getBarcodeSucceeded(response.data));
    } catch (error) {
        yield put(actions.getBarcodeFailed(extractError(error)));
    }
}

function* watchSubmitProduct() {
    yield takeEvery(
        types.REQUEST_SUBMIT_PRODUCT,
        submitProduct,
    );
}

function* watchGetBrandCategories() {
    yield takeEvery(
        types.REQUEST_GET_BRAND_CATEGORIES,
        getBrandCategories,
    );
}

function* watchGetBarcode() {
    yield takeEvery(
        types.REQUEST_GET_BARCODE,
        getBarcode,
    );
}

function* createSaga() {
    yield all([
        fork(watchSubmitProduct),
        fork(watchGetBrandCategories),
        fork(watchGetBarcode),
    ]);
}

export default createSaga;