import BaseService from '../BaseService';

class OfferService extends BaseService {
    getAll = (filters) => this.post('/warehouse/offers', filters || { });

    getOne = (id) => this.get(`/warehouse/offers/${id}`);

    deleteOne = (id) => this.delete(`/warehouse/offers/${id}`);

    addOffer = (data) => this.post('/warehouse/offer', data);

    editOffer = (id, data) => this.post(`/warehouse/offers/${id}`, data);

    getProductDetails = (data) => this.post('/warehouse/product/details', data);
};

export default new OfferService();