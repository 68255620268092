import { all, fork, takeEvery } from 'redux-saga/effects';
import { types } from './reducer';
import pagination from '@iso/lib/helpers/pagination';
import OfferService from '@iso/services/SettingsService/OfferService';

function* watchFetchOffers() {
    yield takeEvery(
        types.REQUEST_FETCH_OFFERS,
        pagination('offers').saga(OfferService),
    );
}

function* paginationSaga() {
    yield all([
        fork(watchFetchOffers),
    ]);
}

export default paginationSaga;