import { all, fork, takeEvery } from 'redux-saga/effects';
import { types } from './reducer';
import pagination from '@iso/lib/helpers/pagination';
import GroupService from '@iso/services/AccountService/GroupService';

function* watchFetchGroups() {
    yield takeEvery(
        types.REQUEST_FETCH_GROUPS,
        pagination('groups').saga(GroupService),
    );
}

function* paginationSaga() {
    yield all([
        fork(watchFetchGroups),
    ]);
}

export default paginationSaga;