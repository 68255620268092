import BaseService from '../../BaseService';

class UnitService extends BaseService {
    getAll = () => this.get('/warehouse/product/unit/list');

    getOne = (id) => this.get(`/warehouse/product/unit/${id}`);

    deleteOne = (id) => this.delete(`/warehouse/product/unit/${id}`);

    addUnit = (data) => this.post('/warehouse/product/unit', data);

    editUnit = (id, data) => this.put(`/warehouse/product/unit/${id}`, data);
}

export default new UnitService();