import { all, fork, takeEvery } from 'redux-saga/effects';
import { types } from './reducer';
import pagination from '@iso/lib/helpers/pagination';
import UnitService from '@iso/services/SettingsService/ProductService/UnitService';

function* watchFetchProductUnits() {
    yield takeEvery(
        types.REQUEST_FETCH_PRODUCT_UNITS,
        pagination('productUnits').saga(UnitService),
    );
}

function* paginationSaga() {
    yield all([
        fork(watchFetchProductUnits),
    ]);
}

export default paginationSaga;