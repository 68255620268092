import { all, fork, call, put, takeEvery } from 'redux-saga/effects';
import { types, actions } from './reducer';
import pagination from '@iso/lib/helpers/pagination';
import { extractError } from '@iso/lib/helpers/api';
import MarketingService from '@iso/services/PromotionService/MarketingService';

function* watchFetchMarketingList() {
    yield takeEvery(
        types.REQUEST_FETCH_MARKETING_LIST,
        pagination('marketingList').saga(MarketingService),
    );
}

function* submitMarketing({ data }) {
    try {
        const response = yield call(MarketingService.sendMarketing, data);
        yield put(actions.submitMarketingSucceeded(response.data));
    } catch (error) {
        yield put(actions.submitMarketingFailed(extractError(error)));
    }
}

function* watchSubmitMarketing() {
    yield takeEvery(
        types.REQUEST_SUBMIT_MARKETING,
        submitMarketing,
    );
}

function* marketingSaga() {
    yield all([
        fork(watchFetchMarketingList),
        fork(watchSubmitMarketing)
    ]);
}

export default marketingSaga;