import { createActions } from 'reduxsauce';

const { Types: types, Creators: actions } = createActions({
    // add role
    requestSubmitRole: ['id', 'data'],
    submitRoleSucceeded: ['data'],
    submitRoleFailed: ['errors'],

    // get permissions
    requestGetPermissions: null,
    getPermissionsSucceeded: ['data'],
    getPermissionsFailed: ['errors'],

    // clear state
    clearRoleCreateState: null,
});

export default {
    // add role
    [types.REQUEST_SUBMIT_ROLE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_ROLE_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            roleDetails: data,
            success: true,
        },
    }),
    [types.SUBMIT_ROLE_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            success: false,
            errors,
        },
    }),

    // get permissions
    [types.REQUEST_GET_PERMISSIONS]: (state) => ({
        ...state,
        create: {
            ...state.create,
            loading: true,
            errors: null,
        },
    }),
    [types.GET_PERMISSIONS_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            permissions: data,
        },
    }),
    [types.GET_PERMISSIONS_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            errors,
        },
    }),

    // clear state
    [types.CLEAR_ROLE_CREATE_STATE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            roleDetails: null,
            success: false,
            errors: null,
        },
    }),
};

export { types, actions };