import { createActions } from 'reduxsauce';

export const { Types: types, Creators: actions } = createActions({
    // view group
    requestViewGroup: ['id'],
    viewGroupSucceeded: ['data'],
    viewGroupFailed: ['error'],

    // clear state
    clearGroupViewState: null,
});

export default {
    // view group
    [types.REQUEST_VIEW_GROUP]: (state) => ({
        ...state,
        view: {
            ...state.view,
            loading: true,
            error: null,
        },
    }),
    [types.VIEW_GROUP_SUCCEEDED]: (state, { data }) => ({
        ...state,
        view: {
            ...state.view,
            groupDetails: data,
            loading: false,
        },
    }),
    [types.VIEW_GROUP_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            loading: false,
            error,
        },
    }),

    // clear state
    [types.CLEAR_GROUP_VIEW_STATE]: (state) => ({
        ...state,
        view: {
            ...state.view,
            groupDetails: null,
        },
    }),
}
