import pagination from '@iso/lib/helpers/pagination';
import create from './create/model';
import deleteModel from './delete/model';
import view from './view/model';

export default {
    ...pagination('offers').model,
    create,
    delete: deleteModel,
    view,
};