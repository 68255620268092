import BaseService from '../BaseService';

class OrderOutService extends BaseService {
    getAll = (filters) => this.post('/warehouse/orders/out', filters || { });

    getOne = (id) => this.get(`/warehouse/order/out/${id}`);

    deleteOne = (id) => this.delete(`/warehouse/order/out/${id}`);

    addOrder = (data) => this.post('/warehouse/order/out', data);

    getProductPriceDetails = (data) => this.post('/warehouse/product/priceDetails', data);

    readFromBarcode = (data) => this.post('/warehouse/product/searchBarcode', data);

    changeStatus = (data) => this.put('/warehouse/order/updateStatus', data);
}

export default new OrderOutService();