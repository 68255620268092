import { createActions } from 'reduxsauce';

export const { Types: types, Creators: actions } = createActions({
    // view order out
    requestViewOrderOut: ['id'],
    viewOrderOutSucceeded: ['data'],
    viewOrderOutFailed: ['error'],

    // change order out status
    requestChangeOrderOutStatus: ['data'],
    changeOrderOutStatusSuccedded: ['data'],
    changeOrderOutStatusFailed: ['error'],

    // clear state
    clearOrderOutViewState: null,
});

export default {
    // view order out
    [types.REQUEST_VIEW_ORDER_OUT]: (state) => ({
        ...state,
        view: {
            ...state.view,
            loading: true,
            error: null,
        },
    }),
    [types.VIEW_ORDER_OUT_SUCCEEDED]: (state, { data }) => ({
        ...state,
        view: {
            ...state.view,
            orderOut: data,
            loading: false,
        },
    }),
    [types.VIEW_ORDER_OUT_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            loading: false,
            error,
        },
    }),

    // change order in status
    [types.REQUEST_CHANGE_ORDER_OUT_STATUS]: (state) => ({
        ...state,
        view: {
            ...state.view,
            error: null,
        },
    }),
    [types.CHANGE_ORDER_OUT_STATUS_SUCCEDDED]: (state, { data }) => ({
        ...state,
        view: {
            ...state.view,
            status: data,
        },
    }),
    [types.CHANGE_ORDER_OUT_STATUS_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            error,
        },
    }),


    // clear state
    [types.CLEAR_ORDER_OUT_VIEW_STATE]: (state) => ({
        ...state,
        view: {
            ...state.view,
            orderOut: [],
        },
    }),
}
