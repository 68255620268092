import { createActions } from 'reduxsauce';

export const { Types: types, Creators: actions } = createActions({
    // view company
    requestViewCompany: [''],
    viewCompanySucceeded: ['data'],
    viewCompanyFailed: ['error'],
});

export default {
    // view company
    [types.REQUEST_VIEW_COMPANY]: (state) => ({
        ...state,
        view: {
            ...state.view,
            loading: true,
            error: null,
        },
    }),
    [types.VIEW_COMPANY_SUCCEEDED]: (state, { data }) => ({
        ...state,
        view: {
            ...state.view,
            details: data,
            loading: false,
        },
    }),
    [types.VIEW_COMPANY_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            loading: false,
            error,
        },
    }),
}
