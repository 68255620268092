import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { extractError } from '@iso/lib//helpers/api';
import { types, actions } from './reducer';
import SizeService from '@iso/services/SettingsService/ProductService/SizeService';

function* deleteSize({ id }) {
    try {
        yield call(SizeService.deleteOne, id);
        yield put(actions.deleteSizeSucceeded(id));
    } catch (error) {
        yield put(actions.deleteSizeFailed(extractError(error)));
    }
}

function* watchDeleteSize() {
    yield takeEvery(
        types.REQUEST_DELETE_SIZE,
        deleteSize,
    );
}

function* deleteSaga() {
    yield all([
        fork(watchDeleteSize),
    ]);
}

export default deleteSaga;
