import { all, fork, takeEvery } from 'redux-saga/effects';
import { types } from './reducer';
import pagination from '@iso/lib/helpers/pagination';
import UserService from '@iso/services/AccountService/UserService';

function* watchFetchUsers() {
    yield takeEvery(
        types.REQUEST_FETCH_USERS,
        pagination('users').saga(UserService),
    );
}

function* paginationSaga() {
    yield all([
        fork(watchFetchUsers),
    ]);
}

export default paginationSaga;