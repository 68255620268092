import BaseService from '../BaseService';

class AlertService extends BaseService {
    getAll = (filters) => this.post('/warehouse/product/listAlertsRule', filters || { });

    getOne = (id) => this.get(`/warehouse/product/viewAlertsRule/${id}`);

    deleteOne = (id) => this.delete(`/warehouse/product/deleteAlertsRule/${id}`);

    addAlert = (data) => this.post('/warehouse/products/addAlertsRule', data);

    editAlert = (id, data) => this.put(`/warehouse/product/updateAlertsRule/${id}`, data);

    getProductUnits = (data) => this.post('/warehouse/product/details', data);
};

export default new AlertService();