import { createReducer } from 'reduxsauce';
import initialState from './model';

import loginReduxHandlers, {
    types as loginTypes,
    actions as loginActions,
} from './login/reducer';

const types = {
    ...loginTypes,
};

const actions = {
    ...loginActions,
};

export default createReducer(initialState, {
    ...loginReduxHandlers,
});

export { types, actions };