import { createActions } from 'reduxsauce';

export const { Types: types, Creators: actions } = createActions({
    // view unit
    requestViewUnit: ['id'],
    viewUnitSucceeded: ['data'],
    viewUnitFailed: ['error'],

    // clear state
    clearUnitViewState: null,
});

export default {
    // view unit
    [types.REQUEST_VIEW_UNIT]: (state) => ({
        ...state,
        view: {
            ...state.view,
            loading: true,
            error: null,
        },
    }),
    [types.VIEW_UNIT_SUCCEEDED]: (state, { data }) => ({
        ...state,
        view: {
            ...state.view,
            unitDetails: data,
            loading: false,
        },
    }),
    [types.VIEW_UNIT_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            loading: false,
            error,
        },
    }),

    // clear state
    [types.CLEAR_UNIT_VIEW_STATE]: (state) => ({
        ...state,
        view: {
            ...state.view,
            unitDetails: null,
        },
    }),
}
