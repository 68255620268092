const actions = {
  SELECT_PRODUCT: "SELECT_PRODUCT",

  setSelectProduct: (props) => {
    return {
      type: actions.SELECT_PRODUCT,
      productCode : props.Prop1,
    };
  },
};

export default actions;
