import { all, fork, takeEvery } from 'redux-saga/effects';
import { types } from './reducer';
import pagination from '@iso/lib/helpers/pagination';
import SaleService from '@iso/services/ReportingService/SaleService';

function* watchFetchSales() {
    yield takeEvery(
        types.REQUEST_FETCH_SALES,
        pagination('sales').saga(SaleService),
    );
}

function* salesReportingSaga() {
    yield all([
        fork(watchFetchSales),
    ]);
}

export default salesReportingSaga;