import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { types, actions } from './reducer';
import { extractError } from '@iso/lib//helpers/api';
import OrderInService from '@iso/services/OrderService/OrderInService';

function* viewOrderIn({ id }) {
    try {
        const response = yield call(OrderInService.getOne, id);
        yield put(actions.viewOrderInSucceeded(response.data));
    } catch (error) {
        yield put(actions.viewOrderInFailed(extractError(error)));
    }
}

function* changeOrderInStatus({ data }) {
    try {
        const response = yield call(OrderInService.changeStatus, data);
        yield put(actions.changeOrderInStatusSucceeded(response.data));
    } catch (error) {
        yield put(actions.changeOrderInStatusFailed(extractError(error)));
    }
}

function* watchViewOrderIn() {
    yield takeEvery(
        types.REQUEST_VIEW_ORDER_IN,
        viewOrderIn,
    );
}

function* watchChangeOrderInStatus() {
    yield takeEvery(
        types.REQUEST_CHANGE_ORDER_IN_STATUS,
        changeOrderInStatus,
    );
}

function* viewSaga() {
    yield all([
        fork(watchViewOrderIn),
        fork(watchChangeOrderInStatus),
    ]);
}

export default viewSaga;
