import pagination from '@iso/lib/helpers/pagination';

const {
    actions: baseActions,
    types,
    reducer,
} = pagination('productCategories');

const actions = {
    requestFetchProductCategories: baseActions.request,
    fetchProductCategoriesSucceeded: baseActions.succeeded,
    fetchProductCategoriesFailed: baseActions.failed,
};

export { types, actions };

export default reducer;