import { all, fork, takeEvery } from 'redux-saga/effects';
import { types } from './reducer';
import pagination from '@iso/lib/helpers/pagination';
import GenderService from '@iso/services/SettingsService/ProductService/GenderService';

function* watchFetchProductGenders() {
    yield takeEvery(
        types.REQUEST_FETCH_PRODUCT_GENDERS,
        pagination('productGenders').saga(GenderService),
    );
}

function* paginationSaga() {
    yield all([
        fork(watchFetchProductGenders),
    ]);
}

export default paginationSaga;