import { createActions } from 'reduxsauce';

const { Types: types, Creators: actions } = createActions({
    // add brand
    requestSubmitBrand: ['id', 'data'],
    submitBrandSucceeded: ['data'],
    submitBrandFailed: ['errors'],

    // clear state
    clearBrandCreateState: null,
});

export default {
    // add brand
    [types.REQUEST_SUBMIT_BRAND]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_BRAND_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            brandDetails: data,
            success: true,
        },
    }),
    [types.SUBMIT_BRAND_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            success: false,
            errors,
        },
    }),

    // clear state
    [types.CLEAR_BRAND_CREATE_STATE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            brandDetails: null,
            success: false,
            errors: null,
        },
    }),
};

export { types, actions };