import { createActions } from 'reduxsauce';

export const { Types: types, Creators: actions } = createActions({
    // view season
    requestViewSeason: ['id'],
    viewSeasonSucceeded: ['data'],
    viewSeasonFailed: ['error'],

    // clear state
    clearSeasonViewState: null,
});

export default {
    // view season
    [types.REQUEST_VIEW_SEASON]: (state) => ({
        ...state,
        view: {
            ...state.view,
            loading: true,
            error: null,
        },
    }),
    [types.VIEW_SEASON_SUCCEEDED]: (state, { data }) => ({
        ...state,
        view: {
            ...state.view,
            seasonDetails: data,
            loading: false,
        },
    }),
    [types.VIEW_SEASON_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            loading: false,
            error,
        },
    }),

    // clear state
    [types.CLEAR_SEASON_VIEW_STATE]: (state) => ({
        ...state,
        view: {
            ...state.view,
            seasonDetails: null,
        },
    }),
}
