import { createActions } from 'reduxsauce';

const { Types: types, Creators: actions } = createActions({
    // login
    requestLogin: ['data'],
    loginSucceeded: null,
    loginFailed: ['error'],

    //check auth
    checkAuthorization:null,
    checkAuthorizationSucceeded: ['isAuth'],
    checkAuthorizationFailed: ['isAuth'],

    // logout
    requestLogout: null,
    logoutSucceeded: null,
    logoutFailed: ['error'],
});

export default {
    // login
    [types.REQUEST_LOGIN]: (state) => ({
        ...state,
        loggingIn: true,
        loggingInError: null,
    }),
    [types.LOGIN_SUCCEEDED]: (state) => ({
        ...state,
        authenticated: true,
        loggingIn: false,
    }),
    [types.LOGIN_FAILED]: (state, { error }) => ({
        ...state,
        loggingIn: false,
        loggingInError: error,
    }),

    // check auth
    [types.CHECK_AUTHORIZATION]: (state) => ({
        ...state,
        authenticated: false,
    }),
    [types.CHECK_AUTHORIZATION_SUCCEEDED]: (state, { isAuth }) => ({
        ...state,
        authenticated: isAuth,
    }),
    [types.CHECK_AUTHORIZATION_FAILED]: (state, { isAuth }) => ({
        ...state,
        authenticated: isAuth,
    }),

    // logout
    [types.REQUEST_LOGOUT]: (state) => ({
        ...state,
        loggingOut: true,
        loggingOutError: null,
    }),
    [types.LOGOUT_SUCCEEDED]: (state) => ({
        ...state,
        authenticated: false,
        loggingOut: false,
    }),
    [types.LOGOUT_FAILED]: (state, { error }) => ({
        ...state,
        loggingOut: false,
        loggingOutError: error,
    }),

};

export { types, actions };
