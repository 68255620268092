import BaseService from '../../BaseService';

class WarehouseService extends BaseService {
    getAll = (filters) => this.post('/warehouses', filters || { });

    getOne = (id) => this.get(`/warehouse/${id}`);

    deleteOne = (id) => this.delete(`/warehouse/${id}`);

    addWarehouse = (data) => this.post('/warehouse', data);

    editWarehouse = (id, data) => this.put(`/warehouse/${id}`, data);
}

export default new WarehouseService();