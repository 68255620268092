import pagination from '@iso/lib/helpers/pagination';
import create from './create/model';
import view from './view/model';
import deleteModel from './delete/model';

export default {
    ...pagination('orderTypes').model,
    create,
    view,
    delete: deleteModel,
};