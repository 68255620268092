import pagination from '@iso/lib/helpers/pagination';

const {
    actions: baseActions,
    types,
    reducer,
} = pagination('partners');

const actions = {
    requestFetchPartners: baseActions.request,
    fetchPartnersSucceeded: baseActions.succeeded,
    fetchPartnersFailed: baseActions.failed,
};

export { types, actions };

export default reducer;