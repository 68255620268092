import { call, put } from 'redux-saga/effects';
import { createActions } from 'reduxsauce';
import { camelToSnake, firstToUpper } from './string';
import { createEntityActionTypes } from './redux';

const Pagination = (entity) => {
    const actionCreatorNames = createEntityActionTypes(
        firstToUpper(entity),
        'fetch',
    );

    const { Types: types, Creators } = createActions({
        [actionCreatorNames.request]: ['filters'],
        [actionCreatorNames.succeeded]: [
            'data',
            'totalItems',
            'totalMinutes',
            'totalHours',
        ],
        [actionCreatorNames.failed]: ['error'],
    });

    const mappedTypes = {
        request: camelToSnake(actionCreatorNames.request),
        succeeded: camelToSnake(actionCreatorNames.succeeded),
        failed: camelToSnake(actionCreatorNames.failed),
    };

    const actions = {
        request: Creators[actionCreatorNames.request],
        succeeded: Creators[actionCreatorNames.succeeded],
        failed: Creators[actionCreatorNames.failed],
    };

    const model = {
        data: [],
        totalItems: 0,
        totalMinutes: 0,
        totalHours: 0,
        fetchingLoading: true,
        fetchingError: null,
    };

    const reducer = {
        [mappedTypes.request]: (state) => ({
            ...state,
            fetchingLoading: true,
            fetchingError: null,
        }),
        [mappedTypes.succeeded]: (state, action) => ({
            ...state,
            data: action.data,
            totalItems: action.totalItems,
            totalMinutes: action.totalMinutes,
            totalHours: action.totalHours,
            fetchingLoading: false,
            fetchingError: null,
        }),
        [mappedTypes.failed]: (state, action) => ({
            ...state,
            fetchingLoading: false,
            fetchingError: action.error,
        }),
    };

    const saga = (Service) => function* paginationGenerator({ filters }) {
        try {
            const response = yield call(Service.getAll, filters);
            const totalItems = response.meta?.total;
            const totalMinutes = response.meta?.total_min;
            const totalHours = response.meta?.total_hours;

            yield put(actions.succeeded(
                response.data, totalItems, totalMinutes, totalHours));
        } catch (error) {
            yield put(actions.failed(error.message));
        }
    };

    return {
        types, mappedTypes, model, actions, reducer, saga,
    };
};

export default Pagination;
