import { createActions, createReducer } from 'reduxsauce';
import initialState from './model';

const { Types: types, Creators: actions } = createActions({
 
    // get permissions
    requestGetUserPermissions: [null],
    getUserPermissionsSucceeded: ['data', 'user'],
    getUserPermissionsFailed: ['error'],
 

});

export default createReducer(initialState, {
    [types.REQUEST_GET_USER_PERMISSIONS]: (state) => ({
        ...state,
        loading: true,
        error: null,
    }),
    [types.GET_USER_PERMISSIONS_SUCCEEDED]: (state, { data, user }) => ({
        ...state,
        permissionList: data,
        user: user,
        loading: false,
    }),
    [types.GET_USER_PERMISSIONS_FAILED]: (state, { error }) => ({
        ...state,
        loading: false,
        error,
    }),
});

export { types, actions };