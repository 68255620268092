import { all } from 'redux-saga/effects';
import usersSaga from './users/saga';
import rolesSaga from './roles/saga';
import groupsSaga from './groups/saga';

function* accountsSaga() {
    yield all([
        usersSaga(),
        rolesSaga(),
        groupsSaga(),
    ]);
}

export default accountsSaga;