import { all, fork, takeEvery } from 'redux-saga/effects';
import { types } from './reducer';
import pagination from '@iso/lib/helpers/pagination';
import CompositeProductService from '@iso/services/SettingsService/CompositeProductService';

function* watchFetchCompositeProducts() {
    yield takeEvery(
        types.REQUEST_FETCH_COMPOSITE_PRODUCTS,
        pagination('compositeProducts').saga(CompositeProductService),
    );
}

function* paginationSaga() {
    yield all([
        fork(watchFetchCompositeProducts),
    ]);
}

export default paginationSaga;