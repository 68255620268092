import axios from 'axios';
import StorageManager from '@iso/lib/helpers/StorageManager';

class BaseService {
    constructor() {
        this.token = StorageManager.get('token');

        this.api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            headers: { 'Content-type': 'application/json' },
        });

        this.authInterceptor = this.api.interceptors.request.use((config) => {
            const token = StorageManager.get('token');
            return {
                ...config,
                headers: {
                    ...config.headers,
                    Authorization: `Bearer ${token}`,
                },
            };
        });

        this.responseInterceptors = this.api.interceptors.response.use(
            (res) => {
                return res.data;
            },
            // (error) => alert(error.message)
            // async (err) => {
            //     const originalConfig = err.config;
            //     if (originalConfig.url !== "/warehouse/login" && err.response) {
            //         // Access Token was expired
            //         if (err.response.status === 400 && !originalConfig._retry) {
            //             originalConfig._retry = true;
            //             try {
            //                 const rs = await this.api.post("/refresh", {
            //                     refresh_token: StorageManager.get('refreshToken'),
            //                 });
            //                 const {
            //                     access_token: accessToken,
            //                     refresh_token: refreshToken,
            //                 } = rs.data;
            //                 StorageManager.set('token', accessToken);
            //                 StorageManager.set('refreshToken', refreshToken);
            //                 return this.api(originalConfig);
            //             } catch (_error) {
            //             return Promise.reject(_error);
            //             }
            //         } else {
            //              StorageManager.clearAll();
            //         }
            //     }
            //     return Promise.reject(err);
            // }
        );
    }

    get = (url, options) => this.api.get(url, options);

    post = (url, data, options) => this.api.post(url, data, options);

    put = (url, data, options) => this.api.put(url, data, options);

    patch = (url, data, options) => this.api.patch(url, data, options);

    delete = (url, options) => this.api.delete(url, options);
}

export default BaseService;
