import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { extractError } from '@iso/lib/helpers/api';
import ProductService from '@iso/services/ProductService';
import { types, actions } from './reducer';

function* deleteProduct({ id }) {
    try {
        yield call(ProductService.deleteOne, id);
        yield put(actions.deleteProductSucceeded(id));
    } catch (error) {
        yield put(actions.deleteProductFailed(extractError(error)));
    }
}

function* watchDeleteProduct() {
    yield takeEvery(
        types.REQUEST_DELETE_PRODUCT,
        deleteProduct,
    );
}

function* deleteSaga() {
    yield all([
        fork(watchDeleteProduct),
    ]);
}

export default deleteSaga;
