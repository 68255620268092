import pagination from '@iso/lib/helpers/pagination';
import { createActions, createReducer } from 'reduxsauce';
import initialState from './model';

const {
    actions: baseActions,
    types: paginationTypes,
    reducer: paginationReducer,
} = pagination('marketingList');

const paginationActions = {
    requestFetchMarketingList: baseActions.request,
    fetchMarketingListSucceeded: baseActions.succeeded,
    fetchMarketingListFailed: baseActions.failed,
};

const { Types, Creators } = createActions({
    // send marketing
    requestSubmitMarketing: ['data'],
    submitMarketingSucceeded: ['messageSuccess'],
    submitMarketingFailed: ['errors'],
});

export const types = { ...Types, ...paginationTypes };

export const actions = { ...Creators, ...paginationActions};

export default createReducer(initialState, {
    // get marketing list
    ...paginationReducer,

    // send marketing
    [types.REQUEST_SUBMIT_MARKETING]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_MARKETING_SUCCEEDED]: (state, { successMessage }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            success: successMessage,
        },
    }),
    [types.SUBMIT_MARKETING_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            errors,
        },
    }),
});