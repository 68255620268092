import { createActions, createReducer } from 'reduxsauce';
import initialState from './model';

const { Types: types, Creators: actions } = createActions({
 
    // get menu
    requestGetMenu: [null],
    getMenuSucceeded: ['data'],
    getMenuFailed: ['error'],
 

});

export default createReducer(initialState, {
    [types.REQUEST_GET_MENU]: (state) => ({
        ...state,
        loading: true,
        error: null,
    }),
    [types.GET_MENU_SUCCEEDED]: (state, { data }) => ({
        ...state,
       arrayMenu: data,
        loading: false,
    }),
    [types.GET_MENU_FAILED]: (state, { error }) => ({
        ...state,
        loading: false,
        error,
    }),
});

export { types, actions };