import {
    takeEvery,
    call,
    all,
    put,
    fork,
} from 'redux-saga/effects';

import { types, actions } from './reducer';
import AllowedWarehousesService from '@iso/services/AllowedWarehousesService';
import { extractError } from '@iso/lib/helpers/api';

function* getAllowedWarehouses({ filters }) {
    try {
        const response = yield call(AllowedWarehousesService.getAllowedWarehouses, filters);
        yield put(actions.getAllowedWarehousesSucceeded(response.data));
    } catch (error) {
        yield put(actions.getAllowedWarehousesFailed(extractError(error)));
    }
}

function* watchGetAllowedWarehouses() {
    yield takeEvery(
        types.REQUEST_GET_ALLOWED_WAREHOUSES,
        getAllowedWarehouses,
    );
}

function* allowedWarehousesSaga() {
    yield all([
        fork(watchGetAllowedWarehouses),
    ]);
}

export default allowedWarehousesSaga;