import {
    takeEvery,
    call,
    all,
    put,
    fork,
} from 'redux-saga/effects';

import { types, actions } from './reducer';
import OfferService from '@iso/services/SettingsService/OfferService';
import { extractError } from '@iso/lib/helpers/api';

function* submitOffer({ id, data }) {
    try {
        let response;
        if(id) {
            response = yield call(OfferService.editOffer, id, data);
        } else {
            response = yield call(OfferService.addOffer, data);
        }
        yield put(actions.submitOfferSucceeded(response.data));
    } catch (error) {
        yield put(actions.submitOfferFailed(extractError(error)));
    }
}

function* getProductDetails({ data }) {
    try {
        const response = yield call(OfferService.getProductDetails, data);
        yield put(actions.getProductDetailsSucceeded(response.data));
    } catch (error) {
        yield put(actions.getProductDetailsFailed(extractError(error)));
    }
}

function* watchSubmitOffer() {
    yield takeEvery(
        types.REQUEST_SUBMIT_OFFER,
        submitOffer,
    );
}

function* watchGetProductDetails() {
    yield takeEvery(
        types.REQUEST_GET_PRODUCT_DETAILS,
        getProductDetails,
    );
}

function* createSaga() {
    yield all([
        fork(watchSubmitOffer),
        fork(watchGetProductDetails),
    ]);
}

export default createSaga;