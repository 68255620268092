import { createActions } from 'reduxsauce';

const { Types: types, Creators: actions } = createActions({
    // add product
    requestSubmitProduct: ['id', 'data'],
    submitProductSucceeded: ['data'],
    submitProductFailed: ['errors'],

    // get brand categories
    requestGetBrandCategories: ['id'],
    getBrandCategoriesSucceeded: ['data'],
    getBrandCategoriesFailed: ['errors'],

    // get product barcode
    requestGetBarcode: ['data'],
    getBarcodeSucceeded: ['data'],
    getBarcodeFailed: ['errors'],

    // clear state
    clearProductCreateState: null,

});

export default {
    // add product
    [types.REQUEST_SUBMIT_PRODUCT]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_PRODUCT_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            product: data,
            success: true,
        },
    }),
    [types.SUBMIT_PRODUCT_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            success: false,
            errors,
        },
    }),

    // get brand categories
    [types.REQUEST_GET_BRAND_CATEGORIES]: (state) => ({
        ...state,
        create: {
            ...state.create,
            loading: true,
            errors: null,
        }
    }),
    [types.GET_BRAND_CATEGORIES_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            brandCategories: data,
            loading: false,
        }
    }),
    [types.GET_BRAND_CATEGORIES_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            errors,
        }
    }),

    // get product barcode
    [types.REQUEST_GET_BARCODE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            loading: true,
            errors: null,
        }
    }),
    [types.GET_BARCODE_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            barcode: data,
            loading: false,
        }
    }),
    [types.GET_BARCODE_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            errors,
        }
    }),

    // clear state
    [types.CLEAR_PRODUCT_CREATE_STATE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            product: null,
            success: false,
            errors: null,
        },
    }),
};

export { types, actions };