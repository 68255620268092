import pagination from '@iso/lib/helpers/pagination';

const {
    actions: baseActions,
    types,
    reducer,
} = pagination('roles');

const actions = {
    requestFetchRoles: baseActions.request,
    fetchRolesSucceeded: baseActions.succeeded,
    fetchRolesFailed: baseActions.failed,
};

export { types, actions };

export default reducer;