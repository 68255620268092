import { all } from 'redux-saga/effects';
import salesSaga from './sales/saga';
import promotionSaga from './promotion/saga';
import stockSaga from './stock/saga';

function* reportingSaga() {
    yield all([
        salesSaga(),
        promotionSaga(),
        stockSaga(),
    ]);
}

export default reportingSaga;