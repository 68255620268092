import { all, fork, takeEvery } from 'redux-saga/effects';
import { types } from './reducer';
import pagination from '@iso/lib/helpers/pagination';
import CurrencyService from '@iso/services/SettingsService/GeneralService/CurrencyService';

function* watchFetchCurrencies() {
    yield takeEvery(
        types.REQUEST_FETCH_CURRENCIES,
        pagination('currencies').saga(CurrencyService),
    );
}

function* paginationSaga() {
    yield all([
        fork(watchFetchCurrencies),
    ]);
}

export default paginationSaga;