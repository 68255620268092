import { all, fork } from 'redux-saga/effects';
import paginationSaga from './pagination/saga';
import createSaga from './create/saga';
import deleteSaga from './delete/saga';
import viewSaga from './view/saga';

function* alertSaga() {
    yield all([
        fork(paginationSaga),
        fork(createSaga),
        fork(deleteSaga),
        fork(viewSaga),
    ]);
}

export default alertSaga;