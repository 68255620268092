import {
    fork,
    all,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { types, actions } from './reducer';
import { extractError } from '@iso/lib/helpers/api';
import RouteService from '@iso/services/RouteService';

function* getRoutes() {
    try {
        const response = yield call(RouteService.getRoutes);
        yield put(actions.getRoutesSucceeded(response.data));
    } catch (error) {
        yield put(actions.getRoutesFailed(extractError(error)));
    }
}
 
function* watchGetRoutes() {
    yield takeEvery(
        types.REQUEST_GET_ROUTES,
        getRoutes,
    );
}

function* routeSaga() {
    yield all([
        fork(watchGetRoutes),
    ]);
}

export default routeSaga;