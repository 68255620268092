import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { types, actions } from './reducer';
import { extractError } from '@iso/lib//helpers/api';
import TypeService from '@iso/services/SettingsService/OrderService/TypeService';

function* viewOrderType({ id }) {
    try {
        const response = yield call(TypeService.getOne, id);
        yield put(actions.viewOrderTypeSucceeded(response.data));
    } catch (error) {
        yield put(actions.viewOrderTypeFailed(extractError(error)));
    }
}

function* watchViewOrderType() {
    yield takeEvery(
        types.REQUEST_VIEW_ORDER_TYPE,
        viewOrderType,
    );
}

function* viewSaga() {
    yield all([
        fork(watchViewOrderType),
    ]);
}

export default viewSaga;
