import { createActions } from 'reduxsauce';
import { removeSelectedItem } from '@iso/lib//helpers/CRUD';

export const { Types: types, Creators: actions } = createActions({
    requestDeleteSize: ['id'],
    deleteSizeSucceeded: ['id'],
    deleteSizeFailed: ['error'],
});

export default {
    [types.REQUEST_DELETE_SIZE]: (state, { id }) => ({
        ...state,
        delete: {
            ...state.delete,
            id,
            loading: true,
            error: null,
        },
    }),
    [types.DELETE_SIZE_SUCCEEDED]: (state, { id }) => ({
        ...state,
        data: removeSelectedItem(state.data, id),
        delete: {
            ...state.delete,
            id: null,
            loading: false,
        },
    }),
    [types.DELETE_SIZE_FAILED]: (state, { error }) => ({
        ...state,
        delete: {
            ...state.delete,
            id: null,
            loading: false,
            error,
        },
    }),
};
