import BaseService from './BaseService';

class DownloadService extends BaseService {

    getUrlToDownloadStock = (data) => this.post('/warehouse/product/downloadStock', data);

    getUrlToDownloadSales = (data) => this.post('/warehouse/product/downloadSells', data);

    downloadExcelFile = (api) => this.get(api);
}

export default new DownloadService();