import {
    takeEvery,
    call,
    all,
    put,
    fork,
} from 'redux-saga/effects';

import { types, actions } from './reducer';
import UnitService from '@iso/services/SettingsService/ProductService/UnitService';
import { extractError } from '@iso/lib/helpers/api';

function* submitUnit({ id, data }) {
    try {
        let response;
        if(id) {
            response = yield call(UnitService.editUnit, id, data);
        } else {
            response = yield call(UnitService.addUnit, data);
        }
        yield put(actions.submitUnitSucceeded(response.data));
    } catch (error) {
        yield put(actions.submitUnitFailed(extractError(error)));
    }
}

function* watchSubmitUnit() {
    yield takeEvery(
        types.REQUEST_SUBMIT_UNIT,
        submitUnit,
    );
}

function* createSaga() {
    yield all([
        fork(watchSubmitUnit),
    ]);
}

export default createSaga;