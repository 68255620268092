import { createActions, createReducer } from 'reduxsauce';
import initialState from './model';

const { Types: types, Creators: actions } = createActions({
    // get allowed warehouses
    requestGetAllowedWarehouses: ['filters'],
    getAllowedWarehousesSucceeded: ['warehouses'],
    getAllowedWarehousesFailed: ['errors'],
});

export default createReducer(initialState, {
    // get allowed warehouses
    [types.REQUEST_GET_ALLOWED_WAREHOUSES]: (state) => ({
        ...state,
        loading: true,
        errors: null,
    }),
    [types.GET_ALLOWED_WAREHOUSES_SUCCEEDED]: (state, { warehouses }) => ({
        ...state,
        loading: false,
        data: warehouses,
    }),
    [types.GET_ALLOWED_WAREHOUSES_FAILED]: (state, { errors }) => ({
        ...state,
        loading: false,
        errors,
    }),
});

export { types, actions };
