import { all } from 'redux-saga/effects';
import productsSaga from './products/saga';
import compositeProductsSaga from './compositeProducts/saga';
import ordersSaga from './orders/saga';
import warehousesSaga from './warehouses/saga';
import alertSaga from './alert/saga';
import generalSaga from './general/saga';
import partnerSaga from './partner/saga';
import offerSaga from './offer/saga';

function* settingsSaga() {
    yield all([
        productsSaga(),
        compositeProductsSaga(),
        ordersSaga(),
        warehousesSaga(),
        alertSaga(),
        generalSaga(),
        partnerSaga(),
        offerSaga(),
    ]);
}

export default settingsSaga;