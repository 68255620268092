import { all, fork } from 'redux-saga/effects';
import paginationSaga from './pagination/saga';
import createSaga from './create/saga';
import viewSaga from './view/saga';
import deleteSaga from './delete/saga';


function* typeSaga() {
    yield all([
        fork(paginationSaga),
        fork(createSaga),
        fork(viewSaga),
        fork(deleteSaga),
    ]);
}

export default typeSaga;