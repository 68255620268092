import { all } from 'redux-saga/effects';
import currencySaga from './currency/saga';
import paymentMethodSaga from './paymentMethod/saga';
import company from './company/saga';

function* generalSaga() {
    yield all([
        currencySaga(),
        paymentMethodSaga(),
        company(),
    ]);
}

export default generalSaga;