import { all } from 'redux-saga/effects';
import brandSaga from './brand/saga';
import categorySaga from './category/saga';
import genderSaga from './gender/saga';
import seasonSaga from './season/saga';
import sizeSaga from './size/saga';
import typeSaga from './type/saga';
import baseUnitSaga from './baseUnit/saga';
import unitSaga from './unit/saga';

function* productsSaga() {
    yield all([
        brandSaga(),
        categorySaga(),
        genderSaga(),
        seasonSaga(),
        sizeSaga(),
        typeSaga(),
        baseUnitSaga(),
        unitSaga(),
    ]);
}

export default productsSaga;