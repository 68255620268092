import { createActions } from 'reduxsauce';

const { Types: types, Creators: actions } = createActions({
    // add user
    requestSubmitUser: ['id', 'data'],
    submitUserSucceeded: ['data'],
    submitUserFailed: ['errors'],

    // clear state
    clearUserCreateState: null,
});

export default {
    // add user
    [types.REQUEST_SUBMIT_USER]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_USER_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            userDetails: data,
            success: true,
        },
    }),
    [types.SUBMIT_USER_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            success: false,
            errors,
        },
    }),

    // clear state
    [types.CLEAR_USER_CREATE_STATE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            userDetails: null,
            success: false,
            errors: null,
        },
    }),
};

export { types, actions };