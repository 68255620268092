import { all, fork, takeEvery } from 'redux-saga/effects';
import { types } from './reducer';
import pagination from '@iso/lib/helpers/pagination';
import SeasonService from '@iso/services/SettingsService/ProductService/SeasonService';

function* watchFetchProductSeasons() {
    yield takeEvery(
        types.REQUEST_FETCH_PRODUCT_SEASONS,
        pagination('productSeasons').saga(SeasonService),
    );
}

function* paginationSaga() {
    yield all([
        fork(watchFetchProductSeasons),
    ]);
}

export default paginationSaga;