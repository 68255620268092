import BaseService from '../../BaseService';

class GenderService extends BaseService {
    getAll = (filters) => this.post('/warehouse/product/genders', filters || { });

    getOne = (id) => this.get(`/warehouse/product/gender/${id}`);

    deleteOne = (id) => this.delete(`/warehouse/product/gender/${id}`);

    addGender = (data) => this.post('/warehouse/product/gender', data);

    editGender = (id, data) => this.put(`/warehouse/product/gender/${id}`, data);
}

export default new GenderService();