import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { types, actions } from './reducer';
import { extractError } from '@iso/lib//helpers/api';
import UnitService from '@iso/services/SettingsService/ProductService/UnitService';

function* viewUnit({ id }) {
    try {
        const response = yield call(UnitService.getOne, id);
        yield put(actions.viewUnitSucceeded(response.data));
    } catch (error) {
        yield put(actions.viewUnitFailed(extractError(error)));
    }
}

function* watchViewUnit() {
    yield takeEvery(
        types.REQUEST_VIEW_UNIT,
        viewUnit,
    );
}

function* viewSaga() {
    yield all([
        fork(watchViewUnit),
    ]);
}

export default viewSaga;
