import BaseService from '../../BaseService';

class CurrencyService extends BaseService {
    getAll = () => this.get('/warehouse/currencies');

    getOne = (id) => this.get(`/warehouse/currency/${id}`);

    deleteOne = (id) => this.delete(`/warehouse/currency/${id}`);

    addCurrency = (data) => this.post('/warehouse/currency', data);

    editCurrency = (id, data) => this.put(`/warehouse/currency/${id}`, data);
}

export default new CurrencyService();