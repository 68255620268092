import BaseService from '../../BaseService';

class CategoryService extends BaseService {
    getAll = (filters) => this.post('/warehouse/product/categories', filters || { });

    getOne = (id) => this.get(`/warehouse/product/category/${id}`);

    deleteOne = (id) => this.delete(`/warehouse/product/category/${id}`);

    addCategory = (data) => this.post('/warehouse/product/category', data);

    editCategory = (id, data) => this.put(`/warehouse/product/category/${id}`, data);
}

export default new CategoryService();