export default {
    en: {
        translation: {
            "English": "English",
            "Italian": "Italian",
            "topbar.profile": "My profile",
            "topbar.help": "Help",
            "topbar.log-out": "Log out",
            "sidebar.dashboard": "Dashboard",
            "sidebar.products": "Products",
            "sidebar.orders": "Orders",
            "sidebar.order/in": "Order In",
            "sidebar.order/out": "Order Out",
            "sidebar.promotions": "Promotions",
            "sidebar.promotions.marketing": "Marketing",
            "sidebar.promotions.promotion": "Promotion",
            "sidebar.reporting": "Reporting",
            "sidebar.advanced/reporting": "Sales",
            "sidebar.promotion/reporting": "Promotion",
            "sidebar.stock/reporting": "Stock",
            "sidebar.accounts": "Accounts",
            "sidebar.accounts.users": "Users",
            "sidebar.accounts.roles": "Roles",
            "sidebar.accounts.groups": "Groups",
            "sidebar.settings": "Settings",
            "sidebar.settings.product": "Product",
            "sidebar.settings.composed-product": "Composite Product",
            "sidebar.settings.orders": "Order",
            "sidebar.settings.warehouses": "Warehouse",
            "sidebar.settings.alerts": "Alert",
            "sidebar.settings.general": "General",
            "sidebar.settings.partner": "Partner",
            "sidebar.settings.offers": "Offers",
            "dashboard.products": "Products",
            "dashboard.warehouses": "Warehouses",
            "order-in": "Order In",
            "order-out": "Order Out",
            "performance": "Performance",
            "overview": "Overview",
            "dashboard.orders": "Total orders",
            "dashboard.earning": "Earning value",
            "name": "Name",
            "product": "Product",
            "brand": "Brand",
            "category": "Category",
            "type": "Type",
            "size": "Size",
            "season": "Season",
            "gender": "Gender",
            "unit": "Unit",
            "base unit": "Base unit",
            "code": "Code",
            "created-by": "Created by",
            "price": "Price",
            "buy-price": "Buy price",
            "sell-price": "Sell price",
            "quantity": "Quantity",
            "currency": "Currency",
            "barcode": "Barcode",
            "description": "Description",
            "partner": "Partner",
            "status": "Status",
            "parent": "Parent",
            "company": "Company",
            "order-type": "Order type",
            "invoice-code": "Invoice code",
            "invoice-number": "Invoice number",
            "payment-method": "Payment method",
            "total": "Total",
            "start-date": "Start date",
            "end-date": "End date",
            "warehouse": "Warehouse",
            "warehouses": "Warehouses",
            "warehouse-type": "Warehouse type",
            "warehouse-in": "Warehouse in",
            "warehouse-out": "Warehouse out",
            "search": "Search",
            "cancel": "Cancel",
            "save": "Save",
            "yes": "Yes",
            "no": "No",
            "new": "New",
            "new-f": "New",
            "send": "Send",
            "start a promotion": "Start a promotion",
            "stop": "Stop",
            "add-new": "Add new",
            "create": "Create",
            "edit": "Edit",
            "add-unit": "Add unit",
            "add-product": "Add product",
            "are you sure to delete this item": "Are you sure to delete this item",
            "first-name": "First Name",
            "last-name" : "Last Name",
            "email": "Email",
            "phone-number": "Phone number",
            "birthdate": "Birthday",
            "type-message": "Type message",
            "created-order": "Created order",
            "order-amount": "Order amount",
            "created-partner": "Created partner",
            "age": "Age",
            "user": "User",
            "duration": "Duration",
            "total-minutes": "Total minutes",
            "total-hours": "Total hours",
            "from": "From",
            "to": "To",
            "role": "Role",
            "group": "Group",
            "roles": "Roles",
            "groups": "Groups",
            "username": "Username",
            "password": "Password",
            "stock list empty": "Stock list empty",
            "add new order details": "Add new order details",
            "user list empty": "User list empty",
            "role list empty": "Role list empty",
            "group list empty": "Group list empty",
            "permissions": "Permissions",
            "warehouse-permissions": "Warehouse permissions",
            "nothing in marketing": "Nothing in Marketing",
            "you don't have any promotion saved": "You don't have any promotion saved",
            "sign in to ypur account": "Sign in to your account",
            "forgot password": "Forgot password",
            "sign in": "Sign In",
            "account recovery": "Account recovery",
            "send request": "Send request",
            "enter your email and we send you a reset link": "Enter your email and we send you a reset link",
            "partner list empty": "Partner list empty",
            "edit company details": "Edit company details",
            "address": "Address",
            "state": "State",
            "country": "Country",
            "city": "City",
            "zip code": "Zip code",
            "timezone": "Timezone",
            "fax": "Fax",
            "symbol": "Symbol",
            "contact person": "Contact Person",
            "contact": "Contact",
            "location": "Location",
            "status type": "Status type",
            "order type": "Order type",
            "order status type": "Order status type",
            "payment method list empty": "Payment method list empty",
            "currency list empty": "Currency list empty",
            "warehouse list empty": "Warehouse list empty",
            "warehouse type list empty": "Warehouse type list empty",
            "order type list empty": "Order type list empty",
            "order status type list empty": "Order status type list empty",
            "brand list empty": "Brand list empty",
            "category list empty": "Category list empty",
            "gender list empty": "Gender list empty",
            "season list empty": "Season list empty",
            "size list empty": "Size list empty",
            "type list empty": "Type list empty",
            "base unit list empty": "Base unit list empty",
            "unit list empty": "Unit list empty",
            "footer-text": "by InnoSource Technology team",
            "are you sure to send a SMS for all searched results": "Are you sure to send a SMS for all searched results",
            "are you sure to send an email for all searched results": "Are you sure to send an email for all searched results",
            "promotion list empty": "Promotion list empty",
            "sales list empty": "Sales list empty",
            "group-by": "Group by",
            "total-sales": "Total sales",
            "earn": "Earnings",
            "identifier": "Identifier",
            "stock": "Stock",
            "transfered": "Transfered",
            "sold": "Sold",
            "nothing in alerts": "Nothing in alerts",
            "alert": "Alert",
            "view alert list": "View alert list",
            "limit-level": "Limit level",
            "order details": "Order Details",
            "composite": "Composite",
            "simple": "Simple",
            "actions": "Actions",
            "no results found": "No results found",
            "Name can't be empty": "Name can't be empty",
            "Category can't be empty": "Category can't be empty",
            "Type can't be empty": "Type can't be empty",
            "Size can't be empty": "Size can't be empty",
            "Season can't be empty": "Season can't be empty",
            "Gender can't be empty": "Gender can't be empty",
            "Unit can't be empty": "Unit can't be empty",
            "Buy price can't be empty": "Buy price can't be empty",
            "Sell price can't be empty": "Sell price can't be empty",
            "Description can't be empty": "Description can't be empty",
            "Name cannot be more than 20 characters": "Name cannot be more than 20 characters",
            "Description cannot be more than 200 characters": "Description cannot be more than 200 characters",
            "Barcode cannot be more than 12 characters": "Barcode cannot be more than 12 characters",
            "Warehouse can't be empty": "Warehouse can't be empty",
            "Warehouse in can't be empty": "Warehouse in can't be empty",
            "Warehouse out can't be empty": "Warehouse out can't be empty",
            "Partner can't be empty": "Partner can't be empty",
            "Payment method can't be empty": "Payment method can't be empty",
            "Currency can't be empty": "Currency can't be empty",
            "Status can't be empty": "Status can't be empty",
            "Product can't be empty": "Product can't be empty",
            "Price can't be empty": "Price can't be empty",
            "Quantity can't be empty": "Quantity can't be empty",
            "Exchange rate can't be empty": "Exchange rate can't be empty",
            "Description cannot be more than 100 characters": "Description cannot be more than 100 characters",
            "First Name cannot be more than 20 characters": "First Name cannot be more than 20 characters",
            "Last Name cannot be more than 20 characters": "Last Name cannot be more than 20 characters",
            "Email can't be empty": "Email can't be empty",
            "Email cannot be more than 50 characters": "Email cannot be more than 50 characters",
            "Username can't be empty": "Username can't be empty",
            "Username cannot be more than 20 characters": "Username cannot be more than 20 characters",
            "Password has to be at least 8 characters": "Password has to be at least 8 characters",
            "Password cannot be more than 20 characters": "Password cannot be more than 20 characters",
            "Role can't be empty": "Role can't be empty",
            "Name cannot be more than 255 characters": "Name cannot be more than 255 characters",
            "Permissions can't be empty": "Permissions can't be empty",
            "Description cannot be more than 50 characters": "Description cannot be more than 50 characters",
            "Brand can't be empty": "Brand can't be empty",
            "Code can't be empty": "Code can't be empty",
            "Code cannot be more than 5 characters": "Code cannot be more than 5 characters",
            "Base unit can't be empty": "Base unit can't be empty",
            "Name cannot be more than 50 characters": "Name cannot be more than 50 characters",
            "Company can't be empty": "Company can't be empty",
            "Warehouse type can't be empty": "Warehouse type can't be empty",
            "Address cannot be more than 200 characters": "Address cannot be more than 200 characters",
            "Timezone cannot be more than 30 characters": "Timezone cannot be more than 30 characters",
            "State cannot be more than 20 characters": "State cannot be more than 20 characters",
            "Country cannot be more than 20 characters": "Country cannot be more than 20 characters",
            "City cannot be more than 20 characters": "City cannot be more than 20 characters",
            "Zip code cannot be more than 10 characters": "Zip code cannot be more than 10 characters",
            "Name cannot be more than 100 characters": "Name cannot be more than 100 characters",
            "Email cannot be more than 50 characters": "Email cannot be more than 50 characters",
            "Phone number cannot be more than 20 characters": "Phone number cannot be more than 20 characters",
            "Fax cannot be more than 15 characters": "Fax cannot be more than 15 characters",
            "Limit level can't be empty": "Limit level can't be empty",
            "Country can't be empty": "Country can't be empty",
            "Nipt cannot be more than 50 characters": "Nipt cannot be more than 50 characters",
            "Phone number can't be empty": "Phone number can't be empty",
            "Phone number has to be at least 10 characters": "Phone number has to be at least 10 characters",
            "Phone number cannot be more than 50 characters": "Phone number cannot be more than 50 characters",
            "Birthday can't be empty": "Birthday can't be empty",
            "Description cannot be more than 250 characters": "Description cannot be more than 250 characters",
            "created-at": "Created at",
            "try another quantity": "Try another quantity",
            "not enough stock": "Not enough stock",
            "the quantity must be less than actual value": "The quantity must be less than actual value",
            "offer": "Offer",
            "no offer": "No offer",
            "discount": "Discount",
            "discount type": "Discount type",
            "no payment": "No payment",
            "year": "year",
            "years": "years",
            "product list empty": "Product list empty",
            "products": "Products",
            "date": "Date",
            "Discount can't be empty": "Discount can't be empty",
            "Discount type can't be empty": "Discount type can't be empty",
            "Start date can't be empty": "Start date can't be empty",
            "End date can't be empty": "End date can't be empty",
            "decimal": "Decimal",
            "percentage": "Percentage",
            "click on product name to see unit in offer": "Click on product name to see unit in offer",
            "composite product": "Composite product",
            "number of components": "Number of components",
            "composite product list empty": "Composite product list empty",
            "add-component": "Add component",
            "Component can't be empty": "Component can't be empty",
            "main product": "Main product",
            "components": "Components",
            "component": "Component",
            "simple": "Simple",
            "main unit": "Main unit",
            "you don't have permission at this page": "You don't have permission at this page",
            "here's what's happening": "Here's what's happening",
            "order": "Order",
            "account": "Account",
            "product unit": "Product unit",
            "reporting": "Reporting",
            "promotion": "Promotion",
            "other": "Other",
            "allowed": "Allowed",
            "this month": "This month",
            "search by product or brand": "Search by product or brand",
            "Password can't be empty": "Password can't be empty",
            "Email has to be email": "Email has to be email",
            "read from barcode": "Read from barcode",
            "update profile": "Update profile",
            "change password": "Change password",
            "current password": "Current password",
            "new password": "New password",
            "confirm password": "Confirm password",
            "company data updated successfully": "Company data updated successfully",
            "profile updated successfully": "Profile updated successfully",
            "phone": "Phone",
            "First name can't be empty": "First name can't be empty",
            "Last name can't be empty": "Last name can't be empty",
            "Current password can't be empty": "Current password can't be empty",
            "New password can't be empty": "New password can't be empty",
            "Confirm password can't be empty": "Confirm password can't be empty",
            "New password has to be at least 8 characters": "New password has to be at least 8 characters",
            "download": "Download",
            "sales": "sales",
            "exchange rate": "Exchange rate",
            "confirm order": "Confirm order",
            "change status": "Change status",
            "exchanged": "Exchanged",
            "type here": "Type here",
        }
    },
    it: {
        translation: {
            "English": "Inglese",
            "Italian": "Italiano",
            "topbar.profile": "Profilo",
            "topbar.help": "Assistenza",
            "topbar.log-out": "Esci",
            "sidebar.dashboard": "Dashboard",
            "sidebar.products": "Prodotti",
            "sidebar.orders": "Ordini",
            "sidebar.order/in": "Ordine all'ingresso", // ?
            "sidebar.order/out": "Ordine in uscita", // ?
            "sidebar.promotions": "Promozioni",
            "sidebar.promotions.marketing": "Marketing",
            "sidebar.promotions.promotion": "Promozione",
            "sidebar.reporting": "Reportistica",
            "sidebar.advanced/reporting": "Vendite",
            "sidebar.promotion/reporting": "Promozioni", // ?
            "sidebar.stock/reporting": "Stock",
            "sidebar.accounts": "Account",
            "sidebar.accounts.users": "Utenti",
            "sidebar.accounts.roles": "Ruoli",
            "sidebar.accounts.groups": "Gruppi",
            "sidebar.settings": "Impostazioni",
            "sidebar.settings.product": "Prodotto",
            "sidebar.settings.composed-product": "Prodotto Composito",
            "sidebar.settings.orders": "Ordine",
            "sidebar.settings.warehouses": "Magazzino",
            "sidebar.settings.alerts": "Avviso",
            "sidebar.settings.general": "Generale",
            "sidebar.settings.partner": "Partner",
            "sidebar.settings.offers": "Offerte",
            "dashboard.products": "Prodotti",
            "dashboard.warehouses": "Magazzini",
            "order-in": "Ordine all'ingresso", // ?
            "order-out": "Ordine in uscita", // ?
            "performance": "Rendimento",
            "overview": "Panoramica",
            "dashboard.orders": "Ordini totali",
            "dashboard.earning": "Valore di guadagno",
            "name": "Nome",
            "product": "Prodotto",
            "brand": "Marca",
            "category": "Categoria",
            "type": "Tipo",
            "size": "Misura",
            "season": "Stagione",
            "gender": "Sesso",
            "unit": "Unità",
            "base unit": "Unità base",
            "code": "Codice",
            "created-by": "Creato da",
            "price": 'Prezzo',
            "buy-price": "Prezzo di acquisto",
            "sell-price": "Prezzo di vendita",
            "quantity": "Quantità",
            "currency": "Valuta",
            "barcode": "Codice a barre",
            "description": "Descrizione",
            "partner": "Partner",
            "status": "Stato",
            "parent": "Genitore",
            "company": "Società",
            "order-type": "Tipo di ordine",
            "invoice-code": "Codice fattura",
            "invoice-number": "Numero di fattura",
            "payment-method": "Metodo di pagamento",
            "total": "Totale",
            "start-date": "Data di inizio",
            "end-date": "Data di fine",
            "warehouse": "Magazzino",
            "warehouses": "Magazzini",
            "warehouse-type": "Tipo di magazzino",
            "warehouse-in": "Magazzino all'ingresso", // ?
            "warehouse-out": "Magazzino in uscita", // ?
            "search": "Cerca",
            "cancel": "Annulla",
            "save": "Salva",
            "yes": "Sì",
            "no": "No",
            "new": "Nuovo",
            "new-f": "Nuova",
            "send": "Invia",
            "start a promotion": "Avvia una promozione",
            "stop": "Fermare",
            "add-new": "Aggiungere nuova",
            "create": "Crea",
            "edit": "Modifica",
            "add-unit": "Aggiungere unità",
            "add-product": "Aggiungi prodotto",
            "are you sure to delete this item": "Sei sicuro di eliminare questo elemento",
            "first-name": "Nome",
            "last-name" : "Cognome",
            "email": "Email",
            "phone-number": "Numero di telefono",
            "birthdate": "Compleanno",
            "type-message": "Digitare messaggio",
            "created-order": "Ordine creato",
            "order-amount": "Ammontare dell'ordine",
            "created-partner": "Partner creato",
            "age": "Età",
            "user": "Utente",
            "duration": "Durata",
            "total-minutes": "Minuti totali",
            "total-hours": "Ore totali",
            "from": "Da",
            "to": "A",
            "role": "Ruolo",
            "group": "Gruppo",
            "roles": "Ruoli",
            "groups": "Gruppi",
            "username": "Nome utente",
            "password": "Password",
            "stock list empty": "Elenco stock vuoto",
            "add new order details": "Aggiungi nuovi dettagli dell'ordine",
            "user list empty": "Elenco utenti vuoto",
            "role list empty": "Elenco dei ruoli vuoto",
            "group list empty": "Elenco dei gruppi vuoto",
            "permissions": "Permessi",
            "warehouse-permissions": "Permessi di magazzino",
            "nothing in marketing": "Niente nel Marketing",
            "you don't have any promotion saved": "Non ci sono promozioni salvate",
            "sign in to your account": "Accedi al tuo account",
            "forgot password": "Password dimenticata",
            "sign in": "Accedi",
            "account recovery": "Recupero dell'account",
            "send request": "Invia richiesta",
            "enter your email and we send you a reset link": "Inserisci la email e ti inviamo un link di ripristino", // ?
            "partner list empty": "Elenco partner vuoto",
            "edit company details": "Modifica dettagli della società",
            "address": "Indirizzo",
            "state": "Stato",
            "country": "Paese",
            "city": "Città",
            "zip code": "Codice postale",
            "timezone": "Fuso orario",
            "fax": "Fax",
            "symbol": "Simbolo",
            "contact person": "Referente",
            "contact": "Contatto",
            "location": "Posizione",
            "status type": "Tipo di stato",
            "order type": "Tipo di ordine",
            "order status type": "Tipo di stato dell'ordine",
            "payment method list empty": "Elenco dei metodi di pagamento vuoto",
            "currency list empty": "Elenco valute vuoto",
            "warehouse list empty": "Elenco magazzino vuoto",
            "warehouse type list empty": "Elenco dei tipi di magazzino vuoto",
            "order type list empty": "Elenco dei tipi di ordine vuoto",
            "order status type list empty": "Elenco del tipo di stato dell'ordine vuoto",
            "brand list empty": "Elenco marche vuoto",
            "category list empty": "Elenco delle categorie vuoto",
            "gender list empty": "Elenco di genere vuoto",
            "season list empty": "Elenco delle stagioni vuoto",
            "size list empty": "Elenco misure vuoto",
            "type list empty": "Elenco di tipo vuoto", // ?
            "base unit list empty": "Elenco unità base vuoto",
            "unit list empty": "Elenco unità vuoto",
            "footer-text": "da InnoSource Technology team",
            "are you sure to send a SMS for all searched results": "Sei sicuro di inviare un sms per tutti i risultati della ricerca?", // ?
            "are you sure to send an email for all searched results": "Sei sicuro di inviare un'e-mail per tutti i risultati della ricerca", // ?
            "promotion list empty": "Elenco promozioni vuoto",
            "sales list empty": "Elenco vendite vuoto",
            "group-by": "Raggruppa per", //?
            "total-sales": "Vendite totali",
            "earn": "Salario",
            "identifier": "Identificatore",
            "stock": "Stock",
            "transfered": "Trasferita",
            "sold": "Venduto",
            "nothing in alerts": "Niente negli avvisi",
            "alert": "Avviso",
            "view alert list": "Visualizzare l'elenco degli avvisi",
            "limit-level": "Livello limite",
            "order details": "Dettagli dell'ordine",
            "composite": "Composito",
            "simple": "Semplice",
            "actions": "Azione",
            "no results found": "Non sono stati trovati risultati",
            "Name can't be empty": "Il nome non può essere vuoto",
            "Category can't be empty": "La categoria non può essere vuota",
            "Type can't be empty": "Il tipo non può essere vuoto",
            "Size can't be empty": "La dimensione non può essere vuota",
            "Season can't be empty": "La stagione non può essere vuota",
            "Gender can't be empty": "Il genere non può essere vuoto",
            "Unit can't be empty": "L'unità non può essere vuota",
            "Buy price can't be empty": "Il prezzo di acquisto non può essere vuoto",
            "Sell price can't be empty": "Il prezzo di vendita non può essere vuoto",
            "Description can't be empty": "La descrizione non può essere vuota",
            "Name cannot be more than 20 characters": "Il nome non può contenere più di 20 caratteri",
            "Description cannot be more than 200 characters": "La descrizione non può contenere più di 200 caratteri",
            "Barcode cannot be more than 12 characters": "Il codice a barre non può contenere più di 12 caratteri",
            "Warehouse can't be empty": "Il magazzino non può essere vuoto",
            "Warehouse in can't be empty": "Magazzino all'ingresso non può essere vuoto",
            "Warehouse out can't be empty": "Magazzino in uscita non può essere vuoto",
            "Partner can't be empty": "Il partner non può essere vuoto",
            "Payment method can't be empty": "Il metodo di pagamento non può essere vuoto",
            "Currency can't be empty": "La valuta non può essere vuota",
            "Status can't be empty": "lo stato non può essere vuoto",
            "Product can't be empty": "Il prodotto non può essere vuoto",
            "Price can't be empty": "Il prezzo non può essere vuoto",
            "Quantity can't be empty": "La quantità non può essere vuota",
            "Exchange rate can't be empty": "Il tasso di cambio non può essere vuoto",
            "Description cannot be more than 100 characters": "La descrizione non può contenere più di 100 caratteri",
            "First Name cannot be more than 20 characters": "Il nome non può contenere più di 20 caratteri",
            "Last Name cannot be more than 20 characters": "Il cognome non può contenere più di 20 caratteri",
            "Email can't be empty": "Email non può essere vuota",
            "Email cannot be more than 50 characters": "Email non può contenere più di 50 caratteri",
            "Username can't be empty": "Il nome utente non può essere vuoto",
            "Username cannot be more than 20 characters": "Il nome utente non può contenere più di 20 caratteri",
            "Password has to be at least 8 characters": "La password deve contenere almeno 8 caratteri",
            "Password cannot be more than 20 characters": "La password non può contenere più di 20 caratteri",
            "Role can't be empty": "Il ruolo non può essere vuoto",
            "Name cannot be more than 255 characters": "Il nome non può contenere più di 255 caratteri",
            "Permissions can't be empty": "Permessi non possono essere vuote",
            "Description cannot be more than 50 characters": "La descrizione non può contenere più di 50 caratteri",
            "Brand can't be empty": "Il marchio non può essere vuoto",
            "Code can't be empty": "Il codice non può essere vuoto",
            "Code cannot be more than 5 characters": "Il codice non può contenere più di 5 caratteri",
            "Base unit can't be empty": "L'unità base non può essere vuota",
            "Name cannot be more than 50 characters": "Il nome non può contenere più di 50 caratteri",
            "Code cannot be more than 50 characters": "Il codice non può contenere più di 50 caratteri",
            "Company can't be empty": "Società non può essere vuota",
            "Warehouse type can't be empty": "Il tipo di magazzino non può essere vuoto",
            "Address cannot be more than 200 characters": "L'indirizzo non può contenere più di 200 caratteri",
            "Timezone cannot be more than 30 characters": "Il fuso orario non può contenere più di 30 caratteri",
            "State cannot be more than 20 characters": "Lo stato non può contenere più di 20 caratteri",
            "Country cannot be more than 20 characters": "Il paese non può contenere più di 20 caratteri",
            "City cannot be more than 20 characters": "La città non può contenere più di 20 caratteri",
            "Zip code cannot be more than 10 characters": "Il codice postale non può contenere più di 10 caratteri",
            "Name cannot be more than 100 characters": "Il nome non può contenere più di 100 caratteri",
            "Email cannot be more than 50 characters": "L'e-mail non può contenere più di 50 caratteri",
            "Phone number cannot be more than 20 characters": "Il numero di telefono non può contenere più di 20 caratteri",
            "Fax cannot be more than 15 characters": "Il fax non può contenere più di 15 caratteri",
            "Level limit can't be empty": "Il limite di livello non può essere vuoto",
            "Country can't be empty": "Il paese non può essere vuoto",
            "Nipt cannot be more than 50 characters": "Nipt non può contenere più di 50 caratteri",
            "Phone number can't be empty": "Il numero di telefono non può essere vuoto",
            "Phone number has to be at least 10 characters": "Il numero di telefono deve contenere almeno 10 caratteri",
            "Phone number cannot be more than 50 characters": "Il numero di telefono non può contenere più di 50 caratteri",
            "Birthday can't be empty": "Data di nascita non può essere vuoto",
            "Description cannot be more than 250 characters": "La descrizione non può contenere più di 250 caratteri",
            "created-at": "Creato a",
            "try another quantity": "Prova un'altra quantità",
            "not enough stock": "Non abbastanza scorte",
            "the quantity must be less than actual value": "La quantità deve essere inferiore al valore effettivo",
            "offer": "Offerta",
            "no offer": "Nessuna offerta",
            "discount": "Sconto",
            "discount type": "Tipo di sconto",
            "no payment": "Nessun pagamento",
            "year": "anno",
            "years": "anni",
            "product list empty": "Elenco prodotti vuoto",
            "products": "Prodotti",
            "date": "Data",
            "Discount can't be empty": "Lo sconto non può essere vuoto",
            "Discount type can't be empty": "Il tipo di sconto non può essere vuoto",
            "Start date can't be empty": "La data di inizio non può essere vuota",
            "End date can't be empty": "La data di fine non può essere vuota",
            "decimal": "Decimale",
            "percentage": "Percentuale",
            "click on product name to see unit in offer": "Clicca sul nome del prodotto per vedere l'unità in offerta",
            "composite product": "Prodotto composito",
            "number of components": "Numero di componenti",
            "composite product list empty": "Elenco prodotti compositi vuoto",
            "add-component": "Aggiungi componente",
            "Component can't be empty": "Il componente non può essere vuoto",
            "main product": "Prodotto principale",
            "components": "Componenti",
            "component": "Componente",
            "main unit": "Unità principale",
            "you don't have permission at this page": "Non hai il permesso in questa pagina",
            "here's what's happening": "Ecco cosa sta succedendo",
            "order": "Ordine",
            "account": "Account",
            "product unit": "Unità di prodotto",
            "reporting": "Reportistica",
            "promotion": "Promozione",
            "other": "Altro",
            "allowed": "Permesso",
            "this month": "Questo mese",
            "search by product or brand": "Ricerca per prodotto o marca",
            "Password can't be empty": "La password non può essere vuota",
            "Email has to be email": "L'e-mail deve essere e-mail",
            "read from barcode": "Leggere dal codice a barre",
            "update profile": "Aggiorna il profilo",
            "change password": "Cambia la password",
            "current password": "Corrente password",
            "new password": "Nuova password",
            "confirm password":"Conferma password",
            "company data updated successfully": "Dati della società aggiornati con successo",
            "profile updated successfully": "Profilo aggiornato con successo",
            "phone": "Telefono",
            "First name can't be empty": "Il nome non può essere vuoto",
            "Last name can't be empty": "Il cognome non può essere vuoto",
            "Current password can't be empty": "La password attuale non può essere vuota",
            "New password can't be empty": "La nuova password non può essere vuota",
            "Confirm password can't be empty": "Conferma password non può essere vuoto",
            "New password has to be at least 8 characters": "La nuova password deve contenere almeno 8 caratteri",
            "download": "Scaricare",
            "sales": "vendite",
            "exchange rate": "Tasso di cambio",
            "confirm order": "Confermare l'ordine",
            "change status": "Cambiare stato",
            "exchanged": "Scambiato",
            "type here": "Digita qui"
        }
    },
    tr: {
        translation: {
            "English" : "İngilizce",
            "Italian" : "İtalyanca",
            "topbar.profile" : "Profilim",
            "topbar.settings" : "Ayarlar",
            "topbar.feedback" : "Geri bildirim",
            "topbar.help" : "Yardım",
            "topbar.log-out" : "Çıkış Yap",
            "sidebar.dashboard" : "Gösterge Paneli",
            "sidebar.products" : "Ürünler",
            "sidebar.orders" : "Siparişler",
            "sidebar.order/in" : "Sipariş Ver",
            "sidebar.order/out" : "Sipariş Dışı",
            "sidebar.promotions" : "Promosyonlar",
            "sidebar.promotions.marketing" : "Pazarlama",
            "sidebar.promotions.promotion" : "Promosyon",
            "sidebar.reporting" : "Raporlama",
            "sidebar.advanced/reporting" : "Satış",
            "sidebar.promotion/reporting" : "Promosyon",
            "sidebar.accounts" : "Hesaplar",
            "sidebar.accounts.users" : "Kullanıcılar",
            "sidebar.accounts.roles" : "Roller",
            "sidebar.accounts.groups" : "Groups",
            "sidebar.settings" : "Ayarlar",
            "sidebar.settings.products" : "Ürün",
            "sidebar.settings.orders" : "Sipariş",
            "sidebar.settings.warehouses" : "Depo",
            "sidebar.settings.alerts" : "Uyarı",
            "sidebar.settings.general" : "Genel",
            "sidebar.settings.partner" : "Ortak",
            "dashboard.products" : "Ürünler",
            "dashboard.warehouses" : "Depolar",
            "order-in" : "Sipariş Ver",
            "order-out" : "Sipariş Dışı",
            "dashboard.orders" : "Siparişlere Genel Bakış",
            "dashboard.earning" : "Kazanç Genel Bakış",
            "name" : "İsim",
            "product" : "Ürün",
            "brand" : "Marka",
            "category" : "Kategori",
            "type" : "Tip",
            "size" : "Boyut",
            "season" : "Mevsim",
            "gender" : "Cinsiyet",
            "unit" : "Birim",
            "base unit" : "Ana ünite",
            "code" : "kod",
            "created-by" : "Tarafından yaratıldı",
            "price" : "Fiyat",
            "buy-price" : "Alış fiyatı",
            "sell-price" : "Satış fiyatı",
            "quantity" : "Miktar",
            "currency" : "Para birimi",
            "barcode" : "Barkod",
            "description" : "Tanım",
            "partner" : "Ortak",
            "status" : "Durum",
            "parent" : "ebeveyn",
            "company" : "Şirket",
            "order-type" : "Sipariş türü",
            "invoice-code" : "fatura kodu",
            "invoice-number" : "Fatura numarası",
            "payment-method" : "Ödeme şekli",
            "total" : "Toplam",
            "start-date" : "Başlangıç ​​tarihi",
            "end-date" : "Bitiş tarihi",
            "warehouse" : "Depo",
            "warehouses" : "Depolar",
            "warehouse-type" : "Depo tipi",
            "warehouse-in" : "Depo girişi",
            "warehouse-out" : "Depo çıkışı",
            "search" : "Arama",
            "cancel" : "İptal",
            "save" : "Kaydetmek",
            "yes" : "Evet",
            "no" : "Hayır",
            "new" : "Yeni",
            "send" : "Göndermek",
            "start a promotion" : "Bir promosyon başlat",
            "stop" : "Durmak",
            "add-new" : "Yeni ekle",
            "create" : "Oluşturmak",
            "edit" : "Düzenlemek",
            "add-unit" : "Birim ekle",
            "are you sure to delete this item" : "Bu öğeyi silmek istediğinizden emin misiniz?",
            "first-name" : "İlk adı",
            "last-name " : "Soy isim",
            "email" : "E-posta",
            "phone-number" : "Telefon numarası",
            "birthdate" : "Doğum günü",
            "type-message" : "Mesaj yazın",
            "created-order" : "Oluşturulan sipariş",
            "order-amount" : "Sipariş miktarı",
            "created-partner" : "İş ortağı oluşturuldu",
            "age" : "Yaş",
            "user" : "kullanıcı",
            "duration" : "Süre",
            "total-hours" : "Toplam Saat",
            "from" : "İtibaren",
            "to" : "İle",
            "role" : "rol",
            "group" : "Grup",
            "roles" : "Roller",
            "groups" : "Gruplar",
            "username" : "Kullanıcı adı",
            "password" : "Şifre",
            "product list empty" : "Ürün listesi boş",
            "view this product" : "Bu ürünü görüntüle",
            "order list empty" : "Sipariş listesi boş",
            "view this order" : "Bu siparişi görüntüle",
            "add new order details" : "Yeni sipariş ayrıntıları ekle",
            "user list empty" : "Kullanıcı listesi boş",
            "view this user" : "Bu kullanıcıyı görüntüle",
            "role list empty" : "Rol listesi boş",
            "view this role" : "Bu rolü görüntüle",
            "group list empty" : "Grup listesi boş",
            "view this group" : "Bu grubu görüntüle",
            "permissions" : "izinler",
            "warehouse-permissions" : "Depo izinleri",
            "nothing in marketing" : "Pazarlamada hiçbir şey yok",
            "you don't have any promotion saved" : "Kaydedilmiş promosyonunuz yok",
            "sign in to ypur account" : "hesabınıza giriş yapın",
            "forgot password" : "Parolanızı mı unuttunuz",
            "sign in" : "Kayıt olmak",
            "account recovery" : "Hesap kurtarma",
            "send request" : "İstek gönder",
            "enter your email and we send you a reset link" : "E-postanızı girin, size bir sıfırlama bağlantısı gönderelim",
            "partner list empty" : "İş ortağı listesi boş",
            "view this partner" : "Bu iş ortağını görüntüle",
            "edit company details" : "Şirket ayrıntılarını düzenle",
            "address" : "Adres",
            "state" : "Eyalet",
            "country" : "Ülke",
            "city" : "Şehir",
            "zip code" : "Posta kodu",
            "timezone" : "Saat dilimi",
            "fax" : "Faks",
            "symbol" : "Sembol",
            "contact person" : "İlgili kişi",
            "contact" : "İletişim",
            "location" : "Konum",
            "status type" : "Durum türü",
            "order type" : "Sipariş türü",
            "order status type" : "Sipariş durumu türü",
            "payment method list empty" : "Ödeme yöntemi listesi boş",
            "view this payment method" : "Bu ödeme yöntemini görüntüle",
            "currency list empty" : "Para birimi listesi boş",
            "view this currency" : "Bu para birimini görüntüle",
            "warehouse list empty" : "Depo listesi boş",
            "view this warehouse" : "Bu depoyu görüntüle",
            "warehouse type list empty" : "Depo tipi listesi boş",
            "view this warehouse type" : "Bu depo türünü görüntüle",
            "order type list empty" : "Sipariş türü listesi boş",
            "view this order type" : "Bu sipariş türünü görüntüle",
            "order status type list empty" : "Sipariş durumu türü listesi boş",
            "view this order status type" : "Bu sipariş durumu türünü görüntüle",
            "brand list empty" : "Marka listesi boş",
            "view this brand" : "Bu markayı görüntüle",
            "category list empty" : "Kategori listesi boş",
            "view this category" : "Bu kategoriyi görüntüle",
            "gender list empty" : "Cinsiyet listesi boş",
            "view this gender" : "Bu cinsiyeti görüntüle",
            "season list empty" : "Sezon listesi boş",
            "view this season" : "Bu sezonu görüntüle",
            "size list empty" : "Boyut listesi boş",
            "view this size" : "Bu boyutu görüntüle",
            "type list empty" : "Tür listesi boş",
            "view this type" : "Bu türü görüntüle",
            "base unit list empty" : "Ana birim listesi boş",
            "view this base unit" : "Bu ana birimi görüntüle",
            "unit list empty" : "Birim listesi boş",
            "view this unit" : "Bu birimi görüntüle",
            "footer-text" : "InnoSource Teknoloji ekibi tarafından",
            "are you sure to send a SMS for all searched results" : "Aranan tüm sonuçlar için SMS göndereceğinizden emin misiniz?",
            "are you sure to send an email for all searched results" : "Tüm aranan sonuçlar için bir e-posta gönderdiğinizden emin misiniz?",
            "promotion list empty" : "Promosyon listesi boş",
            "sales list empty" : "Satış listesi boş",
            "group-by" : "Gruplandırmak",
            "total-sales" : "Toplam satış",
            "earn" : "Kazanç",
            "identifier" : "tanımlayıcı",
            "stock" : "Stok",
            "transfered" : "transfer edildi",
            "sold" : "Satıldı",
            "nothin in alerts" : "Uyarılarda hiçbir şey yok",
            "alert" : "Uyarı",
            "view updated alert list" : "Güncellenmiş uyarı listesini görüntüleyin",
            "interval" : "December",
            "order details" : "sipariş detayları",
        }
}
}
