import { createActions } from 'reduxsauce';

const { Types: types, Creators: actions } = createActions({
    // add offer
    requestSubmitOffer: ['id', 'data'],
    submitOfferSucceeded: ['data'],
    submitOfferFailed: ['errors'],

    // get product details
    requestGetProductDetails: ['data'],
    getProductDetailsSucceeded: ['data'],
    getProductDetailsFailed: ['errors'],

    // clear state
    clearOfferCreateState: null,
});

export default {
    // add offer
    [types.REQUEST_SUBMIT_OFFER]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_OFFER_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            offer: data,
            success: true,
        },
    }),
    [types.SUBMIT_OFFER_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            success: false,
            errors,
        },
    }),

    // get product details
    [types.REQUEST_GET_PRODUCT_DETAILS]: (state) => ({
        ...state,
        create: {
            ...state.create,
            loading: true,
            errors: null,
        },
    }),
    [types.GET_PRODUCT_DETAILS_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            productDetails: data,
        },
    }),
    [types.GET_PRODUCT_DETAILS_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            errors,
        },
    }),

    // clear state
    [types.CLEAR_OFFER_CREATE_STATE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            offer: null,
            success: false,
            errors: null,
        },
    }),
};

export { types, actions };