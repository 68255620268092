import { createActions } from 'reduxsauce';

const { Types: types, Creators: actions } = createActions({
    // add payment method
    requestSubmitPaymentMethod: ['id', 'data'],
    submitPaymentMethodSucceeded: ['data'],
    submitPaymentMethodFailed: ['errors'],

    // clear state
    clearPaymentMethodCreateState: null,
});

export default {
    // add payment method
    [types.REQUEST_SUBMIT_PAYMENT_METHOD]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_PAYMENT_METHOD_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            details: data,
            success: true,
        },
    }),
    [types.SUBMIT_PAYMENT_METHOD_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            success: false,
            errors,
        },
    }),

    // clear state
    [types.CLEAR_PAYMENT_METHOD_CREATE_STATE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            details: null,
            success: false,
            errors: null,
        },
    }),
};

export { types, actions };