import { createActions, createReducer } from 'reduxsauce';
import initialState from './model';

export const { Types: types, Creators: actions } = createActions({

    // get url to download stock
    requestGetUrlToDownloadStock: ['data'],
    getUrlToDownloadStockSucceeded: ['downloadUrl'],
    getUrlToDownloadStockFailed: ['error'],

    // get url to download sales
    requestGetUrlToDownloadSales: ['data'],
    getUrlToDownloadSalesSucceeded: ['downloadUrl'],
    getUrlToDownloadSalesFailed: ['error'],

    // downoad excel file
    downloadExcelFile: ['data'],
    downloadExcelFileSucceeded: ['message'],
    downloadExcelFileFailed: ['error'],

    // clear url
    clearUrl: null,

});

export default createReducer(initialState, {
    // get url to download stock
    [types.REQUEST_GET_URL_TO_DOWNLOAD_STOCK]: (state) => ({
        ...state,
        loading: true,
        error: null,
    }),
    [types.GET_URL_TO_DOWNLOAD_STOCK_SUCCEEDED]: (state, { downloadUrl }) => ({
        ...state,
        urlToDownloadExcel: downloadUrl,
        loading: false,
    }),
    [types.GET_URL_TO_DOWNLOAD_STOCK_FAILED]: (state, { error }) => ({
        ...state,
        loading: false,
        error,
    }),

    // get url to download stock
    [types.REQUEST_GET_URL_TO_DOWNLOAD_SALES]: (state) => ({
        ...state,
        loading: true,
        error: null,
    }),
    [types.GET_URL_TO_DOWNLOAD_SALES_SUCCEEDED]: (state, { downloadUrl }) => ({
        ...state,
        urlToDownloadExcel: downloadUrl,
        loading: false,
    }),
    [types.GET_URL_TO_DOWNLOAD_SALES_FAILED]: (state, { error }) => ({
        ...state,
        loading: false,
        error,
    }),

    // download excel file
    [types.DOWNLOAD_EXCEL_FILE]: (state) => ({
        ...state,
        error: null,
    }),
    [types.DOWNLOAD_EXCEL_FILE_SUCCEEDED]: (state, { message }) => ({
        ...state,
        fileDownloadedSuccessfully: message,
    }),
    [types.DOWNLOAD_EXCEL_FILE_FAILED]: (state, { error }) => ({
        ...state,
        error,
    }),

    // clear url
    [types.CLEAR_URL]: (state) => ({
        ...state,
        urlToDownloadExcel: null,
    }),
});