import { all, fork, takeEvery } from 'redux-saga/effects';
import { types } from './reducer';
import pagination from '@iso/lib/helpers/pagination';
import CategoryService from '@iso/services/SettingsService/ProductService/CategoryService';

function* watchFetchProductCategories() {
    yield takeEvery(
        types.REQUEST_FETCH_PRODUCT_CATEGORIES,
        pagination('productCategories').saga(CategoryService),
    );
}

function* paginationSaga() {
    yield all([
        fork(watchFetchProductCategories),
    ]);
}

export default paginationSaga;