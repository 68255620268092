import pagination from '@iso/lib/helpers/pagination';

const {
    actions: baseActions,
    types,
    reducer,
} = pagination('orderTypes');

const actions = {
    requestFetchOrderTypes: baseActions.request,
    fetchOrderTypesSucceeded: baseActions.succeeded,
    fetchOrderTypesFailed: baseActions.failed,
};

export { types, actions };

export default reducer;