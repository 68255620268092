import { createActions } from 'reduxsauce';

export const { Types: types, Creators: actions } = createActions({
    // view order in
    requestViewOrderIn: ['id'],
    viewOrderInSucceeded: ['data'],
    viewOrderInFailed: ['error'],

    // change order in status
    requestChangeOrderInStatus: ['data'],
    changeOrderInStatusSuccedded: ['data'],
    changeOrderInStatusFailed: ['error'],

    // clear state
    clearOrderInViewState: null,
});

export default {
    // view order in
    [types.REQUEST_VIEW_ORDER_IN]: (state) => ({
        ...state,
        view: {
            ...state.view,
            loading: true,
            error: null,
        },
    }),
    [types.VIEW_ORDER_IN_SUCCEEDED]: (state, { data }) => ({
        ...state,
        view: {
            ...state.view,
            orderIn: data,
            loading: false,
        },
    }),
    [types.VIEW_ORDER_IN_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            loading: false,
            error,
        },
    }),

    // change order in status
    [types.REQUEST_CHANGE_ORDER_IN_STATUS]: (state) => ({
        ...state,
        view: {
            ...state.view,
            error: null,
        },
    }),
    [types.CHANGE_ORDER_IN_STATUS_SUCCEDDED]: (state, { data }) => ({
        ...state,
        view: {
            ...state.view,
            status: data,
        },
    }),
    [types.CHANGE_ORDER_IN_STATUS_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            error,
        },
    }),

    // clear state
    [types.CLEAR_ORDER_IN_VIEW_STATE]: (state) => ({
        ...state,
        view: {
            ...state.view,
            orderIn: [],
        },
    }),
}
