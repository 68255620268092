import actions from "./actions";

const initState = {
  productCode: -99,
};

 


export default function desktopReducer(state = initState, action) 
{
 
  switch (action.type) {
    case actions.SELECT_PRODUCT:
      return {
        ...state,
        productCode: action.productCode,
     };
    default:
      return state;
  } 
}
