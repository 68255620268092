import { createActions } from 'reduxsauce';

const { Types: types, Creators: actions } = createActions({
    // add gender
    requestSubmitGender: ['id', 'data'],
    submitGenderSucceeded: ['data'],
    submitGenderFailed: ['errors'],

    // clear state
    clearGenderCreateState: null,
});

export default {
    // add gender
    [types.REQUEST_SUBMIT_GENDER]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_GENDER_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            genderDetails: data,
            success: true,
        },
    }),
    [types.SUBMIT_GENDER_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            success: false,
            errors,
        },
    }),

    // clear state
    [types.CLEAR_GENDER_CREATE_STATE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            genderDetails: null,
            success: false,
            errors: null,
        },
    }),
};

export { types, actions };