import { createActions } from 'reduxsauce';

const { Types: types, Creators: actions } = createActions({
    // add alert
    requestSubmitAlert: ['id', 'data'],
    submitAlertSucceeded: ['data'],
    submitAlertFailed: ['errors'],

    // clear state
    clearAlertCreateState: null,

    // get product units
    requestGetProductUnits: ['data'],
    getProductUnitsSucceeded: ['data'],
    getProductUnitsFailed: ['errors'],
});

export default {
    // add alert
    [types.REQUEST_SUBMIT_ALERT]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_ALERT_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            alertDetails: data,
            success: true,
        },
    }),
    [types.SUBMIT_ALERT_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            success: false,
            errors,
        },
    }),

    // clear create state
    [types.CLEAR_ALERT_CREATE_STATE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            alertDetails: null,
            success: false,
            errors: null,
        },
    }),

    // get product units
    [types.REQUEST_GET_PRODUCT_UNITS]: (state) => ({
        ...state,
        create: {
            ...state.create,
            loading: true,
            errors: null,
        },
    }),
    [types.GET_PRODUCT_UNITS_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            productUnits: data,
        },
    }),
    [types.GET_PRODUCT_UNITS_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            errors,
        },
    }),
};

export { types, actions };