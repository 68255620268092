import { createActions } from 'reduxsauce';

export const { Types: types, Creators: actions } = createActions({
    // view alert
    requestViewAlert: ['id'],
    viewAlertSucceeded: ['data'],
    viewAlertFailed: ['error'],

    // clear state
    clearAlertViewState: null,
});

export default {
// view alert
    [types.REQUEST_VIEW_ALERT]: (state) => ({
        ...state,
        view: {
            ...state.view,
            loading: true,
            error: null,
        },
    }),
    [types.VIEW_ALERT_SUCCEEDED]: (state, { data }) => ({
        ...state,
        view: {
            ...state.view,
            alert: data,
            loading: false,
        },
    }),
    [types.VIEW_ALERT_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            loading: false,
            error,
        },
    }),

    // clear view state
    [types.CLEAR_ALERT_VIEW_STATE]: (state) => ({
        ...state,
        view: {
            ...state.view,
            alert: null,
        },
    }),
};