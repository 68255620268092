import BaseService from '../../BaseService';

class TypeService extends BaseService {
    getAll = (filters) => this.post('/warehouse/product/types', filters || { });

    getOne = (id) => this.get(`/warehouse/product/type/${id}`);

    deleteOne = (id) => this.delete(`/warehouse/product/type/${id}`);

    addType = (data) => this.post('/warehouse/product/type', data);

    editType = (id, data) => this.put(`/warehouse/product/type/${id}`, data);
}

export default new TypeService();