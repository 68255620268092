import {
    takeEvery,
    call,
    all,
    put,
    fork,
} from 'redux-saga/effects';

import { types, actions } from './reducer';
import RoleService from '@iso/services/AccountService/RoleService';
import { extractError } from '@iso/lib/helpers/api';

function* submitRole({ id, data }) {
    try {
        let response;
        if(id) {
            response = yield call(RoleService.editRole, id, data);
        } else {
            response = yield call(RoleService.addRole, data);
        }
        yield put(actions.submitRoleSucceeded(response.data));
    } catch (error) {
        yield put(actions.submitRoleFailed(extractError(error)));
    }
}

function* getPermissions() {
    try {
        const response = yield call(RoleService.getPermissions);
        yield put(actions.getPermissionsSucceeded(response.data));
    } catch (error) {
        yield put(actions.getPermissionsFailed(extractError(error)));
    }
}

function* watchSubmitRole() {
    yield takeEvery(
        types.REQUEST_SUBMIT_ROLE,
        submitRole,
    );
}

function* watchGetPermissions() {
    yield takeEvery(
        types.REQUEST_GET_PERMISSIONS,
        getPermissions,
    );
}

function* createSaga() {
    yield all([
        fork(watchSubmitRole),
        fork(watchGetPermissions),
    ]);
}

export default createSaga;