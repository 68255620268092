import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { extractError } from '@iso/lib//helpers/api';
import { types, actions } from './reducer';
import TypeService from '@iso/services/SettingsService/OrderService/TypeService';

function* deleteOrderType({ id }) {
    try {
        yield call(TypeService.deleteOne, id);
        yield put(actions.deleteOrderTypeSucceeded(id));
    } catch (error) {
        yield put(actions.deleteOrderTypeFailed(extractError(error)));
    }
}

function* watchDeleteOrderType() {
    yield takeEvery(
        types.REQUEST_DELETE_ORDER_TYPE,
        deleteOrderType,
    );
}

function* deleteSaga() {
    yield all([
        fork(watchDeleteOrderType),
    ]);
}

export default deleteSaga;
