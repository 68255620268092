const model = {
    submitting: false,
    errors: null,
    roleDetails: null,
    success: false,

    permissions: [],
    loading: false,
};

export default model;
