import { createActions } from 'reduxsauce';

const { Types: types, Creators: actions } = createActions({
    // add currency
    requestSubmitCurrency: ['id', 'data'],
    submitCurrencySucceeded: ['data'],
    submitCurrencyFailed: ['errors'],

    // clear state
    clearCurrencyCreateState: null,
});

export default {
    // add currency
    [types.REQUEST_SUBMIT_CURRENCY]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_CURRENCY_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            currencyDetails: data,
            success: true,
        },
    }),
    [types.SUBMIT_CURRENCY_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            success: false,
            errors,
        },
    }),

    // clear state
    [types.CLEAR_CURRENCY_CREATE_STATE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            currencyDetails: null,
            success: false,
            errors: null,
        },
    }),
};

export { types, actions };