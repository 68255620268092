import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { types, actions } from './reducer';
import { extractError } from '@iso/lib//helpers/api';
import PaymentMethodService from '@iso/services/SettingsService/GeneralService/PaymentMethodService';

function* viewPaymentMethod({ id }) {
    try {
        const response = yield call(PaymentMethodService.getOne, id);
        yield put(actions.viewPaymentMethodSucceeded(response.data));
    } catch (error) {
        yield put(actions.viewPaymentMethodFailed(extractError(error)));
    }
}

function* watchViewPaymentMethod() {
    yield takeEvery(
        types.REQUEST_VIEW_PAYMENT_METHOD,
        viewPaymentMethod,
    );
}

function* viewSaga() {
    yield all([
        fork(watchViewPaymentMethod),
    ]);
}

export default viewSaga;
