import { createActions } from 'reduxsauce';
import { upseartItem } from '@iso/lib/helpers/CRUD';

const { Types: types, Creators: actions } = createActions({
    // add order out
    requestSubmitOrderOut: ['data'],
    submitOrderOutSucceeded: ['data'],
    submitOrderOutFailed: ['errors'],

    // get product price details
    requestGetProductPriceDetailsOut: ['data'],
    getProductPriceDetailsOutSucceeded: ['data'],
    getProductPriceDetailsOutFailed: ['errors'],

    // read from barcode
    requestReadFromBarcodeOut: ['data'],
    readFromBarcodeOutSucceeded: ['data'],
    readFromBarcodeOutFailed: ['errors'],

    // save to all products
    saveToAllProductsOut: ['data'],

    // clear state
    clearOrderOutCreateState: null,

    // clear barcode units & product price details
    clearProductPriceDetailsOut: null,
    clearBarcodeUnitsOut: null,
});

export const replaceProductItem = (items, updatedItem) => items.map(
    (item) => (
        (parseInt(item.id) === updatedItem.id)
            ? {
                ...item,
                minQuantity: item.minQuantity ? parseInt(item.minQuantity) + 1 : 1,
            }
            : {
                ...item,
            }
    ),
);

export const updateProduct = (existingItems, newItem) => {
    const itemsAlreadyExists = existingItems.findIndex(
        (existingItem) => parseInt(existingItem.id) === newItem.id,
    ) !== -1;
    if (itemsAlreadyExists) {
        return replaceProductItem(existingItems, newItem);
    } else {
        return existingItems.concat({
            ...newItem,
            minQuantity: 1,
        });
    }
};

export default {
    // add order out
    [types.REQUEST_SUBMIT_ORDER_OUT]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_ORDER_OUT_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            orderOut: data,
            success: true,
        },
    }),
    [types.SUBMIT_ORDER_OUT_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            errors,
        },
    }),

    // get product price details
    [types.REQUEST_GET_PRODUCT_PRICE_DETAILS_OUT]: (state) => ({
        ...state,
        create: {
            ...state.create,
            loading: true,
            errors: null,
        },
    }),
    [types.GET_PRODUCT_PRICE_DETAILS_OUT_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            productPriceDetails: data,
        },
    }),
    [types.GET_PRODUCT_PRICE_DETAILS_OUT_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            errors,
        },
    }),

    // read from barcode
    [types.REQUEST_READ_FROM_BARCODE_OUT]: (state) => ({
        ...state,
        create: {
            ...state.create,
            loading: true,
            errors: null,
        },
    }),
    [types.READ_FROM_BARCODE_OUT_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            allProducts: updateProduct(state.create.allProducts, data.products),
            units: upseartItem(state.create.units, data.products.unit),
            barcodeProducts: upseartItem(state.create.barcodeProducts, data.products)
        },
    }),
    [types.READ_FROM_BARCODE_OUT_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            errors,
        },
    }),

    // save to all products
    [types.SAVE_TO_ALL_PRODUCTS_OUT]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            allProducts: data,
        }
    }),

    // clear state
    [types.CLEAR_ORDER_OUT_CREATE_STATE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            orderOut: [],
            success: false,
            errors: null,
            allProducts: [],
        },
    }),

    // clear barcode units & product price details
    [types.CLEAR_PRODUCT_PRICE_DETAILS_OUT]: (state) => ({
        ...state,
        create: {
            ...state.create,
            productPriceDetails: [],
        },
    }),

    [types.CLEAR_BARCODE_UNITS_OUT]: (state) => ({
        ...state,
        create: {
            ...state.create,
            units: [],
        },
    }),
};

export { types, actions };