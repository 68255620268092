import BaseService from '../../BaseService';

class CompanyService extends BaseService {

    getOne = () => this.get('/company/1');

    editCompany = (data) => this.put('/company/1', data);

    getCountries = (data) => this.post('/warehouse/country', data);
}

export default new CompanyService();