import {
    takeEvery,
    call,
    all,
    put,
    fork,
} from 'redux-saga/effects';

import { types, actions } from './reducer';
import TypeService from '@iso/services/SettingsService/WarehouseService/TypeService';
import { extractError } from '@iso/lib/helpers/api';

function* submitWarehouseType({ id, data }) {
    try {
        let response;
        if(id) {
            response = yield call(TypeService.editWarehouseType, id, data);
        } else {
            response = yield call(TypeService.addWarehouseType, data);
        }
        yield put(actions.submitWarehouseTypeSucceeded(response.data));
    } catch (error) {
        yield put(actions.submitWarehouseTypeFailed(extractError(error)));
    }
}

function* watchSubmitWarehouseType() {
    yield takeEvery(
        types.REQUEST_SUBMIT_WAREHOUSE_TYPE,
        submitWarehouseType,
    );
}

function* createSaga() {
    yield all([
        fork(watchSubmitWarehouseType),
    ]);
}

export default createSaga;