import { all } from 'redux-saga/effects';
import warehouseSaga from './warehouse/saga';
import typeSaga from './type/saga';

function* warehousesSaga() {
    yield all([
        warehouseSaga(),
        typeSaga(),
    ]);
}

export default warehousesSaga;