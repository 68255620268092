import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { types, actions } from './reducer';
import { extractError } from '@iso/lib//helpers/api';
import CurrencyService from '@iso/services/SettingsService/GeneralService/CurrencyService';

function* viewCurrency({ id }) {
    try {
        const response = yield call(CurrencyService.getOne, id);
        yield put(actions.viewCurrencySucceeded(response.data));
    } catch (error) {
        yield put(actions.viewCurrencyFailed(extractError(error)));
    }
}

function* watchViewCurrency() {
    yield takeEvery(
        types.REQUEST_VIEW_CURRENCY,
        viewCurrency,
    );
}

function* viewSaga() {
    yield all([
        fork(watchViewCurrency),
    ]);
}

export default viewSaga;
