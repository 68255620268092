import { combineReducers } from 'redux';

import marketing, {
    types as marketingTypes,
    actions as marketingActions,
} from './marketing/reducer';

import promotion, {
    types as promotionTypes,
    actions as promotionActions,
} from './promotion/reducer';

const actions = {
    ...marketingActions,
    ...promotionActions,
};

const types = {
    ...marketingTypes,
    ...promotionTypes,
};

export { types, actions };

const promotionsReducer = combineReducers({
    marketing,
    promotion,
});

export default promotionsReducer;