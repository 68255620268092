import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { types, actions } from './reducer';
import { extractError } from '@iso/lib//helpers/api';
import OrderOutService from '@iso/services/OrderService/OrderOutService';

function* viewOrderOut({ id }) {
    try {
        const response = yield call(OrderOutService.getOne, id);
        yield put(actions.viewOrderOutSucceeded(response.data));
    } catch (error) {
        yield put(actions.viewOrderOutFailed(extractError(error)));
    }
}

function* changeOrderOutStatus({ data }) {
    try {
        const response = yield call(OrderOutService.changeStatus, data);
        yield put(actions.changeOrderOutStatusSucceeded(response.data));
    } catch (error) {
        yield put(actions.changeOrderOutStatusFailed(extractError(error)));
    }
}

function* watchViewOrderOut() {
    yield takeEvery(
        types.REQUEST_VIEW_ORDER_OUT,
        viewOrderOut,
    );
}

function* watchChangeOrderOutStatus() {
    yield takeEvery(
        types.REQUEST_CHANGE_ORDER_OUT_STATUS,
        changeOrderOutStatus,
    );
}

function* viewSaga() {
    yield all([
        fork(watchViewOrderOut),
        fork(watchChangeOrderOutStatus),
    ]);
}

export default viewSaga;
