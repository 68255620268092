import { createActions } from 'reduxsauce';

const { Types: types, Creators: actions } = createActions({
    // add base unit
    requestSubmitBaseUnit: ['id', 'data'],
    submitBaseUnitSucceeded: ['data'],
    submitBaseUnitFailed: ['errors'],

    // clear state
    clearBaseUnitCreateState: null,
});

export default {
    // add base unit
    [types.REQUEST_SUBMIT_BASE_UNIT]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_BASE_UNIT_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            baseUnitDetails: data,
            success: true,
        },
    }),
    [types.SUBMIT_BASE_UNIT_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            success: false,
            errors,
        },
    }),

    // clear state
    [types.CLEAR_BASE_UNIT_CREATE_STATE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            baseUnitDetails: null,
            success: false,
            errors: null,
        },
    }),
};

export { types, actions };