import { createActions } from 'reduxsauce';

export const { Types: types, Creators: actions } = createActions({
    // view offer
    requestViewOffer: ['id'],
    viewOfferSucceeded: ['data'],
    viewOfferFailed: ['error'],

    // clear state
    clearOfferViewState: null,
});

export const getCategories = (data) => {
    const categories =  data.product.map((prod) => prod.category);
    return categories[0];
}

export const getProducts = (data) => {
    const products =  data.product.map((prod) => prod.products);
    return products[0];
}

export const getUnits = (data) => {
    const units =  data.product.map((prod) => prod.units);
    return units[0];
}

export default {
    // view offer
    [types.REQUEST_VIEW_OFFER]: (state) => ({
        ...state,
        view: {
            ...state.view,
            loading: true,
            error: null,
        },
    }),
    [types.VIEW_OFFER_SUCCEEDED]: (state, { data }) => ({
        ...state,
        view: {
            ...state.view,
            offer: data,
            categories: getCategories(data),
            products: getProducts(data),
            units: getUnits(data),
            loading: false,
        },
    }),
    [types.VIEW_OFFER_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            loading: false,
            error,
        },
    }),

    // clear state
    [types.CLEAR_OFFER_VIEW_STATE]: (state) => ({
        ...state,
        view: {
            ...state.view,
            offer: null,
            categories: [],
            products: [],
            units: [],
        },
    }),
}
