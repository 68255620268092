import {
    takeEvery,
    call,
    all,
    put,
    fork,
} from 'redux-saga/effects';

import { types, actions } from './reducer';
import UserService from '@iso/services/AccountService/UserService';
import { extractError } from '@iso/lib/helpers/api';

function* submitUser({ id, data }) {
    try {
        let response;
        if(id) {
            response = yield call(UserService.editUser, id, data);
        } else {
            response = yield call(UserService.addUser, data);
        }
        yield put(actions.submitUserSucceeded(response.data));
    } catch (error) {
        yield put(actions.submitUserFailed(extractError(error)));
    }
}

function* watchSubmitUser() {
    yield takeEvery(
        types.REQUEST_SUBMIT_USER,
        submitUser,
    );
}

function* createSaga() {
    yield all([
        fork(watchSubmitUser),
    ]);
}

export default createSaga;