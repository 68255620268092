import pagination from '@iso/lib/helpers/pagination';

const {
    actions: baseActions,
    types,
    reducer,
} = pagination('offers');

const actions = {
    requestFetchOffers: baseActions.request,
    fetchOffersSucceeded: baseActions.succeeded,
    fetchOffersFailed: baseActions.failed,
};

export { types, actions };

export default reducer;