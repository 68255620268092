import { combineReducers } from 'redux';

import alert, {
    types as alertTypes,
    actions as alertActions,
} from './alert/reducer';

import profile, {
    types as profileTypes,
    actions as profileActions,
} from './profile/reducer';

const actions = {
    ...alertActions,
    ...profileActions,
};

const types = {
    ...alertTypes,
    ...profileTypes,
};

export { types, actions };

const topbarReducer = combineReducers({
    alert,
    profile,
});

export default topbarReducer;