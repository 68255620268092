import { all, fork, takeEvery } from 'redux-saga/effects';
import { types } from './reducer';
import pagination from '@iso/lib/helpers/pagination';
import ProductService from '@iso/services/ProductService';

function* watchFetchProducts() {
    yield takeEvery(
        types.REQUEST_FETCH_PRODUCTS,
        pagination('products').saga(ProductService),
    );
}

function* paginationSaga() {
    yield all([
        fork(watchFetchProducts),
    ]);
}

export default paginationSaga;