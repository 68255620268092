import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { types, actions } from './reducer';
import { extractError } from '@iso/lib//helpers/api';
import CompanyService from '@iso/services/SettingsService/GeneralService/CompanyService';

function* viewCompany() {
    try {
        const response = yield call(CompanyService.getOne);
        yield put(actions.viewCompanySucceeded(response.data));
    } catch (error) {
        yield put(actions.viewCompanyFailed(extractError(error)));
    }
}

function* watchViewCompany() {
    yield takeEvery(
        types.REQUEST_VIEW_COMPANY,
        viewCompany,
    );
}

function* viewSaga() {
    yield all([
        fork(watchViewCompany),
    ]);
}

export default viewSaga;
