import BaseService from '../BaseService';

class CompositeProductService extends BaseService {
    getAll = (filters) => this.post('/warehouse/composedProduct/list', filters || { });

    getOne = (id) => this.get(`/warehouse/composedProduct/${id}`);

    deleteOne = (id) => this.delete(`/warehouse/composedProduct/${id}`);

    addProduct = (data) => this.post('/warehouse/composedProduct', data);

    editProduct = (id, data) => this.put(`/warehouse/composedProduct/update/${id}`, data);

    getProductUnits = (data) => this.post('/warehouse/composedProduct/productMinUnit', data);

    getComponentProducts = (filters) => this.post('/warehouse/product/list', filters || { });
};

export default new CompositeProductService();