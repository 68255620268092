import BaseService from './BaseService';

class ProductService extends BaseService {
    getAll = (filters) => this.post('/warehouse/product/list', filters || { });

    getOne = (id) => this.get(`/warehouse/product/${id}`);

    deleteOne = (id) => this.delete(`/warehouse/product/${id}`);

    addProduct = (data) => this.post('/warehouse/product', data);

    editProduct = (id, data) => this.put(`/warehouse/product/update/${id}`, data);

    getBrandCategories = (id) => this.get(`/warehouse/product/brandCategories/${id}`);

    getBarcode = (data) => this.post('/warehouse/product/barcodeGenerate', data);
}

export default new ProductService();