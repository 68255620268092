import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { types, actions } from './reducer';
import { extractError } from '@iso/lib/helpers/api';
import DashboardService from '@iso/services/DashboardService';

function* getDashboardStock() {
    try {
        const response = yield call(DashboardService.getDashboardStock);
        const {
            totals: totalData,
            graph_1: graph1Data,
            graph_2: graph2Data,
        } = response.data;
        yield put(actions.getDashboardStockSucceeded(
            totalData,
            graph1Data,
            graph2Data,
        ));
    } catch (error) {
        yield put(actions.getDashboardStockFailed(extractError(error)));
    }
}

function* watchGetDashboardStock() {
    yield takeEvery(
        types.REQUEST_GET_DASHBOARD_STOCK,
        getDashboardStock,
    );
}

function* dashboardSaga() {
    yield all([
        fork(watchGetDashboardStock),
    ]);
}

export default dashboardSaga;
