import { combineReducers } from 'redux';
import App from '@iso/redux/app/reducer';
import auth from '@iso/redux/auth/reducer';
import menu from '@iso/redux/menu/reducer';
import routes from '@iso/redux/routes/reducer';
import dashboard from '@iso/redux/dashboard/reducer';
import products from '@iso/redux/products/reducer';
import orders from '@iso/redux/orders/reducer';
import promotions from '@iso/redux/promotions/reducer';
import reporting from '@iso/redux/reporting/reducer';
import accounts from '@iso/redux/accounts/reducer';
import settings from '@iso/redux/settings/reducer';
import desktop from '@iso/redux/desktop/reducer';
import permissions from '@iso/redux/permissions/reducer';
import download from '@iso/redux/download/reducer';
import topbar from '@iso/redux/topbar/reducer';
import allowedWarehouses from '@iso/redux/allowedWarehouses/reducer';

export default combineReducers({
  auth,
  App,
  menu,
  routes,
  dashboard,
  products,
  orders,
  promotions,
  reporting,
  accounts,
  settings,
  desktop,
  permissions,
  download,
  topbar,
  allowedWarehouses,
});
