import { createActions } from 'reduxsauce';

export const { Types: types, Creators: actions } = createActions({
    // view base unit
    requestViewBaseUnit: ['id'],
    viewBaseUnitSucceeded: ['data'],
    viewBaseUnitFailed: ['error'],

    // clear state
    clearBaseUnitViewState: null,
});

export default {
    // view base unit
    [types.REQUEST_VIEW_BASE_UNIT]: (state) => ({
        ...state,
        view: {
            ...state.view,
            loading: true,
            error: null,
        },
    }),
    [types.VIEW_BASE_UNIT_SUCCEEDED]: (state, { data }) => ({
        ...state,
        view: {
            ...state.view,
            baseUnitDetails: data,
            loading: false,
        },
    }),
    [types.VIEW_BASE_UNIT_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            loading: false,
            error,
        },
    }),

    // clear state
    [types.CLEAR_BASE_UNIT_VIEW_STATE]: (state) => ({
        ...state,
        view: {
            ...state.view,
            baseUnitDetails: null,
        },
    }),
}
