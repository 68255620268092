import {
    takeEvery,
    call,
    all,
    put,
    fork,
} from 'redux-saga/effects';

import { types, actions } from './reducer';
import PromotionService from '@iso/services/PromotionService/PromotionService';
import { extractError } from '@iso/lib/helpers/api';

function* fetchStartStopPromotion({ data }) {
    const { start_stop: startStop } = data;
    try {
        const response = yield call(PromotionService.startStopPromotion, data);
    
        yield put(actions.fetchStartStopPromotionSucceeded(
            response.message,
            startStop,
        ));
    } catch (error) {
        yield put(actions.fetchStartStopPromotionFailed(extractError(error)));
    }
}

function* watchFetchStartStopPromotion() {
    yield takeEvery(
        types.REQUEST_FETCH_START_STOP_PROMOTION,
        fetchStartStopPromotion
    );
}


function* createSaga() {
    yield all([
        fork(watchFetchStartStopPromotion),
    ]);
}

export default createSaga;