import pagination from '@iso/lib/helpers/pagination';
import { createReducer } from 'reduxsauce';
import initialState from './model';

const {
    actions: baseActions,
    types: paginationTypes,
    reducer: paginationReducer,
} = pagination('stock');

const paginationActions = {
    requestFetchStock: baseActions.request,
    fetchStockSucceeded: baseActions.succeeded,
    fetchStockFailed: baseActions.failed,
};

export const types = { ...paginationTypes };

export const actions = { ...paginationActions};

export default createReducer(initialState, {
    // get stock list
    ...paginationReducer,
});