import { createActions } from 'reduxsauce';

const { Types: types, Creators: actions } = createActions({
    // add partner
    requestSubmitPartner: ['id', 'data'],
    submitPartnerSucceeded: ['data'],
    submitPartnerFailed: ['errors'],

    // get partner type
    requestGetPartnerType: null,
    getPartnerTypeSucceeded: ['data'],
    getPartnerTypeFailed: ['errors'],

    // clear state
    clearPartnerCreateState: null,
});

export default {
    // add partner
    [types.REQUEST_SUBMIT_PARTNER]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_PARTNER_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            partnerDetails: data,
            success: true,
        },
    }),
    [types.SUBMIT_PARTNER_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            success: false,
            errors,
        },
    }),

    // get partner type 
    [types.REQUEST_GET_PARTNER_TYPE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            errors: null,
        },
    }),
    [types.GET_PARTNER_TYPE_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            partnerType: data,
        },
    }),
    [types.GET_PARTNER_TYPE_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            errors,
        },
    }),

    // clear state
    [types.CLEAR_PARTNER_CREATE_STATE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            partnerDetails: null,
            success: false,
            errors: null,
        },
    }),
};

export { types, actions };