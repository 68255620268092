import {
    takeEvery,
    call,
    all,
    put,
    fork,
} from 'redux-saga/effects';

import { types, actions } from './reducer';
import AlertService from '@iso/services/SettingsService/AlertService';
import { extractError } from '@iso/lib/helpers/api';

function* submitAlert({ id, data }) {
    try {
        let response;
        if(id) {
            response = yield call(AlertService.editAlert, id, data);
        } else {
            response = yield call(AlertService.addAlert, data);
        }
        yield put(actions.submitAlertSucceeded(response.data));
    } catch (error) {
        yield put(actions.submitAlertFailed(extractError(error)));
    }
}

function* getProductUnits({ data }) {
    try {
        const response = yield call(AlertService.getProductUnits, data);
        yield put(actions.getProductUnitsSucceeded(response.data));
    } catch (error) {
        yield put(actions.getProductUnitsFailed(extractError(error)));
    }
}

function* watchSubmitAlert() {
    yield takeEvery(
        types.REQUEST_SUBMIT_ALERT,
        submitAlert,
    );
}

function* watchGetProductUnits() {
    yield takeEvery(
        types.REQUEST_GET_PRODUCT_UNITS,
        getProductUnits,
    );
}

function* createSaga() {
    yield all([
        fork(watchSubmitAlert),
        fork(watchGetProductUnits),
    ]);
}

export default createSaga;