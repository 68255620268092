import { all, fork, takeEvery } from 'redux-saga/effects';
import { types } from './reducer';
import pagination from '@iso/lib/helpers/pagination';
import SizeService from '@iso/services/SettingsService/ProductService/SizeService';

function* watchFetchProductSizes() {
    yield takeEvery(
        types.REQUEST_FETCH_PRODUCT_SIZES,
        pagination('productSizes').saga(SizeService),
    );
}

function* paginationSaga() {
    yield all([
        fork(watchFetchProductSizes),
    ]);
}

export default paginationSaga;