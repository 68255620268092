import { createActions } from 'reduxsauce';

export const { Types: types, Creators: actions } = createActions({
    // view payment method
    requestViewPaymentMethod: ['id'],
    viewPaymentMethodSucceeded: ['data'],
    viewPaymentMethodFailed: ['error'],

    // clear state
    clearPaymentMethodViewState: null,
});

export default {
    // view payment method
    [types.REQUEST_VIEW_PAYMENT_METHOD]: (state) => ({
        ...state,
        view: {
            ...state.view,
            loading: true,
            error: null,
        },
    }),
    [types.VIEW_PAYMENT_METHOD_SUCCEEDED]: (state, { data }) => ({
        ...state,
        view: {
            ...state.view,
            details: data,
            loading: false,
        },
    }),
    [types.VIEW_PAYMENT_METHOD_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            loading: false,
            error,
        },
    }),

    // clear state
    [types.CLEAR_PAYMENT_METHOD_VIEW_STATE]: (state) => ({
        ...state,
        view: {
            ...state.view,
            details: null,
        },
    }),
}
