import {
    fork,
    all,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { actions as authActions } from '@iso/redux/auth/login/reducer';
import {
    removeToken,
    removeRefreshToken,
} from '@iso/lib/helpers/auth';
import { types, actions } from './reducer';
import { extractError } from '@iso/lib/helpers/api';
import MenuService from '@iso/services/MenuService';

function* getMenu() {
    try {
        const response = yield call(MenuService.getMenu);
        yield put(actions.getMenuSucceeded(response.data));
    } catch (error) {
        removeToken(); // temporary
        removeRefreshToken();
        yield put(authActions.logoutSucceeded());
        yield put(actions.getMenuFailed(extractError(error)));
    }
}
 
function* watchGetMenu() {
    yield takeEvery(
        types.REQUEST_GET_MENU,
        getMenu,
    );
}

function* menuSaga() {
    yield all([
        fork(watchGetMenu),
    ]);
}

export default menuSaga;