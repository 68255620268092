import { createActions } from 'reduxsauce';

const { Types: types, Creators: actions } = createActions({
    // edit company
    requestSubmitCompany: ['data'],
    submitCompanySucceeded: ['data'],
    submitCompanyFailed: ['errors'],

    // country list
    requestGetCountries: ['data'],
    getCountriesSucceeded: ['data'],
    getCountriesFailed: ['errors'],

    // clear state
    clearCompanyState: null,
});

export default {
    // submit company
    [types.REQUEST_SUBMIT_COMPANY]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_COMPANY_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            details: data,
            success: true,
        },
    }),
    [types.SUBMIT_COMPANY_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            success: false,
            errors,
        },
    }),

    // get countries
    [types.REQUEST_GET_COUNTRIES]: (state) => ({
        ...state,
        create: {
            ...state.create,
            loading: true,
            errors: null,
        },
    }),
    [types.GET_COUNTRIES_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            countries: data,
        },
    }),
    [types.GET_COUNTRIES_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            errors,
        },
    }),

    // clear state
    [types.CLEAR_COMPANY_STATE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            errors: null,
            success: false,
        },
    }),
};

export { types, actions };