import {
    takeEvery,
    call,
    all,
    put,
    fork,
} from 'redux-saga/effects';

import { types, actions } from './reducer';
import OrderInService from '@iso/services/OrderService/OrderInService';
import { extractError } from '@iso/lib/helpers/api';


function* submitOrderIn({ data }) {
    try {
        const response = yield call(OrderInService.addOrder, data);
        yield put(actions.submitOrderInSucceeded(response.data));
    } catch (error) {
        yield put(actions.submitOrderInFailed(extractError(error)));
    }
}

function* getProductPriceDetailsIn({ data }) {
    try {
        const response = yield call(OrderInService.getProductPriceDetails, data);
        yield put(actions.getProductPriceDetailsInSucceeded(response.data.details));
    } catch (error) {
        yield put(actions.getProductPriceDetailsInFailed(extractError(error)));
    }
}

function* readFromBarcodeIn({ data }) {
    try {
        const response = yield call(OrderInService.readFromBarcode, data);
        yield put(actions.readFromBarcodeInSucceeded(response.data));
    } catch (error) {
        yield put(actions.readFromBarcodeInFailed(extractError(error)));
    }
}

function* watchSubmitOrderIn() {
    yield takeEvery(
        types.REQUEST_SUBMIT_ORDER_IN,
        submitOrderIn,
    );
}

function* watchGetProductPriceDetailsIn() {
    yield takeEvery(
        types.REQUEST_GET_PRODUCT_PRICE_DETAILS_IN,
        getProductPriceDetailsIn,
    );
}

function* watchReadFromBarcodeIn() {
    yield takeEvery(
        types.REQUEST_READ_FROM_BARCODE_IN,
        readFromBarcodeIn,
    );
}

function* createSaga() {
    yield all([
        fork(watchSubmitOrderIn),
        fork(watchGetProductPriceDetailsIn),
        fork(watchReadFromBarcodeIn),
    ]);
}

export default createSaga;