import { createActions } from 'reduxsauce';

export const { Types: types, Creators: actions } = createActions({
    // view order status type
    requestViewOrderStatusType: ['id'],
    viewOrderStatusTypeSucceeded: ['data'],
    viewOrderStatusTypeFailed: ['error'],

    // clear state
    clearOrderStatusTypeViewState: null,
});

export default {
    // view order status type
    [types.REQUEST_VIEW_ORDER_STATUS_TYPE]: (state) => ({
        ...state,
        view: {
            ...state.view,
            loading: true,
            error: null,
        },
    }),
    [types.VIEW_ORDER_STATUS_TYPE_SUCCEEDED]: (state, { data }) => ({
        ...state,
        view: {
            ...state.view,
            statusTypeDetails: data,
            loading: false,
        },
    }),
    [types.VIEW_ORDER_STATUS_TYPE_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            loading: false,
            error,
        },
    }),

    // clear state
    [types.CLEAR_ORDER_STATUS_TYPE_VIEW_STATE]: (state) => ({
        ...state,
        view: {
            ...state.view,
            statusTypeDetails: null,
        },
    }),
}
