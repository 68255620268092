import { createActions } from 'reduxsauce';

const { Types: types, Creators: actions } = createActions({
    // add composite product
    requestSubmitCompositeProduct: ['id', 'data'],
    submitCompositeProductSucceeded: ['data'],
    submitCompositeProductFailed: ['errors'],

    // get main product units
    requestGetMainProductUnits: ['data'],
    getMainProductUnitsSucceeded: ['data'],
    getMainProductUnitsFailed: ['errors'],

    // get component product units
    requestGetComponentProductUnits: ['data'],
    getComponentProductUnitsSucceeded: ['data'],
    getComponentProductUnitsFailed: ['errors'],

    // get component products
    requestGetComponentProducts: ['data'],
    getComponentProductsSucceeded: ['data'],
    getComponentProductsFailed: ['errors'],

    // clear state
    clearCompositeProductCreateState: null,
});

export default {
    // add composite product
    [types.REQUEST_SUBMIT_COMPOSITE_PRODUCT]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_COMPOSITE_PRODUCT_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            compositeProduct: data,
            success: true,
        },
    }),
    [types.SUBMIT_COMPOSITE_PRODUCT_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            success: false,
            errors,
        },
    }),

    // get main product units
    [types.REQUEST_GET_MAIN_PRODUCT_UNITS]: (state) => ({
        ...state,
        create: {
            ...state.create,
            loading: true,
            errors: null,
        },
    }),
    [types.GET_MAIN_PRODUCT_UNITS_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            mainUnits: data,
        },
    }),
    [types.GET_MAIN_PRODUCT_UNITS_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            errors,
        },
    }),

    // get component product units
    [types.REQUEST_GET_COMPONENT_PRODUCT_UNITS]: (state) => ({
        ...state,
        create: {
            ...state.create,
            loading: true,
            errors: null,
        },
    }),
    [types.GET_COMPONENT_PRODUCT_UNITS_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            componentUnits: data,
        },
    }),
    [types.GET_COMPONENT_PRODUCT_UNITS_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            errors,
        },
    }),

    // get component products
    [types.REQUEST_GET_COMPONENT_PRODUCTS]: (state) => ({
        ...state,
        create: {
            ...state.create,
            loading: true,
            errors: null,
        },
    }),
    [types.GET_COMPONENT_PRODUCTS_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            componentProducts: data,
        },
    }),
    [types.GET_COMPONENT_PRODUCTS_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            errors,
        },
    }),

    // clear state
    [types.CLEAR_COMPOSITE_PRODUCT_CREATE_STATE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            compositeProduct: null,
            componentUnits: [],
            success: false,
            errors: null,
        },
    }),
};

export { types, actions };