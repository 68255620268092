import {
    call,
    takeEvery,
    all,
    put,
    fork,
} from 'redux-saga/effects';

import { types, actions } from './reducer';
import {
    setToken,
    setRefreshToken,
    hasTokens,
    removeToken,
    removeRefreshToken,
} from '@iso/lib/helpers/auth';
import { extractError } from '@iso/lib/helpers/api';
import AuthenticationService from '@iso/services/AuthenticationService';

function* login({ data }) {
    try {
        const response = yield call(AuthenticationService.login, data);
        const { token } = response.data;
        const {
            access_token: accessToken,
            refresh_token: refreshToken,
        } = token;

        setToken(accessToken);
        setRefreshToken(refreshToken);

        yield put(actions.loginSucceeded());
    } catch (error) {
        yield put(actions.loginFailed(extractError(error)));
    }
}

function* checkAuthorization() {
    try {
        if (!hasTokens()) {
            throw new Error('No token');
        } else {
            yield put(actions.checkAuthorizationSucceeded(true));
        }
    } catch (error) {
        if (error.message === 'No token') {
            yield put(actions.checkAuthorizationFailed(false));
            return;
        }
        yield put(actions.checkAuthorizationFailed(extractError(error)));
    }
};

function* logout() {
    try {
        yield call(AuthenticationService.logout);
        removeToken();
        removeRefreshToken();
        yield put(actions.logoutSucceeded());
    } catch (error) {
        removeToken(); // temporary
        removeRefreshToken();
        yield put(actions.logoutSucceeded());
        yield put(actions.logoutFailed(extractError(error)));
    }
}

function* watchLogin() {
    yield takeEvery(types.REQUEST_LOGIN, login);
}

function* watchCheckAuthorization() {
    yield takeEvery(types.CHECK_AUTHORIZATION, checkAuthorization);
}

function* watchLogout() {
    yield takeEvery(types.REQUEST_LOGOUT, logout);
}

function* loginSaga() {
    yield all([
        fork(watchLogin),
        fork(watchCheckAuthorization),
        fork(watchLogout),
    ]);
}

export default loginSaga;
