import { createActions, createReducer } from 'reduxsauce';
import initialState from './model';

const { Types: types, Creators: actions } = createActions({

    requestGetDashboardStock: [null],
    getDashboardStockSucceeded: [
        'totalData',
        'graph1Data',
        'graph2Data',
    ],
    getDashboardStockFailed: ['error'],
});

export default createReducer(initialState, {

    [types.REQUEST_GET_DASHBOARD_STOCK]: (state) => ({
        ...state,
        loading: true,
        error: null,
    }),
    [types.GET_DASHBOARD_STOCK_SUCCEEDED]: (
        state,
        {
            totalData,
            graph1Data,
            graph2Data,
        },
    ) => ({
        ...state,
        total: totalData,
        orders: graph1Data,
        earnings: graph2Data,
        loading: false,
    }),
    [types.GET_DASHBOARD_STOCK_FAILED]: (state, { error }) => ({
        ...state,
        loading: false,
        error,
    }),
});

export { types, actions };
