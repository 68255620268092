import {
    takeEvery,
    call,
    all,
    put,
    fork,
} from 'redux-saga/effects';

import { types, actions } from './reducer';
import SizeService from '@iso/services/SettingsService/ProductService/SizeService';
import { extractError } from '@iso/lib/helpers/api';

function* submitSize({ id, data }) {
    try {
        let response;
        if(id) {
            response = yield call(SizeService.editSize, id, data);
        } else {
            response = yield call(SizeService.addSize, data);
        }
        yield put(actions.submitSizeSucceeded(response.data));
    } catch (error) {
        yield put(actions.submitSizeFailed(extractError(error)));
    }
}

function* watchSubmitSize() {
    yield takeEvery(
        types.REQUEST_SUBMIT_SIZE,
        submitSize,
    );
}

function* createSaga() {
    yield all([
        fork(watchSubmitSize),
    ]);
}

export default createSaga;