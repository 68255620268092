import pagination from '@iso/lib/helpers/pagination';

const {
    actions: baseActions,
    types,
    reducer,
} = pagination('orderOut');

const actions = {
    requestFetchOrderOut: baseActions.request,
    fetchOrderOutSucceeded: baseActions.succeeded,
    fetchOrderOutFailed: baseActions.failed,
};

export { types, actions };

export default reducer;