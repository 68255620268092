import { all, fork, takeEvery } from 'redux-saga/effects';
import { types } from './reducer';
import pagination from '@iso/lib/helpers/pagination';
import TypeService from '@iso/services/SettingsService/OrderService/TypeService';

function* watchFetchOrderTypes() {
    yield takeEvery(
        types.REQUEST_FETCH_ORDER_TYPES,
        pagination('orderTypes').saga(TypeService),
    );
}

function* paginationSaga() {
    yield all([
        fork(watchFetchOrderTypes),
    ]);
}

export default paginationSaga;