import BaseService from '../../BaseService';

class SizeService extends BaseService {
    getAll = (filters) => this.post('/warehouse/product/sizes', filters || { });

    getOne = (id) => this.get(`/warehouse/product/size/${id}`);

    deleteOne = (id) => this.delete(`/warehouse/product/size/${id}`);

    addSize = (data) => this.post('/warehouse/product/size', data);

    editSize = (id, data) => this.put(`/warehouse/product/size/${id}`, data);
}

export default new SizeService();