import pagination from '@iso/lib/helpers/pagination';

const {
    actions: baseActions,
    types,
    reducer,
} = pagination('compositeProducts');

const actions = {
    requestFetchCompositeProducts: baseActions.request,
    fetchCompositeProductsSucceeded: baseActions.succeeded,
    fetchCompositeProductsFailed: baseActions.failed,
};

export { types, actions };

export default reducer;