import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { types, actions } from './reducer';
import { extractError } from '@iso/lib//helpers/api';
import AlertService from '@iso/services/SettingsService/AlertService';

function* viewAlert({ id }) {
    try {
        const response = yield call(AlertService.getOne, id);
        yield put(actions.viewAlertSucceeded(response.data));
    } catch (error) {
        yield put(actions.viewAlertFailed(extractError(error)));
    }
}

function* watchViewAlert() {
    yield takeEvery(
        types.REQUEST_VIEW_ALERT,
        viewAlert,
    );
}

function* viewSaga() {
    yield all([
        fork(watchViewAlert),
    ]);
}

export default viewSaga;
