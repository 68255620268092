import BaseService from '../../BaseService';

class TypeService extends BaseService {
    getAll = () => this.get('/warehouse/order/types');

    getOne = (id) => this.get(`/warehouse/order/type/${id}`);

    deleteOne = (id) => this.delete(`/warehouse/order/type/${id}`);

    addOrderType = (data) => this.post('/warehouse/order/type', data);

    editOrderType = (id, data) => this.put(`/warehouse/order/type/${id}`, data);
}

export default new TypeService();