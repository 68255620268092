import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { extractError } from '@iso/lib/helpers/api';
import CompositeProductService from '@iso/services/SettingsService/CompositeProductService';
import { types, actions } from './reducer';

function* deleteCompositeProduct({ id }) {
    try {
        yield call(CompositeProductService.deleteOne, id);
        yield put(actions.deleteCompositeProductSucceeded(id));
    } catch (error) {
        yield put(actions.deleteCompositeProductFailed(extractError(error)));
    }
}

function* watchDeleteCompositeProduct() {
    yield takeEvery(
        types.REQUEST_DELETE_COMPOSITE_PRODUCT,
        deleteCompositeProduct,
    );
}

function* deleteSaga() {
    yield all([
        fork(watchDeleteCompositeProduct),
    ]);
}

export default deleteSaga;
