import { createActions } from 'reduxsauce';
import { removeSelectedItem } from '@iso/lib//helpers/CRUD';

export const { Types: types, Creators: actions } = createActions({
    requestDeleteOrderType: ['id'],
    deleteOrderTypeSucceeded: ['id'],
    deleteOrderTypeFailed: ['error'],
});

export default {
    [types.REQUEST_DELETE_ORDER_TYPE]: (state, { id }) => ({
        ...state,
        delete: {
            ...state.delete,
            id,
            loading: true,
            error: null,
        },
    }),
    [types.DELETE_ORDER_TYPE_SUCCEEDED]: (state, { id }) => ({
        ...state,
        data: removeSelectedItem(state.data, id),
        delete: {
            ...state.delete,
            id: null,
            loading: false,
        },
    }),
    [types.DELETE_ORDER_TYPE_FAILED]: (state, { error }) => ({
        ...state,
        delete: {
            ...state.delete,
            id: null,
            loading: false,
            error,
        },
    }),
};
