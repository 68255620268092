const jwtTokenName = 'token';
const refreshTokenName = 'refreshToken';

export const setToken = (token) => {
    localStorage.setItem(jwtTokenName, token);
};

export const setRefreshToken = (refreshToken) => {
    localStorage.setItem(refreshTokenName, refreshToken);
};

export const getToken = () => localStorage.getItem(jwtTokenName);

export const getRefreshToken = () => localStorage.getItem(refreshTokenName);

export const removeToken = () => {
    localStorage.removeItem(jwtTokenName);
};

export const removeRefreshToken = () => {
    localStorage.removeItem(refreshTokenName);
};

export const hasTokens = () => !!getToken() && !!getRefreshToken();
