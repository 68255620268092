/**
 *
 * @param {String} str
 */
 export const camelToSnake = (str) => str
 .replace(/[A-Z]/g, (letter) => `_${letter}`)
 .toUpperCase();

/**
*
* @param {String} str
*/
export const snakeToCamel = (str) => str
 .toLowerCase()
 .replace(/_[A-z]/g, (match) => match[1].toUpperCase());

export const firstToUpper = (str) => str.charAt(0).toUpperCase() + str.slice(1);
