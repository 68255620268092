import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { extractError } from '@iso/lib/helpers/api';
import PromotionService from '@iso/services/PromotionService/PromotionService';
import { types, actions } from './reducer';

function* deletePromotion({ id }) {
    try {
        yield call(PromotionService.deleteOne, id);
        yield put(actions.deletePromotionSucceeded(id));
    } catch (error) {
        yield put(actions.deletePromotionFailed(extractError(error)));
    }
}

function* watchDeletePromotion() {
    yield takeEvery(
        types.REQUEST_DELETE_PROMOTION,
        deletePromotion,
    );
}

function* deleteSaga() {
    yield all([
        fork(watchDeletePromotion),
    ]);
}

export default deleteSaga;
