import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { extractError } from '@iso/lib//helpers/api';
import { types, actions } from './reducer';
import BaseUnitService from '@iso/services/SettingsService/ProductService/BaseUnitService';

function* deleteBaseUnit({ id }) {
    try {
        yield call(BaseUnitService.deleteOne, id);
        yield put(actions.deleteBaseUnitSucceeded(id));
    } catch (error) {
        yield put(actions.deleteBaseUnitFailed(extractError(error)));
    }
}

function* watchDeleteBaseUnit() {
    yield takeEvery(
        types.REQUEST_DELETE_BASE_UNIT,
        deleteBaseUnit,
    );
}

function* deleteSaga() {
    yield all([
        fork(watchDeleteBaseUnit),
    ]);
}

export default deleteSaga;
