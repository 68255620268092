import { createActions } from 'reduxsauce';

export const { Types: types, Creators: actions } = createActions({
    // view currency
    requestViewCurrency: ['id'],
    viewCurrencySucceeded: ['data'],
    viewCurrencyFailed: ['error'],

    // clear state
    clearCurrencyViewState: null,
});

export default {
    // view currency
    [types.REQUEST_VIEW_CURRENCY]: (state) => ({
        ...state,
        view: {
            ...state.view,
            loading: true,
            error: null,
        },
    }),
    [types.VIEW_CURRENCY_SUCCEEDED]: (state, { data }) => ({
        ...state,
        view: {
            ...state.view,
            currencyDetails: data,
            loading: false,
        },
    }),
    [types.VIEW_CURRENCY_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            loading: false,
            error,
        },
    }),

    // clear state
    [types.CLEAR_CURRENCY_VIEW_STATE]: (state) => ({
        ...state,
        view: {
            ...state.view,
            currencyDetails: null,
        },
    }),
}
