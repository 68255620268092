import { createActions } from 'reduxsauce';

export const { Types: types, Creators: actions } = createActions({
    // view product
    requestViewProduct: ['id'],
    viewProductSucceeded: ['productData', 'stockData'],
    viewProductFailed: ['error'],

    // clear state
    clearProductViewState: null,

});

export default {
    // view product
    [types.REQUEST_VIEW_PRODUCT]: (state) => ({
        ...state,
        view: {
            ...state.view,
            loading: true,
            error: null,
        },
    }),
    [types.VIEW_PRODUCT_SUCCEEDED]: (state, { productData, stockData }) => ({
        ...state,
        view: {
            ...state.view,
            product: productData,
            stock: stockData,
            loading: false,
        },
    }),
    [types.VIEW_PRODUCT_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            loading: false,
            error,
        },
    }),

    // clear state
    [types.CLEAR_PRODUCT_VIEW_STATE]: (state) => ({
        ...state,
        view: {
            ...state.view,
            product: null,
        },
    }),
}
