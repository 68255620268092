import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { types, actions } from './reducer';
import { extractError } from '@iso/lib//helpers/api';
import TypeService from '@iso/services/SettingsService/WarehouseService/TypeService';

function* viewWarehouseType({ id }) {
    try {
        const response = yield call(TypeService.getOne, id);
        yield put(actions.viewWarehouseTypeSucceeded(response.data));
    } catch (error) {
        yield put(actions.viewWarehouseTypeFailed(extractError(error)));
    }
}

function* watchViewWarehouseType() {
    yield takeEvery(
        types.REQUEST_VIEW_WAREHOUSE_TYPE,
        viewWarehouseType,
    );
}

function* viewSaga() {
    yield all([
        fork(watchViewWarehouseType),
    ]);
}

export default viewSaga;
