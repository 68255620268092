import {
    takeEvery,
    call,
    all,
    put,
    fork,
} from 'redux-saga/effects';

import { types, actions } from './reducer';
import OrderOutService from '@iso/services/OrderService/OrderOutService';
import { extractError } from '@iso/lib/helpers/api';


function* submitOrderOut({ data }) {
    try {
        const response = yield call(OrderOutService.addOrder, data);
        yield put(actions.submitOrderOutSucceeded(response.data));
    } catch (error) {
        yield put(actions.submitOrderOutFailed(extractError(error)));
    }
}

function* getProductPriceDetailsOut({ data }) {
    try {
        const response = yield call(OrderOutService.getProductPriceDetails, data);
        yield put(actions.getProductPriceDetailsOutSucceeded(response.data.details));
    } catch (error) {
        yield put(actions.getProductPriceDetailsOutFailed(extractError(error)));
    }
}

function* readFromBarcodeOut({ data }) {
    try {
        const response = yield call(OrderOutService.readFromBarcode, data);
        yield put(actions.readFromBarcodeOutSucceeded(response.data));
    } catch (error) {
        yield put(actions.readFromBarcodeOutFailed(extractError(error)));
    }
}

function* watchSubmitOrderOut() {
    yield takeEvery(
        types.REQUEST_SUBMIT_ORDER_OUT,
        submitOrderOut,
    );
}

function* watchGetProductPriceDetailsOut() {
    yield takeEvery(
        types.REQUEST_GET_PRODUCT_PRICE_DETAILS_OUT,
        getProductPriceDetailsOut,
    );
}

function* watchReadFromBarcodeOut() {
    yield takeEvery(
        types.REQUEST_READ_FROM_BARCODE_OUT,
        readFromBarcodeOut,
    );
}

function* createSaga() {
    yield all([
        fork(watchSubmitOrderOut),
        fork(watchGetProductPriceDetailsOut),
        fork(watchReadFromBarcodeOut),
    ]);
}

export default createSaga;