import { createReducer } from 'reduxsauce';
import initialState from './model';

import createReduxHandlers, {
    types as createTypes,
    actions as createActions,
} from './create/reducer';

import viewReduxHandlers, {
    types as viewTypes,
    actions as viewActions,
} from './view/reducer';

const types = {
    ...createTypes,
    ...viewTypes,
};

const actions = {
    ...createActions,
    ...viewActions,
};

export { types, actions };

export default createReducer(initialState, {
    ...createReduxHandlers,
    ...viewReduxHandlers,
});