import { all } from 'redux-saga/effects';
import alertSaga from './alert/saga';
import profileSaga from './profile/saga';

function* topbarSaga() {
    yield all([
        alertSaga(),
        profileSaga(),
    ]);
}

export default topbarSaga;