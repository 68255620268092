import {
    takeEvery,
    call,
    all,
    put,
    fork,
} from 'redux-saga/effects';

import { types, actions } from './reducer';
import WarehouseService from '@iso/services/SettingsService/WarehouseService/WarehouseService';
import { extractError } from '@iso/lib/helpers/api';

function* submitWarehouse({ id, data }) {
    try {
        let response;
        if(id) {
            response = yield call(WarehouseService.editWarehouse, id, data);
        } else {
            response = yield call(WarehouseService.addWarehouse, data);
        }
        yield put(actions.submitWarehouseSucceeded(response.data));
    } catch (error) {
        yield put(actions.submitWarehouseFailed(extractError(error)));
    }
}

function* watchSubmitWarehouse() {
    yield takeEvery(
        types.REQUEST_SUBMIT_WAREHOUSE,
        submitWarehouse,
    );
}

function* createSaga() {
    yield all([
        fork(watchSubmitWarehouse),
    ]);
}

export default createSaga;