import { createActions } from 'reduxsauce';

export const { Types: types, Creators: actions } = createActions({
    // view size
    requestViewSize: ['id'],
    viewSizeSucceeded: ['data'],
    viewSizeFailed: ['error'],

    // clear state
    clearSizeViewState: null,
});

export default {
    // view size
    [types.REQUEST_VIEW_SIZE]: (state) => ({
        ...state,
        view: {
            ...state.view,
            loading: true,
            error: null,
        },
    }),
    [types.VIEW_SIZE_SUCCEEDED]: (state, { data }) => ({
        ...state,
        view: {
            ...state.view,
            sizeDetails: data,
            loading: false,
        },
    }),
    [types.VIEW_SIZE_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            loading: false,
            error,
        },
    }),

    // clear state
    [types.CLEAR_SIZE_VIEW_STATE]: (state) => ({
        ...state,
        view: {
            ...state.view,
            sizeDetails: null,
        },
    }),
}
