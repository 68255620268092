import pagination from '@iso/lib/helpers/pagination';

const {
    actions: baseActions,
    types,
    reducer,
} = pagination('products');

const actions = {
    requestFetchProducts: baseActions.request,
    fetchProductsSucceeded: baseActions.succeeded,
    fetchProductsFailed: baseActions.failed,
};

export { types, actions };

export default reducer;