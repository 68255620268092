import { createActions } from 'reduxsauce';

const { Types: types, Creators: actions } = createActions({
    // add order type
    requestSubmitOrderType: ['id', 'data'],
    submitOrderTypeSucceeded: ['data'],
    submitOrderTypeFailed: ['errors'],

    // clear state
    clearOrderTypeCreateState: null,
});

export default {
    // add order type
    [types.REQUEST_SUBMIT_ORDER_TYPE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_ORDER_TYPE_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            typeDetails: data,
            success: true,
        },
    }),
    [types.SUBMIT_ORDER_TYPE_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            success: false,
            errors,
        },
    }),

    // clear state
    [types.CLEAR_ORDER_TYPE_CREATE_STATE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            typeDetails: null,
            success: false,
            errors: null,
        },
    }),
};

export { types, actions };