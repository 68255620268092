import { all, fork, takeEvery } from 'redux-saga/effects';
import { types } from './reducer';
import pagination from '@iso/lib/helpers/pagination';
import PromotionService from '@iso/services/ReportingService/PromotionService';

function* watchFetchPromotionList() {
    yield takeEvery(
        types.REQUEST_FETCH_PROMOTION_LIST,
        pagination('promotionList').saga(PromotionService),
    );
}

function* promotionReportingSaga() {
    yield all([
        fork(watchFetchPromotionList),
    ]);
}

export default promotionReportingSaga;